import PartnerNetworkIcon from '@mui/icons-material/LeakAdd';
import PartnerNetworkList from './PartnerNetworkList';
import PartnerNetworkEdit from './PartnerNetworkEdit';
import PartnerNetworkCreate from './PartnerNetworkCreate';

export default {
  list: PartnerNetworkList,
  edit: PartnerNetworkEdit,
  create: PartnerNetworkCreate,
  icon: PartnerNetworkIcon,
};
