import Button from '@mui/material/Button';
import styled from 'styled-components';
import { CircularProgress } from '@mui/material';

const StyledButton = styled(Button)`
  margin: 0 10px;
  color: #fff;
  background-color: #4f3cc9;

  &:hover {
    background-color: #4f3cc9;
  }
`;

// TODO replace for one btn with type property
const InfoBtn = ({ loading, disabled, children, ...props }: any) => {
  return (
    <StyledButton {...props} variant="contained" disabled={disabled || loading}>
      {children}
      {loading && <CircularProgress size={15} thickness={4} />}
    </StyledButton>
  );
};

export default InfoBtn;
