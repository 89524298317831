import {
  Datagrid,
  List,
  TextField,
  NumberField,
  BooleanField,
  SelectField,
  TopToolbar,
  SelectColumnsButton,
  CreateButton,
} from 'react-admin';
import CustomPagination from 'layout/CustomPagination';
import { supplyRank } from './field-choices';
import { CsvExport } from 'component/common/CsvExport';
import { CSVSource } from 'types/enums/CSVSource';

const Actions = () => {
  return (
    <TopToolbar
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
      }}
    >
      <CreateButton />
      <CsvExport source={CSVSource.Supply} />
    </TopToolbar>
  );
};

const SupplyList = () => {
  return (
    <List
      perPage={15}
      actions={<Actions />}
      sort={{ field: 'updated_at', order: 'DESC' }}
      pagination={<CustomPagination />}
    >
      <Datagrid optimized rowClick="edit" bulkActionButtons={false}>
        <NumberField
          source="id"
          options={{ useGrouping: false }}
          sortable={false}
          textAlign="center"
        />
        <TextField source="name" textAlign="center" sortable={false} />
        <SelectField source="rank" choices={supplyRank} />
        <TextField source="site_ids" textAlign="center" sortable={false} />
        <BooleanField
          source="code_available"
          sortable={false}
          textAlign="center"
        />
        <NumberField
          label="Paid coefficient"
          source="coefficient"
          options={{ useGrouping: false }}
          sortable={false}
        />
        <NumberField
          label="Free coefficient"
          source="free_coefficient"
          options={{ useGrouping: false }}
          sortable={false}
        />
        <BooleanField
          source="gift_available"
          sortable={false}
          textAlign="center"
        />
        <NumberField
          source="gift_coefficient"
          options={{ useGrouping: false }}
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};

export default SupplyList;
