import { FC } from 'react';
import {
  BooleanInput,
  Edit,
  PasswordInput,
  SimpleForm,
  TextInput,
  Toolbar,
  required,
  SaveButton,
  useEditController,
  minLength,
  SelectInput,
  useRecordContext,
} from 'react-admin';

import { supplyAgentRoles } from 'consts';
import { useParams } from 'react-router-dom';

const SupplyAgentsTitle: FC = () => {
  const record = useRecordContext();

  return record ? <span>Supply Agents #{record.id}</span> : null;
};

const SupplyAgentsEdit: FC = () => {
  const { id } = useParams();
  const { save } = useEditController({
    resource: 'supply-agent',
    id,
  });

  const sanitizeData = (data: any) => {
    const { created_at, updated_at, ...cleanedData } = data;

    return cleanedData;
  };

  return (
    <Edit title={<SupplyAgentsTitle />}>
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton alwaysEnable />
          </Toolbar>
        }
        onSubmit={(values) => save && save(sanitizeData(values))}
      >
        <TextInput source="name" validate={[required()]} variant="filled" />
        <TextInput source="email" validate={[required()]} variant="filled" />
        <PasswordInput
          source="password"
          validate={[required(), minLength(8)]}
          variant="filled"
        />
        <BooleanInput source="is_active" defaultValue={true} />
        <SelectInput
          label="Supply role"
          source="role"
          validate={[required()]}
          choices={supplyAgentRoles}
          variant="filled"
        />
      </SimpleForm>
    </Edit>
  );
};

export default SupplyAgentsEdit;
