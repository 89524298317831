import { FC } from 'react';
import { Edit, useRecordContext } from 'react-admin';
import GiftForm from './GiftForm';

const ConfigTitle: FC = () => {
  const record = useRecordContext();

  return record ? <span>Gift #{record?.id}</span> : null;
};

const GiftEdit: FC = () => {
  return (
    <Edit title={<ConfigTitle />}>
      <GiftForm />
    </Edit>
  );
};

export default GiftEdit;
