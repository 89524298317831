import { FC, memo } from 'react';
import { FormDataConsumer, useRecordContext } from 'react-admin';

import { Partner } from 'model-types';

import { SubsourceGroupsConfigs } from './submodules/SubsourceGroupsConfigs';
import { PostbackEventConfigs } from './submodules/PostbackEventConfigs';
import { SubsourceConfigs } from './submodules/SubsourceConfigs';
import { APIConfigs } from './submodules/APIConfigs';

export const AffiliateConfig: FC = memo(() => {
  const record = useRecordContext();

  return (
    <>
      {!!record?.id && !!record?.first_subsource && <SubsourceGroupsConfigs />}

      <PostbackEventConfigs />

      <FormDataConsumer>
        {({ formData }: { formData: Partner }) => {
          return formData.traffic_type === 'api' ? <APIConfigs /> : null;
        }}
      </FormDataConsumer>

      <SubsourceConfigs />
    </>
  );
});
