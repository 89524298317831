import PersonIcon from '@mui/icons-material/Person';

import UserList from './RegularUserList';
import UserEdit from './RegularUserEdit';

export default {
  list: UserList,
  edit: UserEdit,
  icon: PersonIcon,
};
