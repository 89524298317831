import photoModerationUserIcon from 'assets/menu-icons/photo-moderation.svg';
import trustedUserModerationIcon from 'assets/menu-icons/trusted-user-moderation.svg';
import iceBreakerModerationIcon from 'assets/menu-icons/ice-breaker-moderation.svg';
import regularUsersIcon from 'assets/menu-icons/person.svg';
import partnersIcon from 'assets/menu-icons/emoji-people.svg';
import partnerNetworkIcon from 'assets/menu-icons/leak-add.svg';
import textSourcesIcon from 'assets/menu-icons/text-fields.svg';
import textCategoriesIcon from 'assets/menu-icons/account-tree.svg';
import systemSettingsIcon from 'assets/menu-icons/settings.svg';
import wizardSettingsIcon from 'assets/menu-icons/tune.svg';
import enumsIcon from 'assets/menu-icons/list.svg';
import supplyIcon from 'assets/menu-icons/group.svg';
import supplyAgentsIcon from 'assets/menu-icons/person-add.svg';
import notificationIcon from 'assets/menu-icons/notifications-active.svg';
import activityIcon from 'assets/menu-icons/av-timer.svg';

export enum Favicon {
  PhotoModeration = '/photo/moderation/user',
  PartnersNetworks = '/partners-networks',
  TrustedUserReModeration = '/trusted-user/re-moderation',
  TrustedUserModeration = '/trusted-user/moderation',
  IceBreakerModeration = '/ice-breaker/moderation',
  IceBreakerConfig = '/ice-breaker/config',
  IceBreakerAll = '/ice-breaker/all',
  TranslationsTextSources = '/translations/text-sources',
  TranslationsTextCategories = '/translations/text-categories',
  SystemSettings = '/system-settings',
  WizardFinderConfig = '/wizard-finder-config',
  SupplyAgent = '/supply-agent',
  SupplyNotification = '/supply-notification',
  Partners = '/partners',
  TrustedUser = '/trusted-user',
  DocsEnums = '/docs/enums',
  SupportTickets = '/support/ticket',
  Supply = '/supply',
  ActivityConfig = '/activity/config',
  Activity = '/activity',
  Users = '/users',
}

export const favicons: Record<Favicon, string> = {
  [Favicon.PhotoModeration]: photoModerationUserIcon,
  [Favicon.PartnersNetworks]: partnerNetworkIcon,
  [Favicon.Partners]: partnersIcon,
  [Favicon.TrustedUserModeration]: trustedUserModerationIcon,
  [Favicon.TrustedUserReModeration]: trustedUserModerationIcon,
  [Favicon.TrustedUser]: trustedUserModerationIcon,
  [Favicon.IceBreakerModeration]: iceBreakerModerationIcon,
  [Favicon.IceBreakerConfig]: iceBreakerModerationIcon,
  [Favicon.IceBreakerAll]: iceBreakerModerationIcon,
  [Favicon.TranslationsTextSources]: textSourcesIcon,
  [Favicon.TranslationsTextCategories]: textCategoriesIcon,
  [Favicon.SystemSettings]: systemSettingsIcon,
  [Favicon.WizardFinderConfig]: wizardSettingsIcon,
  [Favicon.DocsEnums]: enumsIcon,
  [Favicon.SupportTickets]: notificationIcon,
  [Favicon.SupplyAgent]: supplyAgentsIcon,
  [Favicon.SupplyNotification]: notificationIcon,
  [Favicon.Supply]: supplyIcon,
  [Favicon.Activity]: activityIcon,
  [Favicon.ActivityConfig]: activityIcon,
  [Favicon.Users]: regularUsersIcon,
};

export const validFavicon = (pathName: string) => {
  const regex = new RegExp(`^(${Object.values(Favicon).join('|')})`);

  if (regex.test(pathName)) {
    const faviconKey = Object.keys(favicons).find((item) =>
      pathName.includes(item),
    ) as keyof typeof favicons;

    if (faviconKey) {
      return favicons[faviconKey];
    }
  }

  return favicons[Favicon.PhotoModeration];
};
