import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import React, { FC, useEffect, useRef, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { getArrayFromLinkedList } from 'utils';
import styled from 'styled-components';

const StyledTable = styled(Table)`
  min-width: 650px;
`;

interface Log {
  screen_height: number;
  screen_width: number;
  screen_orientation: string;
  user_id: number;
  created_at: string;
  interaction_with_user_id: number | null;
  host: string;
  url: string;
  origin: string;
  duration: number;
}

interface Props {
  userId: number;
}

export const PageViews: FC<Props> = ({ userId }) => {
  const [page, setPage] = useState(0);
  const [logs, setLogs] = useState<Log[]>([]);
  const [isExtend, setIsExtend] = useState(false);

  const rowsPerPage = useRef(25);

  const handleChange = (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setIsExtend((state) => !state);
  };

  const fetchLogs = async () => {
    const logs = await getArrayFromLinkedList(`/users/${userId}/page-loads`);

    if (logs?.length) setLogs(logs);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (isExtend && !logs.length) {
      fetchLogs();
    }
  }, [isExtend, logs]);

  return (
    <Accordion onChange={handleChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Page views</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer component={Paper}>
          <StyledTable size="small">
            <TableHead>
              <TableRow>
                <TableCell>number</TableCell>
                <TableCell>screen_height</TableCell>
                <TableCell>screen_width</TableCell>
                <TableCell>screen_orientation</TableCell>
                <TableCell>user_id</TableCell>
                <TableCell>interaction_with_user_id</TableCell>
                <TableCell>host</TableCell>
                <TableCell>url</TableCell>
                <TableCell>origin</TableCell>
                <TableCell>duration</TableCell>
                <TableCell>timestamp</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs
                .slice(
                  page * rowsPerPage.current,
                  page * rowsPerPage.current + rowsPerPage.current,
                )
                .map((log, index) => (
                  <TableRow key={log.user_id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{log.screen_height}</TableCell>
                    <TableCell>{log.screen_width}</TableCell>
                    <TableCell>{log.screen_orientation}</TableCell>
                    <TableCell>{log.user_id}</TableCell>
                    <TableCell>{log.interaction_with_user_id || '-'}</TableCell>
                    <TableCell>{log.host}</TableCell>
                    <TableCell>{log.url}</TableCell>
                    <TableCell>{log.origin || '-'}</TableCell>
                    <TableCell>{log.duration}</TableCell>
                    <TableCell>
                      {log.created_at.split('T').join(' ').slice(0, 19)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={2}
                  rowsPerPageOptions={[]}
                  count={logs.length}
                  rowsPerPage={rowsPerPage.current}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </TableRow>
            </TableFooter>
          </StyledTable>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};
