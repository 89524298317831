import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Toolbar,
  useRecordContext,
  useRefresh,
  useNotify,
  useRedirect,
} from 'react-admin';
import { useNavigate, useLocation } from 'react-router';

import queryString from 'query-string';

import { httpClient } from 'httpClient';
import { EventEmitter } from 'eventEmitter';

import {
  TrustedUserMedia,
  TrustedUserModerationData,
} from 'types/trustedUser/trustedUserModeration';
import { EmitterEvents } from 'types/enums/EmitterEvents';
import { ModerationStatus } from 'types/enums/ModerationStatus';
import SuccessButton from 'layout/buttons/SuccessButton';
import GreyButton from 'layout/buttons/GreyButton';
import YellowButton from 'layout/buttons/YellowButton';

import {
  DEFAULT_MODERATION_RESPONSE,
  GET_TUB_NUMBER_REG_EXP,
  TABS,
} from '../constants';

import DeclinePopup from './DeclinePopup';
import SubmitBtn from './SubmitBtn';
import styled from 'styled-components';

const BtnsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const DraftBtn = styled(GreyButton)`
  margin: 0 10px;
`;

interface Props {
  isDraftSaving: boolean;
  moderationData: TrustedUserModerationData;
  setModerationData: Dispatch<SetStateAction<TrustedUserModerationData>>;
  saveDraft: () => void;
}

export const CustomToolbar: React.FC<Props> = ({
  isDraftSaving,
  moderationData,
  setModerationData,
  saveDraft,
}: any) => {
  const record = useRecordContext();

  const navigate = useNavigate();
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const { pathname, search } = useLocation();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tabNumber, setTubNumber] = useState(0);

  const openNextTab = () => {
    if (tabNumber === 0) {
      setModerationData((prevData: TrustedUserModerationData) => ({
        ...prevData,
        statuses: {
          ...prevData.statuses,
          is_documents_changed: false,
        },
      }));
    }

    if (tabNumber === 1) {
      setModerationData((prevData: TrustedUserModerationData) => ({
        ...prevData,
        statuses: {
          ...prevData.statuses,
          is_other_details_changed: false,
        },
      }));
    }

    const newUrl = tabNumber
      ? pathname.replace(GET_TUB_NUMBER_REG_EXP, String(tabNumber + 1))
      : pathname + '/1';

    navigate(`${newUrl}${search}`);
  };

  const openPrevTab = () => {
    const newUrl =
      tabNumber > 1
        ? pathname.replace(GET_TUB_NUMBER_REG_EXP, String(tabNumber - 1))
        : pathname.replace(/\/(?<=\d\/)\d+$/, '');

    navigate(newUrl);
  };

  const checkIsAllMediaModerated = () => {
    if (moderationData.statuses.is_documents_changed) {
      notify(`Moderate documents again!`, { type: 'error' });

      return false;
    }

    if (moderationData.statuses.is_other_details_changed) {
      notify(`Moderate other details again!`, { type: 'error' });

      return false;
    }

    const isAllPublicPhotoModerated = moderationData.public_photos.find(
      (photo: TrustedUserMedia) =>
        photo.status_id !== ModerationStatus.Ok &&
        photo.status_id !== ModerationStatus.Ban,
    );

    if (isAllPublicPhotoModerated) {
      notify(`Moderate all public photo for submit`, { type: 'error' });

      return false;
    }

    const isAllPrivatePhotoModerated = moderationData.private_photos.find(
      (photo: TrustedUserMedia) =>
        photo.status_id !== ModerationStatus.Ok &&
        photo.status_id !== ModerationStatus.Ban,
    );

    if (isAllPrivatePhotoModerated) {
      notify(`Moderate all private photo for submit`, { type: 'error' });

      return false;
    }

    const isAllPrivatePhotoLibraryModerated =
      moderationData.private_library.find(
        (photo: TrustedUserMedia) =>
          photo.status_id !== ModerationStatus.Ok &&
          photo.status_id !== ModerationStatus.Ban,
      );

    if (isAllPrivatePhotoLibraryModerated) {
      notify(`Moderate all private library for submit`, { type: 'error' });

      return false;
    }

    const isAllVideosModerated = moderationData.videos.find(
      (video: TrustedUserMedia) =>
        video.status_id !== ModerationStatus.Ok &&
        video.status_id !== ModerationStatus.Ban,
    );

    if (isAllVideosModerated) {
      notify(`Moderate all video for submit`, { type: 'error' });

      return false;
    }

    return true;
  };

  const handleSubmitBtn = async () => {
    if (!checkIsAllMediaModerated()) {
      return;
    }

    setIsSubmitting(true);

    await saveDraft();

    const publicPhotosModerationData = moderationData.public_photos.map(
      (mediaData: TrustedUserMedia) => {
        return {
          id: mediaData.id,
          status_id: mediaData.status_id,
          is_erotic: mediaData.is_erotic,
        };
      },
    );
    const privatePhotosModerationData = moderationData.private_photos.map(
      (mediaData: TrustedUserMedia) => {
        return {
          id: mediaData.id,
          status_id: mediaData.status_id,
          is_erotic: mediaData.is_erotic,
        };
      },
    );
    const privateLibraryModerationData = moderationData.private_library.map(
      (mediaData: TrustedUserMedia) => {
        return {
          id: mediaData.id,
          status_id: mediaData.status_id,
          is_erotic: mediaData.is_erotic,
        };
      },
    );
    const videosModerationData = moderationData.videos.map(
      (mediaData: TrustedUserMedia) => {
        return {
          id: mediaData.id,
          status_id: mediaData.status_id,
          is_erotic: mediaData.is_erotic,
        };
      },
    );

    const moderationRequestPayload = {
      retrieved_at: moderationData.retrieved_at,
      document_status: moderationData.document_status,
      document_decline_reason: moderationData.document_decline_reason,
      other_detail_status: moderationData.other_detail_status,
      other_detail_decline_reason: moderationData.other_detail_decline_reason,
      public_photos: publicPhotosModerationData,
      private_photos: privatePhotosModerationData,
      private_library: privateLibraryModerationData,
      videos: videosModerationData,
    };

    const path = pathname.includes('re-moderation')
      ? 're-moderation'
      : 'moderation';

    httpClient
      .post(
        `/trusted-user/moderation/${record.id}`,
        { ...moderationRequestPayload },
        { timeout: 60 * 1000 },
      )
      .then(() => {
        notify('User moderation saved', { type: 'info' });

        httpClient.get(`/trusted-user/${path}`).then((response) => {
          EventEmitter.dispatch(EmitterEvents.ReloadModerationCounters);

          if (response.data?.data?.[0]) {
            const redirectLink = queryString.stringifyUrl({
              url: `/trusted-user/${path}/${response.data.data[0].id}`,
            });

            redirect(redirectLink);
          } else {
            redirect(`/trusted-user/${path}`);
          }
        });

        setModerationData(DEFAULT_MODERATION_RESPONSE);
      })
      .catch((error) => {
        if (error?.response?.data?.retrieved_at) {
          refresh();

          notify(error?.response?.data?.retrieved_at, {
            type: 'error',
            multiLine: true,
          });

          return;
        }

        !!error?.response?.data
          ? Object.keys(error.response.data).forEach((errorKey) =>
              notify(error.response.data[errorKey], {
                type: 'error',
                multiLine: true,
              }),
            )
          : notify(error.message, { type: 'error' });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    const tabNumberFromUrl = pathname.match(GET_TUB_NUMBER_REG_EXP);
    const tubNumber = tabNumberFromUrl ? Number(tabNumberFromUrl[0]) : 0;

    setTubNumber(tubNumber);
  }, [pathname]);

  return (
    <Toolbar
      style={{
        position: 'sticky',
        bottom: '0',
        marginTop: 'auto',
      }}
    >
      {tabNumber < 2 && (
        <DeclinePopup
          tabNumber={tabNumber}
          moderationData={moderationData}
          setModerationData={setModerationData}
        />
      )}

      <BtnsContainer>
        <DraftBtn onClick={saveDraft} loading={isDraftSaving}>
          Save Progress
        </DraftBtn>

        {!!tabNumber && <YellowButton onClick={openPrevTab}>Prev</YellowButton>}

        {tabNumber < 5 && (
          <SuccessButton onClick={openNextTab}>Next</SuccessButton>
        )}

        {tabNumber === 5 && (
          <SubmitBtn
            isSubmitting={isSubmitting}
            handleSubmitBtn={handleSubmitBtn}
          />
        )}
      </BtnsContainer>
    </Toolbar>
  );
};

export default CustomToolbar;
