import { FC } from 'react';

import { Edit, useRecordContext } from 'react-admin';
import { IceBreakerConfigForm } from './IceBreakerConfigForm';

const ConfigTitle: FC = () => {
  const record = useRecordContext();
  return record ? <span>IcebreakerConfig #{record.id}</span> : null;
};

const IceBreakerConfigEdit: FC = () => {
  return (
    <Edit title={<ConfigTitle />}>
      <IceBreakerConfigForm />
    </Edit>
  );
};

export default IceBreakerConfigEdit;
