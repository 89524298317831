import { FC, memo } from 'react';
import {
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
} from 'react-admin';
import { Button, Grid } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import {
  hourChoices,
  minuteChoices,
} from 'pages/partner/submodules/field-choices';

interface Props {
  source: string;
}

export const TimingConfigs: FC<Props> = memo(({ source }) => {
  return (
    <Grid container paddingLeft={0} sx={{ width: '100%' }}>
      <p>Timing configs:</p>

      <ArrayInput source={source} label="">
        <SimpleFormIterator
          addButton={<Button startIcon={<AddCircleOutlineIcon />}>Add</Button>}
          disableClear
          inline
          getItemLabel={(index) => `#${index + 1}`}
          sx={{
            justifyContent: 'space-between',

            [`& .RaSimpleFormIterator-inline`]: {
              flexGrow: '1',
            },

            [`& .RaSimpleFormIterator-action`]: {
              width: '10%',
            },
          }}
        >
          <SelectInput
            source="from_hour"
            defaultValue={0}
            label="From Hour"
            variant="filled"
            required
            choices={hourChoices}
          />

          <SelectInput
            source="from_minute"
            defaultValue={0}
            label="From Minute"
            variant="filled"
            required
            choices={minuteChoices}
          />

          <SelectInput
            source="to_hour"
            defaultValue={1}
            label="To Hour"
            variant="filled"
            required
            choices={hourChoices}
          />

          <SelectInput
            source="to_minute"
            defaultValue={0}
            label="To Minute"
            variant="filled"
            required
            choices={minuteChoices}
          />

          <NumberInput
            source="enabled_percentage"
            defaultValue={100}
            min={0}
            max={100}
            step={1}
            variant="filled"
            label="% Enabled"
            sx={{ marginTop: '8px', width: '100px' }}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </Grid>
  );
});
