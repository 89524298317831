import { Chip } from '@mui/material';
import { useRecordContext } from 'react-admin';

function TypeField(props: any) {
  const record = useRecordContext();

  if (!record) {
    return <></>;
  }

  const setColor = (type: string) => {
    switch (type) {
      case 'fine':
        return 'rgb(220,252,231)';
      case 'news':
        return 'rgb(224,242,254)';
      case 'other':
        return 'rgb(254,249,195)';
      default:
        break;
    }
  };

  return (
    <Chip
      label={record.type}
      style={{ backgroundColor: setColor(record.type) }}
      {...props}
    />
  );
}

export default TypeField;
