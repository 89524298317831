import { FC, memo, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { ImageListItem, ImageList } from '@mui/material';

import { Env, getEnv } from 'utils';
import { TrustedUserMedia } from 'types/trustedUser/trustedUserModeration';
import { ModerationStatus } from 'types/enums/ModerationStatus';
import { MediaType } from 'types/mediaType';
import SuccessButton from 'layout/buttons/SuccessButton';
import ErrorButton from 'layout/buttons/ErrorButton';

import MediaModerationBtn from './MediaModerationBtn';
import CustomPagination from './CustomPagination';
import styled from 'styled-components';

const PHOTOS_ON_PAGE = 30;

const StyledImageList = styled(ImageList)`
  grid-template-rows: auto 1fr;
  gap: 10px;
  padding: 10px 0s;
`;

const StyledImageListItem = styled(ImageListItem)`
  display: flex;
  justify-content: center;
  margin-right: 5px;
`;
const PhotoItem = styled.img`
  height: 300px;
  max-width: 100%;
  min-width: 150px;
  object-fit: contain;
`;

const VideoItem = styled.video`
  max-width: 100%;
  min-width: 150px;
  height: 100%;
  object-fit: contain;
`;

const NewBadge = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: #fff;
  padding: 5px;
`;

interface Props {
  tabNumber: number;
  media: TrustedUserMedia[];
  setMedia: (
    setStateAction: (prev: TrustedUserMedia[]) => TrustedUserMedia[],
  ) => void;
}

export const MediaList: FC<Props> = memo(({ tabNumber, media, setMedia }) => {
  const currentEnv = getEnv();

  const { pathname } = useLocation();

  const ref = useRef<null | HTMLDivElement>(null);

  const [activePage, setActivePage] = useState(1);

  const renderedPhotos = useMemo(() => {
    return media.slice(
      PHOTOS_ON_PAGE * activePage - PHOTOS_ON_PAGE,
      PHOTOS_ON_PAGE * activePage,
    );
  }, [media, activePage]);

  const pages = useMemo(() => {
    return Array.from(
      { length: Math.ceil(media?.length / PHOTOS_ON_PAGE) || 1 },
      (_, index) => index + 1,
    );
  }, [media.length]);

  const handleModerateMedia = ({
    id,
    statusId,
    isErotic,
  }: {
    id: number;
    statusId?: number;
    isErotic?: boolean;
  }) => {
    setMedia((prevMedia) =>
      prevMedia.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            is_erotic: isErotic !== undefined ? isErotic : item.is_erotic,
            status_id: statusId !== undefined ? statusId : item.status_id,
          };
        }

        return item;
      }),
    );
  };

  const handleApproveAllBtn = () => {
    setMedia((prevMedia) =>
      prevMedia.map((item) => ({
        ...item,
        status_id: ModerationStatus.Ok,
      })),
    );
  };

  const handleDeclineAllBtn = () => {
    setMedia((prevMedia) =>
      prevMedia.map((item) => ({
        ...item,
        status_id: ModerationStatus.Ban,
      })),
    );
  };

  const handlePageChange = (nextTargetPage: number) => {
    setActivePage(Number(nextTargetPage));

    if (ref?.current) {
      setTimeout(
        () =>
          ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' }),
        400,
      );
    }
  };

  if (!media || !pathname.endsWith(`/${tabNumber}`)) {
    return null;
  }

  if (media.length === 0) {
    return <p>No media for moderation</p>;
  }

  return (
    <div ref={ref}>
      {currentEnv !== Env.Production && (
        <>
          <SuccessButton
            style={{ marginBottom: '5px' }}
            onClick={handleApproveAllBtn}
          >
            Approve all
          </SuccessButton>
          <ErrorButton
            style={{ marginBottom: '5px' }}
            onClick={handleDeclineAllBtn}
          >
            Decline all
          </ErrorButton>
        </>
      )}

      <StyledImageList cols={4} gap={10}>
        {renderedPhotos.map((item) => (
          <StyledImageListItem key={item.id}>
            {item.type === MediaType.Video ||
            item.type === MediaType.PrivateLibraryVideo ? (
              <VideoItem src={item.file_url} controls />
            ) : (
              <Zoom>
                <PhotoItem src={item.file_url} loading="lazy" />
              </Zoom>
            )}

            {item.status_id === ModerationStatus.Process && (
              <NewBadge>New</NewBadge>
            )}

            <MediaModerationBtn
              item={item}
              isEroticCheckboxActive={
                item.type === MediaType.PrivateLibraryPhoto ||
                item.type === MediaType.PrivateLibraryVideo
              }
              handleModerateMedia={handleModerateMedia}
            />
          </StyledImageListItem>
        ))}
      </StyledImageList>

      <CustomPagination
        pages={pages}
        activePage={activePage}
        onChangeActivePage={handlePageChange}
      />
    </div>
  );
});
