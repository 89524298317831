import { Grid } from '@mui/material';
import { FC } from 'react';
import { TextInput } from 'react-admin';

export const GoogleAdsConfig: FC = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={3}>
        <TextInput
          source="ga_measurement_id"
          sx={{ width: '100%' }}
          required
          variant="filled"
        />
      </Grid>
      <Grid item xs={9}>
        <TextInput
          source="ga_api_secret"
          sx={{ width: '100%' }}
          variant="filled"
          required
        />
      </Grid>
    </Grid>
  );
};
