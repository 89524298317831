import { FC } from 'react';
import { Edit, useRecordContext } from 'react-admin';

import SupplyForm from './SupplyForm';

const SupplyTitle: FC = () => {
  const record = useRecordContext();

  return record ? <span>Supply #{record.id}</span> : null;
};

const SupplyEdit: FC = () => {
  return (
    <Edit title={<SupplyTitle />}>
      <SupplyForm isEdit />
    </Edit>
  );
};

export default SupplyEdit;
