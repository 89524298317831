import TrustedUserModerationIcon from '@mui/icons-material/RecentActors';

import TrustedUserModerationList from './TrustedUserModerationList';
import TrustedUserModerationEdit from './TrustedUserModerationEdit';

export default {
  list: TrustedUserModerationList,
  edit: TrustedUserModerationEdit,
  icon: TrustedUserModerationIcon,
};
