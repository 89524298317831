import { FC } from 'react';
import { useRecordContext } from 'react-admin';

import OtherDetailTextField from './OtherDetailTextField';
import { Grid } from '@mui/material';
import styled from 'styled-components';

import { ReasonBadge } from 'component/common/ReasonBadge';

const Caption = styled.p`
  margin: 8px 0 0 0;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
`;

interface Props {
  declineReason: string;
}

export const OtherDetailsSection: FC<Props> = ({ declineReason }) => {
  const record = useRecordContext();

  if (!record.options) return null;

  return (
    <Grid container sx={{ marginBottom: '10px' }}>
      {!!declineReason && (
        <Grid xs={12} item>
          <ReasonBadge reason={declineReason} color="#d32f2f" />
        </Grid>
      )}

      <Grid xs={6} item>
        <Caption>Gender</Caption>
        {!!record.other_details.gender && (
          <span>{record.other_details.gender === 1 ? 'Male' : 'Female'}</span>
        )}
        <OtherDetailTextField
          label="Body type"
          field="body_type"
          record={record}
        />
      </Grid>
      <Grid xs={6} item>
        <OtherDetailTextField label="Height" field="height" record={record} />
        <OtherDetailTextField label="Weight" field="weight" record={record} />
      </Grid>
      <Grid xs={6} item>
        <OtherDetailTextField
          label="Color of eyes"
          field="color_of_eyes"
          record={record}
        />
        <OtherDetailTextField
          label="Color of hair"
          field="color_of_hair"
          record={record}
        />
      </Grid>
      <Grid xs={6} item>
        <OtherDetailTextField
          label="Marital status"
          field="marital_status"
          record={record}
        />
        <Caption>Count of children</Caption>
        {record?.other_details?.count_children}
      </Grid>
      <Grid xs={6} item>
        <OtherDetailTextField
          label="Education"
          field="education"
          record={record}
        />
        <OtherDetailTextField
          label="English level"
          field="english_level"
          record={record}
        />
      </Grid>
      <Grid xs={6} item>
        <Caption>Other languages</Caption>
        {!!record?.other_details?.other_languages.length && (
          <span>
            {record.other_details.other_languages.map(
              (hobby: string, index: number) =>
                index === record.other_details.other_languages.length - 1
                  ? record.options.other_languages.options[hobby].label
                  : record.options.other_languages.options[hobby].label + ', ',
            )}
          </span>
        )}
        <OtherDetailTextField
          label="Religion"
          field="religion"
          record={record}
        />
      </Grid>
      <Grid xs={6} item>
        <OtherDetailTextField label="Smoking" field="smoking" record={record} />
        <OtherDetailTextField
          label="Drinking"
          field="drinking"
          record={record}
        />
      </Grid>
      <Grid xs={6} item>
        <Caption>Traits</Caption>
        {!!record?.other_details?.traits.length && (
          <span>
            {record.other_details.traits.map((hobby: string, index: number) =>
              index === record.other_details.traits.length - 1
                ? record.options.traits.options[hobby].label
                : record.options.traits.options[hobby].label + ', ',
            )}
          </span>
        )}
        <Caption>Hobbies</Caption>
        {!!record?.other_details?.hobbies.length && (
          <span>
            {record.other_details.hobbies.map((hobby: string, index: number) =>
              index === record.other_details.hobbies.length - 1
                ? record.options.hobbies.options[hobby].label
                : record.options.hobbies.options[hobby].label + ', ',
            )}
          </span>
        )}
      </Grid>
      <Grid xs={6} item>
        <OtherDetailTextField
          label="Personality type"
          field="personality_type"
          record={record}
        />
        <Caption>Goals</Caption>
        {!!record?.other_details?.goal.length && (
          <span>
            {record.other_details.goal.map((hobby: string, index: number) =>
              index === record.other_details.goal.length - 1
                ? record.options.goal.options[hobby].label
                : record.options.goal.options[hobby].label + ', ',
            )}
          </span>
        )}
      </Grid>
      <Grid xs={6} item>
        <Caption>People aged min</Caption>
        {record?.other_details?.people_aged_min}
        <Caption>People aged max</Caption>
        {record?.other_details?.people_aged_max}
      </Grid>
      <Grid xs={12} item>
        <Caption>Occupation</Caption>
        {record?.other_details?.occupation}
        <Caption>Description</Caption>
        {record?.other_details?.about_me}
      </Grid>
    </Grid>
  );
};
