import { FC } from 'react';
import { Create } from 'react-admin';
import TextCategoryForm from './TextCategoryForm';

const TextCategoryCreate: FC = () => {
  return (
    <Create title="Create Text Category" redirect="list">
      <TextCategoryForm />
    </Create>
  );
};
export default TextCategoryCreate;
