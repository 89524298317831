import { FC, useCallback, useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import styled from 'styled-components';


const StyledFormControl= styled(FormControl)`
  min-width: 150px;
`;

const StyledSelect = styled(Select)`
  padding: 0 10px;
`;

interface Props {
  currentId: string;
  usedIdList: string[];
  supplyIdList: number[];
  setSupplyItemId: (supplyId: string) => void
}

export const SupplyIdSelect: FC<Props> = ({ currentId, usedIdList, supplyIdList, setSupplyItemId}) => {
  const [supplyId, setSupplyId] = useState(''); 
  
  const handleChangeSupplyId = useCallback((event) => {       
    if(usedIdList.includes(event.target.value)) {
      alert('This id is not available');
      
      return;
    }

    setSupplyId(event.target.value);
    setSupplyItemId(event.target.value);
  }, [usedIdList]);

  useEffect(() => {
    if(!supplyId && currentId) {
      setSupplyId(currentId)
    }
  }, [currentId, supplyId]);

  if(!supplyIdList.length) {
    return null
  }

  return ( 
    <StyledFormControl variant='standard'>
      <InputLabel id="placement-id's-label">Supply id:&nbsp;</InputLabel>
      <StyledSelect
        labelId="placement-id's-label"
        id="placement-id's-select"
        value={supplyId}
        onChange={handleChangeSupplyId}
      >
        {supplyIdList.map(id => <MenuItem value={id} key={id}>{id}</MenuItem>)}
      </StyledSelect>
    </StyledFormControl>
  );
};


