import { TextInput, useRecordContext } from 'react-admin';
import GreyButton from '../buttons/GreyButton';
import styled from 'styled-components';

const ClipboardButton = styled(GreyButton)`
  margin-top: 15px;
  margin-left: 10px;
`;

const CopyClipboardInput = ({ source, ...rest }: any) => {
  const record = useRecordContext();

  return (
    <div>
      <TextInput record={record} source={source} {...rest} />
      <ClipboardButton
        onClick={() => {
          navigator.clipboard.writeText(record[source]);
        }}
      >
        Copy
      </ClipboardButton>
    </div>
  );
};
export default CopyClipboardInput;
