import {
  Datagrid,
  List,
  TextField,
  BooleanField,
  NumberField,
  TopToolbar,
  CreateButton,
} from 'react-admin';
import CustomPagination from 'layout/CustomPagination';
import styled from 'styled-components';
import { CsvExport } from 'component/common/CsvExport';
import { CSVSource } from 'types/enums/CSVSource';

const StyledNumberField = styled(NumberField)`
  width: 30px;
  text-align: center;
`;

const Actions = () => {
  return (
    <TopToolbar
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
      }}
    >
      <CreateButton />
      <CsvExport source={CSVSource.SupplyAgent} />
    </TopToolbar>
  );
};

const SupplyAgentsList = () => {
  return (
    <List
      perPage={15}
      actions={<Actions />}
      sort={{ field: 'updated_at', order: 'DESC' }}
      pagination={<CustomPagination />}
    >
      <Datagrid optimized rowClick="edit" bulkActionButtons={false}>
        <StyledNumberField
          source="id"
          options={{ useGrouping: false }}
          sortable={false}
        />

        <StyledNumberField
          label="Carthage agent id"
          source="external_supply_agent_id"
          style={{ color: 'rgb(59,130,246)' }}
          options={{ useGrouping: false }}
          sortable={false}
          textAlign="center"
        />

        <TextField source="name" sortable={false} />
        <TextField source="email" sortable={false} />
        <TextField source="supply.name" title="Supply name" sortable={false} />
        <TextField source="supply.site_ids" title="Site ids" sortable={false} />
        <BooleanField source="is_active" sortable={false} />
      </Datagrid>
    </List>
  );
};

export default SupplyAgentsList;
