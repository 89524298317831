import { useCallback, useEffect, useState } from 'react';
import { Title, useNotify } from 'react-admin';
import styled from 'styled-components';
import { Card, Container, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SaveIcon from '@mui/icons-material/Save';
import { grey } from '@mui/material/colors';

import { httpClient } from 'httpClient';

import { PlacementItem } from './PlacementItem';

const Root = styled(Container)`
    padding: 30px 0;
`;

const StyledCard = styled(Card)`
  background-color: ${()=> grey[100]};
`

export interface SupplyItemType {
  supply_id: string; 
  weight: string;
};

export interface Placement {
  placement: 1 | 2 | 3 | 4 | 5 | 6;
  items: {[key: string]: SupplyItemType}[];
}

export const WizardEditConfigs = () => {
  const [updatedAt, setUpdatetdAt ] = useState('');
  const [supplyIdList, setSupplyIdList] = useState<number[]>([]);
  const [placementData, setPlacementData] = useState<Placement[]>([
    {placement: 1, items: []}, 
    {placement: 2, items: []}, 
    {placement: 3, items: []}, 
    {placement: 4, items: []}, 
    {placement: 5, items: []}, 
    {placement: 6, items: []}
  ]);

  const notify = useNotify();

  const fetchSupplyIdList = useCallback(() => {
    httpClient
    .get('/get-supplies')
    .then(response => {
      setSupplyIdList(response.data)
    })
    .catch(error => alert(error));
  }, []);

  const fetchWizardConfig = useCallback(() => {
    httpClient
    .get('/get-wizard-finder-config')
    .then(response => {
      const { finder } = response.data;

      if (!finder) {
        return
      }

      setUpdatetdAt(finder.updated_at);

      const formattedConfig = finder.config?.map((item: {supply_ids: string[], weight: Record<string, string>[]}, index: number) => {
        const items = item.supply_ids.map((id: string, idIndex: number) => {
          //@ts-ignore
          return {[String(idIndex)]: {supply_id: id, weight: item.weight[id]}}
        })

        return { placement: index + 1, id: Math.random() *1000, items }
      });

      setPlacementData(formattedConfig);
    })
    .catch(error => alert(error));
  }, []);

  const updateWizardFinderConfig = useCallback((event) => {
    event.preventDefault();

    const updatedData = placementData.map(placementItem => {
      return placementItem.items.map((item, index) => {
        return item[index]
        }, [])
    });
      
    const isValidData = !updatedData.find(item => item.find(el => !el.supply_id));

    isValidData ?
     httpClient
    .post('/save-wizard-config', {finder_pack: JSON.stringify(updatedData)})
    .then(() => {
      notify('Your data has been successfully saved!', {type: 'success'})
    })
    .catch(error => notify(
      error?.message || 'Oops, something went wrong. Reload page and try again!', {type: 'error'}
    )) 
    : notify('Сheck the missing field', {type: 'warning'});

  },[placementData]);

const handleUpdateData = useCallback((placementItem, index, supplyItemIndex) => {    
    setPlacementData(state => {
      const copyState = JSON.parse(JSON.stringify(state));
      copyState[index].items[supplyItemIndex] = {[supplyItemIndex]: placementItem};

      return copyState;
    });
  }, []);

  const handleAddSupply = useCallback((placementIndex) => {   
    setPlacementData(state => {
      const copyState = JSON.parse(JSON.stringify(state));
      copyState[placementIndex].items[copyState[placementIndex].items.length] = {[copyState[placementIndex].items.length]: {supply_id: '', weight: ''}};
    

      return copyState;
    });
  }, []);

  const handleRemoveItem = useCallback((updatedPlacement, index) => {
    setPlacementData(state => {
      const copyState = JSON.parse(JSON.stringify(state));   

      copyState[index].items = [...updatedPlacement];

      return copyState;
    })
  }, []);

  useEffect(() => {
    fetchWizardConfig();
  }, []);

  useEffect(() => {
    if(!supplyIdList.length) {
      fetchSupplyIdList();
    }
  }, [supplyIdList]);

  return (
    <Root fixed>
      <Title title="Wizard Finder Config" />
      <StyledCard>
        <form autoComplete="off" onSubmit={updateWizardFinderConfig}>
          <Grid container spacing={3}>
            <Grid  item xs={12}>
              <ul>
                {placementData.map((placement, index) => (
                    <PlacementItem 
                      key={Math.random() * 1000} 
                      placementItems={placement.items}
                      index={index} 
                      placementPosition={placement.placement}
                      supplyIdList={supplyIdList}
                      setPlacementSupplyItem={handleUpdateData}
                      onAddSupplyItem={handleAddSupply}
                      onRemoveSupplyItem={handleRemoveItem}
                    />
                  )
                  )}
              </ul>
            </Grid>
          
            <Grid  item xs={12} container spacing={3} sx={{ margin:0, padding: '0 0  20px'}}>
                <Grid  item xs={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ backgroundColor: "#626262"}}
                    startIcon={<SaveIcon />}
                    type="submit"
                  >
                    Save
                  </Button>
                </Grid>

                <Grid  item xs={9}>
                  <Typography  component="span"> Last updated at: {new Date(updatedAt).toUTCString()}</Typography>
                </Grid>
            </Grid>
          </Grid>
        </form>
      </StyledCard>
    </Root>
  );
};


