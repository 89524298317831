import { useEffect, useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  SelectArrayInput,
} from 'react-admin';

import CustomPagination from 'layout/CustomPagination';
import styled from 'styled-components';

import { getArrayFromLinkedList } from 'utils';

const StyledNumberField = styled(NumberField)`
  width: 30px;
  text-align: center;
`;

const TrustedUserModerationList = () => {
  const [suppliesSelectChoses, setSuppliesSelectChoses] = useState<
    { id: number; name: string }[]
  >([]);

  useEffect(() => {
    const fetchSupplies = async () => {
      const suppliesArray = await getArrayFromLinkedList('/supply');

      const supplyIdList = suppliesArray.map((element) => ({
        id: element.id,
        name: String(element.id),
      }));

      setSuppliesSelectChoses(supplyIdList);
    };

    fetchSupplies();
  }, []);

  return (
    <List
      title="Trusted users"
      perPage={15}
      sort={{ field: 'id', order: 'DESC' }}
      pagination={<CustomPagination />}
      filters={[
        <SelectArrayInput
          label="Supply id"
          source="supply_id"
          choices={suppliesSelectChoses}
          alwaysOn
          variant="filled"
        />,
      ]}
    >
      <Datagrid optimized rowClick="edit" bulkActionButtons={false}>
        <StyledNumberField
          source="id"
          label="id"
          options={{ useGrouping: false }}
          sortable={false}
          textAlign="center"
        />

        <StyledNumberField
          source="supply.id"
          label="Supply id"
          options={{ useGrouping: false }}
          sortable={false}
          textAlign="center"
        />

        <StyledNumberField
          label="Carthage id"
          source="external_id"
          style={{ color: 'rgb(59,130,246)' }}
          options={{ useGrouping: false }}
          sortable={false}
          textAlign="center"
        />

        <TextField label="Email" source="documents.email" sortable={false} />

        <NumberField
          source="documents.date_of_birth"
          label="Date of birth"
          sortable={false}
          textAlign="center"
        />

        <TextField label="Name" source="documents.name" sortable={false} />

        <TextField
          label="City"
          source="documents.city_id.name"
          sortable={false}
        />

        <DateField
          source="created_at"
          locales="ru-RU"
          showTime
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};

export default TrustedUserModerationList;
