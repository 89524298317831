import { useEffect, useState } from 'react';
import { useRecordContext } from 'react-admin';
import styled from 'styled-components';

import { SiteIdsSelect } from './SiteIdsSelect';
import { PidvalApi, SiteIdChoices } from 'api/PidvalApi';
import { ConfirmationDialog } from './ConfirmationDialog';

const Root = styled.div`
  position: relative;
  padding-top: 20px;
`;

export const SiteIdsSelectBlock = () => {
  const record = useRecordContext();

  const [siteIdChoices, setSiteIdChoices] = useState<SiteIdChoices[]>([]);
  const [isChoicesLoaded, setIsChoicesLoaded] = useState(false);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [isPopupShown, setIsPopupShown] = useState(false);
  const [newSelectedIds, setNewSelectedIds] = useState<number[]>([]);

  const handleToggleConfirmationDialog = () => {
    setIsPopupShown(!isPopupShown);
  };

  const handleClickOnSelectOption = (event: any) => {
    setNewSelectedIds(event.target.value);
    handleToggleConfirmationDialog();
  };

  const handleChange = (newSelectedIds: number[]) => {
    setSelectedIds(newSelectedIds);
  };

  useEffect(() => {
    const setSiteId = async () => {
      try {
        const siteInfo = await PidvalApi.fetchMirrorShortInfo();

        setSiteIdChoices(
          siteInfo.filter((idInfo) =>
            record?.supply?.site_ids?.find(
              (availableItem: number) =>
                String(availableItem) === String(idInfo.id),
            ),
          ),
        );
      } catch (error) {
        console.error(error);
      }
    };

    if (!isChoicesLoaded) {
      setIsChoicesLoaded(true);
      setSiteId();
    }
  }, [isChoicesLoaded]);

  useEffect(() => {
    if (record.site_ids) {
      setSelectedIds(record.site_ids);
    }
  }, [record.site_ids]);

  return (
    <Root>
      <SiteIdsSelect
        siteIdChoices={siteIdChoices}
        selectedIds={selectedIds}
        onClickSelectOption={handleClickOnSelectOption}
      />
      <ConfirmationDialog
        isOpen={isPopupShown}
        userId={Number(record.id)}
        siteIdChoices={siteIdChoices}
        selectedIds={selectedIds}
        newSelectedIds={newSelectedIds}
        onClose={handleToggleConfirmationDialog}
        onChangeSelectedIds={handleChange}
      />
    </Root>
  );
};
