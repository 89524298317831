import { FC, useEffect, useState } from 'react';
import {
  SimpleForm,
  required,
  Toolbar,
  SaveButton,
  NumberInput,
  SelectInput,
  minValue,
  maxValue,
  useRecordContext,
} from 'react-admin';
import { grey } from '@mui/material/colors';

import { PidvalApi } from '../../api/PidvalApi';
import styled from 'styled-components';

const StyledSelectInput = styled(SelectInput)<{ $disabled: boolean }>`
  & .MuiInputBase-root {
    border-bottom: ${({ $disabled }) => $disabled && grey[50]};
    color: ${({ $disabled }) => $disabled && grey[500]};
    background-color: ${({ $disabled }) => $disabled && grey[50]};
    pointer-events: ${({ $disabled }) => $disabled && 'none'};
  }
`;

export const IceBreakerConfigForm: FC = () => {
  const record = useRecordContext();

  const [supplyChoices, setSupplyChoices] = useState<any[]>([]);

  useEffect(() => {
    const setSupplyList = async () => {
      try {
        const choices = await PidvalApi.fetchSupplyListForIcebreakerConfig();

        setSupplyChoices(
          choices.map((supply: { id: string; name: string }) => ({
            id: supply.id,
            name: String(supply.id),
          })),
        );
      } catch (error) {
        console.error(error);
      }
    };

    setSupplyList();
  }, []);

  return (
    <SimpleForm
      toolbar={
        <Toolbar>
          <SaveButton alwaysEnable />
        </Toolbar>
      }
    >
      {!!supplyChoices.length && (
        <StyledSelectInput
          label="Supply Id"
          source="supply_id"
          choices={supplyChoices}
          $disabled={!!record?.id}
          validate={[required()]}
          variant="filled"
        />
      )}

      <NumberInput
        source="weight"
        min={1}
        max={1000}
        defaultValue={100}
        validate={[
          required(),
          minValue(1, 'Value must be greater than 1'),
          maxValue(1000, 'Value must be less than 1000'),
        ]}
        variant="filled"
      />
    </SimpleForm>
  );
};
