import { PostbackEventConfigAges } from 'model-types';

const checkUniquenessOfAgeCategories = ({
  min,
  max,
  configListItenIndex,
  ageConfigList,
  errors,
}: {
  min: number;
  max: number;
  configListItenIndex: number;
  ageConfigList: PostbackEventConfigAges[];
  errors: any;
}): any => {
  if (configListItenIndex === 0) {
    return;
  }

  if (
    min >= ageConfigList[configListItenIndex - 1].min &&
    min <= ageConfigList[configListItenIndex - 1].max
  ) {
    errors[configListItenIndex] = {
      ...errors[configListItenIndex],
      min: `You already have a config for age ${ageConfigList[configListItenIndex].min}`,
    };
  }

  if (
    max >= ageConfigList[configListItenIndex - 1].min &&
    max <= ageConfigList[configListItenIndex - 1].max
  ) {
    errors[configListItenIndex] = {
      ...errors[configListItenIndex],
      max: `You already have a config for age ${ageConfigList[configListItenIndex].max}`,
    };
  }

  return checkUniquenessOfAgeCategories({
    min,
    max,
    ageConfigList,
    configListItenIndex: configListItenIndex - 1,
    errors,
  });
};

export const ageConfigValidation = ({
  ageConfigList,
  postbackErrors,
}: {
  ageConfigList: PostbackEventConfigAges[];
  postbackErrors: any;
}) => {
  if (ageConfigList?.length) {
    let percentage = 0;
    const errors: any = { postback_event_config_ages: [] };

    for (let index = 0; index < ageConfigList.length; index++) {
      const ageConfig = ageConfigList[index];

      // if (ageConfig?.min > ageConfig?.max) {
      //   errors[index] = {
      //     ...errors[index],
      //     max: `The max age cannot be less than the min age`,
      //   };
      // }

      if (index !== 0) {
        checkUniquenessOfAgeCategories({
          min: ageConfig.min,
          max: ageConfig.max,
          ageConfigList,
          configListItenIndex: index,
          errors: errors.postback_event_config_ages,
        });
      }

      // if (ageConfig?.min < 18) {
      //   errors[index] = {
      //     ...errors[index],
      //     min: 'Age must be at least 18 years old',
      //   };
      // }

      // if (ageConfig?.min > 99) {
      //   errors[index] = {
      //     ...errors[index],
      //     min: 'Age cannot exceed 99 years',
      //   };
      // }

      // if (ageConfig?.max < 18) {
      //   errors[index] = {
      //     ...errors[index],
      //     max: 'Age must be at least 18 years old',
      //   };
      // }

      // if (ageConfig?.max > 99) {
      //   errors[index] = {
      //     ...errors[index],
      //     max: 'Age cannot exceed 99 years',
      //   };
      // }

      percentage = percentage + ageConfig?.shave;

      if (percentage > 100) {
        errors.postback_event_config_ages[index] = {
          ...errors.postback_event_config_ages[index],
          shave: 'Total sum from the shave fields should not exceed 100%',
        };
      }

      if (errors.postback_event_config_ages.length) {
        postbackErrors.postback_event_config_ages =
          errors.postback_event_config_ages;
      }
    }
  }
};
