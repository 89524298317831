import { FC } from 'react';
import { Create } from 'react-admin';
import PartnerForm from './PartnerForm';

const PartnerCreate: FC = () => {
  return (
    <Create title="Create Partner" redirect="list">
      <PartnerForm />
    </Create>
  );
};

export default PartnerCreate;
