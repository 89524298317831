import { useState } from 'react';
import { DateField, Toolbar, useRecordContext, useRefresh } from 'react-admin';

import { httpClient } from 'httpClient';

import CustomSelect from 'layout/input/CustomSelect';
import ErrorButton from 'layout/buttons/ErrorButton';
import { logoutTimes } from '../../field-choices';
import styled from 'styled-components';

const LogoutBlock = styled.div`
  display: flex;
  align-items: center;
`;

const StyledToolbar = styled(Toolbar)`
  position: sticky;
  bottom: 0;
  margin-top: auto;
`;
const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CustomToolbar = () => {
  const record = useRecordContext();

  const [selectedLogoutTime, setSelectedLogoutTime] = useState(
    logoutTimes[0].value,
  );

  const [isForceLogout, setIsForceLogout] = useState(false);

  const refresh = useRefresh();

  const handleLogout = () => {
    if (
      confirm(`Log out user # ${record.id} on ${selectedLogoutTime} minutes?`)
    ) {
      httpClient
        .get(`/trusted-user/${record.id}/logout?block_on=${selectedLogoutTime}`)
        .then((response) => {
          if (response.status) {
            setIsForceLogout(true);
          }
          refresh();
        });
    }
  };

  return (
    <StyledToolbar>
      <Container>
        <div>
          Last login:&#160;
          <DateField
            source="last_login"
            locales="ru-RU"
            showTime
            sortable={false}
          />
        </div>

        <LogoutBlock>
          Logout user on
          <CustomSelect
            choices={logoutTimes}
            onChange={setSelectedLogoutTime}
          />
          <ErrorButton
            size="small"
            onClick={handleLogout}
            disabled={isForceLogout || !record.is_online}
          >
            Log out
          </ErrorButton>
        </LogoutBlock>
      </Container>
    </StyledToolbar>
  );
};

export default CustomToolbar;
