import { ValidateForm } from 'react-admin';
import { PostbackType } from 'types/PostbackType';
import { validateTimings } from './timingsValidation';
import { ageConfigValidation } from './ageConfigValidation';
import { validateEmailConfigs } from './emailConfigsValidation';
import { Partner, PostbackEventConfig } from 'model-types';
import { PostbackEventType } from '../submodules/field-choices';
import { validateSubsourceRules } from './subsourceRulesValidation';

const hasApiBuying = (partner: Partner): boolean => {
  if (partner.traffic_type !== 'api') {
    return false;
  }
  if (
    !partner.api_buying_config ||
    !(
      partner.api_buying_config.is_reg_enabled ||
      partner.api_buying_config.is_visit_enabled
    )
  ) {
    return false;
  }

  return true;
};

const hasPostbackForSubsource = (
  postbacks: PostbackEventConfig[],
  subsourceId: number | null,
  eventType: number,
): boolean => {
  if (!postbacks.length) {
    return false;
  }
  for (let postback of postbacks) {
    if (
      postback &&
      postback.subsource_id === subsourceId &&
      postback.event_type === eventType
    ) {
      return true;
    }
  }
  return false;
};

export const needVisitPostback = (
  partner: Partner,
  regPostback: PostbackEventConfig,
): boolean => {
  if (PostbackEventType.Reg !== regPostback?.event_type) {
    return false;
  }
  if (!(hasApiBuying(partner) && partner.api_buying_config.is_visit_enabled)) {
    return false;
  }
  return !hasPostbackForSubsource(
    partner.postback_event_configs,
    regPostback.subsource_id,
    PostbackEventType.Visit,
  );
};

export const formValidation: ValidateForm = async (partner) => {
  const errors: any = {};

  if (!partner.network_name && !partner.network_id) {
    errors.network_id = 'Required!';
  }

  if (
    partner.traffic_type === 'api' &&
    partner.postback_type !== PostbackType.Affiliate
  ) {
    errors.traffic_type = 'Use Affiliate postback!';
  }

  if (!!partner.subsource_groups?.length) {
    errors.subsource_groups = [];

    for (let i = 0; i < partner.subsource_groups.length; i++) {
      if (partner.subsource_groups[i]) {
        let subsourceGroup = partner.subsource_groups[i];

        const subsourceGroupsErrors: any = {};

        let configEmails = subsourceGroup.partner_config_emails ?? [];
        validateEmailConfigs({
          errors: subsourceGroupsErrors,
          configEmails,
        });

        if (!subsourceGroup.id) {
          subsourceGroup.partner_id = partner.id;
        }

        await validateSubsourceRules(subsourceGroupsErrors, subsourceGroup);

        let type = subsourceGroup.event_type ?? null;

        if (!type) {
          subsourceGroupsErrors.event_type = 'Event type required';
        }

        errors.subsource_groups[i] = subsourceGroupsErrors;
      }
    }
  }

  if (!!partner?.postback_event_configs?.length) {
    const uniqueRows: string[] = [];
    errors.postback_event_configs = [];

    for (let i = 0; i < partner.postback_event_configs.length; i++) {
      if (partner.postback_event_configs[i]) {
        let eventConfig = partner.postback_event_configs[i];

        const postbackEventConfigsErrors: any = {};

        let timings = eventConfig?.postback_event_config_timings ?? [];
        validateTimings({
          postbackErrors: postbackEventConfigsErrors,
          timings,
        });

        let configEmails = eventConfig?.partner_config_emails ?? [];
        validateEmailConfigs({
          errors: postbackEventConfigsErrors,
          configEmails,
        });

        if (timings.length > 0 && configEmails.length > 0) {
          postbackEventConfigsErrors.partner_config_emails = [
            {
              domain: 'Can not use email config together with timing config',
            },
          ];
        }

        let subsource_id = eventConfig.subsource_id ?? null;
        let type = eventConfig.event_type ?? null;
        let enabled_percentage = eventConfig.enabled_percentage ?? null;

        let uniqueRow = subsource_id + String(type);
        let hasSimpleErrors = false;

        if (!hasSimpleErrors) {
          if (uniqueRows.indexOf(uniqueRow) > -1) {
            postbackEventConfigsErrors.subsource_id =
              'This subsource with event type is already configured';

            postbackEventConfigsErrors.event_type =
              'This event type with subsource is already configured';
          } else {
            uniqueRows.push(uniqueRow);
          }
        }

        if (!type) {
          postbackEventConfigsErrors.event_type = 'Event type required';

          hasSimpleErrors = true;
        }

        if (
          enabled_percentage &&
          (enabled_percentage > 100 || enabled_percentage < 0)
        ) {
          postbackEventConfigsErrors.enabled_percentage =
            'Not valid field value';

          hasSimpleErrors = true;
        }

        const ageConfigList = eventConfig.postback_event_config_ages ?? [];

        ageConfigValidation({
          ageConfigList,
          postbackErrors: postbackEventConfigsErrors,
        });

        if (needVisitPostback(partner as Partner, eventConfig)) {
          postbackEventConfigsErrors.event_type = [
            'Postback for visit not configured',
          ];
        }

        errors.postback_event_configs[i] = postbackEventConfigsErrors;
      }
    }
  }

  return errors;
};
