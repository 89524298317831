import { FC } from 'react';
import { Edit, useRecordContext } from 'react-admin';
import PartnerForm from './PartnerForm';

const PartnerTitle: FC = () => {
  const record = useRecordContext();

  return record ? <span>Partner #{record.id}</span> : null;
};

const PartnerEdit: FC = () => {
  return (
    <Edit title={<PartnerTitle />}>
      <PartnerForm />
    </Edit>
  );
};

export default PartnerEdit;
