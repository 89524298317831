import { useEffect, useState } from 'react';
import {
  Datagrid,
  DateField,
  List,
  NumberField,
  TextField,
  NumberInput,
  SelectArrayInput,
  BooleanField,
  SelectInput,
  FunctionField,
  NullableBooleanInput,
  TextInput,
  TopToolbar,
  SelectColumnsButton,
  DatagridConfigurable,
  Pagination,
} from 'react-admin';

import { getArrayFromLinkedList } from 'utils';

import { moderationStatusChoices, onlineTypeChoices } from 'consts';
import styled from 'styled-components';
import BooleanFromValueField from 'layout/fields/BooleanFromValueField';
import { OnlineType } from 'types/enums/OnlineType';
import { TrustedUser } from 'types/trustedUser/trustedUserModeration';
import { CsvExport } from 'component/common/CsvExport';
import { CSVSource } from 'types/enums/CSVSource';

const StyledNumberField = styled(NumberField)`
  width: 30px;
  text-align: center;
`;

interface SuppliesSelectChoses {
  id: number;
  name: number;
}

const filters = (suppliesSelectChoses: SuppliesSelectChoses[]) => [
  <NumberInput
    source="id"
    label="Id"
    alwaysOn
    style={{ width: '90px' }}
    variant="filled"
  />,

  !!suppliesSelectChoses && (
    <SelectArrayInput
      alwaysOn
      label="Supply id"
      source="supply_id"
      choices={suppliesSelectChoses}
      style={{ minWidth: '90px' }}
      variant="filled"
    />
  ),

  <NumberInput
    source="external_id"
    label="Carthage id"
    alwaysOn
    style={{ width: '90px' }}
    variant="filled"
  />,

  <SelectInput
    alwaysOn
    label="Status"
    source="moderation_status"
    choices={moderationStatusChoices}
    style={{ minWidth: '100px', marginBottom: 0 }}
    variant="filled"
  />,
  <SelectInput
    alwaysOn
    label="Online Type"
    source="online_type"
    choices={onlineTypeChoices}
    style={{ minWidth: '100px', marginBottom: 0 }}
    variant="filled"
  />,

  <NullableBooleanInput
    alwaysOn
    source="is_online"
    label="Is Online"
    style={{ minWidth: '100px' }}
    variant="filled"
  />,

  <NullableBooleanInput
    alwaysOn
    source="is_new"
    label="Is New"
    style={{ minWidth: '100px' }}
    variant="filled"
  />,
  <TextInput
    source="name"
    label="Name"
    alwaysOn
    style={{ width: '100px' }}
    variant="filled"
  />,
  <TextInput
    source="lastname"
    label="Lastname"
    alwaysOn
    style={{ width: '100px' }}
    variant="filled"
  />,

  <TextInput
    source="email"
    label="Email"
    alwaysOn
    style={{ width: '100px' }}
    variant="filled"
  />,

  <TextInput
    source="passport_number"
    label="Passport number"
    alwaysOn
    style={{ width: '100px' }}
    variant="filled"
  />,
];

const Actions = () => {
  return (
    <TopToolbar
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
      }}
    >
      <SelectColumnsButton />
      <CsvExport source={CSVSource.TrustedUser} />
    </TopToolbar>
  );
};

const TrustedUserList = () => {
  const [suppliesSelectChoses, setSuppliesSelectChoses] = useState<
    SuppliesSelectChoses[]
  >([]);

  const fetchSupplies = async () => {
    const suppliesArray = await getArrayFromLinkedList('/supply');

    const supplyIdList = suppliesArray.map((element) => ({
      id: element.id,
      name: element.id,
    }));

    setSuppliesSelectChoses(supplyIdList);
  };

  useEffect(() => {
    fetchSupplies();
  }, []);

  return (
    <List
      actions={<Actions />}
      filters={filters(suppliesSelectChoses)}
      sort={{ field: 'id', order: 'DESC' }}
      pagination={<Pagination rowsPerPageOptions={[]} perPage={30} />}
    >
      <DatagridConfigurable rowClick="edit" bulkActionButtons={false}>
        <StyledNumberField
          source="id"
          options={{ useGrouping: false }}
          textAlign="center"
        />
        <StyledNumberField
          label="Carthage id"
          source="external_id"
          style={{ color: 'rgb(59,130,246)' }}
          options={{ useGrouping: false }}
          sortable={false}
          textAlign="center"
        />

        <TextField label="Supply id" source="supply.id" sortable={false} />

        <TextField label="Name" source="profile_source.name" sortable={false} />

        <TextField
          label="Lastname"
          source="profile_source.lastname"
          sortable={false}
        />

        <TextField
          label="Email"
          source="profile_source.email"
          sortable={false}
        />

        <TextField
          label="Country"
          source="city.country.name"
          sortable={false}
        />

        <BooleanField
          label="Is New"
          source="facilitate_rules.new_from"
          sortable={false}
          looseValue
        />

        <BooleanFromValueField source="is_test" />
        <BooleanField source="is_online" sortable={false} />

        <FunctionField
          label="Online type"
          sortBy="online_type"
          render={(record: TrustedUser) =>
            record?.online_type ? OnlineType[record.online_type] : ''
          }
        />

        <TextField label="Status" source="moderation_status" sortable={false} />

        <DateField
          source="created_at"
          locales="ru-RU"
          showTime
          sortable={false}
        />
      </DatagridConfigurable>
    </List>
  );
};

export default TrustedUserList;
