import { httpClient } from 'httpClient';
import { SubsourceGroup } from 'model-types';

export const validateSubsourceRules = async (
  errors: any,
  subsourceGroup: SubsourceGroup,
) => {
  if (subsourceGroup.subsource_rules) {
    try {
      await httpClient.post('/subsources/validate', subsourceGroup);
    } catch (error: any) {
      if (error?.response?.status === 422) {
        for (let key in error?.response?.data) {
          const index = subsourceGroup.subsource_rules.length - 1;
          errors[key][index] = {};
          errors[key][index].field = error?.response?.data[key].join(', ');
        }
      }
    }
  }
};
