import { FC } from 'react';
import {
  SimpleForm,
  required,
  Toolbar,
  SaveButton,
  NumberInput,
  SelectInput,
  minValue,
  maxValue,
  DateTimeInput,
  ImageInput,
  ImageField,
  DeleteButton,
  useSaveContext,
  useRecordContext,
} from 'react-admin';

import { GiftType } from './field-choices';

const convertFileToBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file.rawFile);
  });

const GiftForm: FC<any> = ({ ...rest }) => {
  const record = useRecordContext();

  const { save } = useSaveContext();

  const handleSubmit = async (data: any) => {
    if (save) {
      if (data.image) {
        const base64Image = await convertFileToBase64(data.image);
        const finalData = {
          ...data,
          image_blob: base64Image, // Add Base64 image to the data
          image_name: data.image.rawFile.name,
        };
        save(finalData);
        return;
      }

      save(data);
    }
  };

  return (
    <SimpleForm
      redirect="list"
      record={record}
      toolbar={
        <Toolbar>
          <DeleteButton />
          <SaveButton alwaysEnable />
        </Toolbar>
      }
      {...rest}
      onSubmit={handleSubmit}
    >
      {!!record?.id ? (
        <ImageField source="url" title="Gift image" />
      ) : (
        <ImageInput source="image" label="Gift image">
          <ImageField source="src" title="Gift image" />
        </ImageInput>
      )}

      <SelectInput
        label="Type"
        source="type"
        choices={GiftType}
        validate={[required()]}
        variant="filled"
      />
      <NumberInput source="credits" variant="filled" />
      <NumberInput
        source="sort_order"
        min={0}
        max={1000}
        validate={[
          required(),
          minValue(0, 'Value must be greater than 1'),
          maxValue(1000, 'Value must be less than 1000'),
        ]}
        variant="filled"
      />
      <DateTimeInput source="available_from" variant="filled" />
      <DateTimeInput source="available_to" variant="filled" />
    </SimpleForm>
  );
};

export default GiftForm;
