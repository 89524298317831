import { FC, memo } from 'react';
import { Add } from '@mui/icons-material';
import _ from 'lodash';

import { PostbackEventConfig } from 'model-types';

import SuccessButton from 'layout/buttons/SuccessButton';
import { Grid } from '@mui/material';

interface Props {
  index: number;
  config: PostbackEventConfig;
  isAddVisitEnabled?: boolean;
  onDuplicate: (index: number, config: PostbackEventConfig) => void;
  onAddVisit: (index: number, config: PostbackEventConfig) => void;
}

export const PostbackEventConfigsActions: FC<Props> = memo(
  ({ index, config, isAddVisitEnabled, onDuplicate, onAddVisit }) => {
    return (
      <Grid container paddingLeft={1} marginBottom={1}>
        <SuccessButton
          startIcon={<Add />}
          size="small"
          onClick={() => onDuplicate(index, config)}
        >
          Duplicate
        </SuccessButton>

        {isAddVisitEnabled && (
          <SuccessButton
            startIcon={<Add />}
            size="small"
            onClick={() => onAddVisit(index, config)}
          >
            Add visit
          </SuccessButton>
        )}
      </Grid>
    );
  },
);
