import { TextField, useRecordContext } from 'react-admin';

const TreeNodeField = (props: any) => {
  const record = useRecordContext();

  return record ? (
    <>
      <span style={{ color: '#A9A9A9' }}>{'—'.repeat(record.level)} </span>
      <TextField {...props} />
    </>
  ) : (
    <></>
  );
};
export default TreeNodeField;
