import { FC } from 'react';
import { Create } from 'react-admin';
import ActivityConfigForm from './ActivityConfigForm';

const ActivityConfigCreate: FC = () => {
  return (
    <Create title=" activity config" redirect="list">
      <ActivityConfigForm />
    </Create>
  );
};

export default ActivityConfigCreate;
