import {
  Datagrid,
  DateField,
  List,
  TextField,
  TextInput,
  ReferenceField,
  ReferenceInput,
  TopToolbar,
  CreateButton,
  ExportButton,
  useListContext,
} from 'react-admin';
import ShortTextField from '../../../layout/fields/ShortTextField';
import TreeSelectInput from 'layout/input/TreeSelectInput';

const ListActions = () => {
  const { filterValues } = useListContext();

  const categoryId = filterValues.category_id;

  const createPath =
    '/translations/text-sources/create' +
    (categoryId ? '?category_id=' + categoryId : '');

  return (
    <TopToolbar>
      <CreateButton to={createPath} />
      <ExportButton />
    </TopToolbar>
  );
};

const filters = [
  <ReferenceInput
    label="Parent category"
    source="category_id"
    reference="translations/text-categories"
    alwaysOn
  >
    <TreeSelectInput
      optionText="name"
      variant="filled"
      style={{ marginBottom: '0' }}
    />
  </ReferenceInput>,
  <TextInput source="key" alwaysOn variant="filled" />,
  <TextInput source="value" alwaysOn variant="filled" />,
];

const TextSourceList = () => {
  return (
    <List
      filters={filters}
      perPage={25}
      sort={{ field: 'key', order: 'ASC' }}
      title="Text sources"
      actions={<ListActions />}
    >
      <Datagrid optimized rowClick="edit">
        <TextField source="key" />
        <ShortTextField source="value" />
        <ReferenceField
          label="Category"
          source="category_id"
          reference="translations/text-categories"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="id" />
        <DateField source="created_at" locales="ru-RU" showTime />
        <DateField source="updated_at" locales="ru-RU" showTime />
      </Datagrid>
    </List>
  );
};
export default TextSourceList;
