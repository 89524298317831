import { FormControl, MenuItem, Select } from '@mui/material';

interface Props {
  initialValue?: any;
  disabled?: boolean;
  choices: { id: number; label: string; value: any }[];
  onChange: (newValue: any) => void;
}

const CustomSelect = ({ initialValue, choices, disabled, onChange }: Props) => {
  const handleSelect = (e: any) => {
    onChange(e.target.value);
  };

  return (
    <FormControl sx={{ minWidth: 120 }} size="small">
      <Select
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        defaultValue={initialValue || choices[0].value}
        onChange={handleSelect}
        sx={{ magrin: 0 }}
        disabled={disabled}
      >
        {choices.map((option) => (
          <MenuItem key={option.id} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
