import AcUnitIcon from '@mui/icons-material/AcUnit';
import ActivityConfigList from './ActivityConfigList';
import ActivityConfigEdit from './ActivityConfigEdit';
import ActivityConfigCreate from './ActivityConfigCreate';

export default {
  list: ActivityConfigList,
  edit: ActivityConfigEdit,
  create: ActivityConfigCreate,
  icon: AcUnitIcon,
};
