import { FC } from 'react';

import { Create } from 'react-admin';
import { IceBreakerConfigForm } from './IceBreakerConfigForm';

const IceBreakerConfigCreate: FC = () => {
  return (
    <Create title="Create IceBreaker config" redirect="list">
      <IceBreakerConfigForm />
    </Create>
  );
};

export default IceBreakerConfigCreate;
