import { AppBar, TitlePortal } from 'react-admin';

import Logo from './Logo';
import SoundToggle from './SoundToggle';
import { Box } from '@mui/material';

const CustomAppBar = () => {
  return (
    <AppBar
      color="inherit"
      sx={{
        '& .RaAppBar-toolbar': {
          display: 'flex',
          justifyContent: 'center',
          gap: '0 15px',
        },
        '& .RaAppBar-title': {
          width: '0',
        },
      }}
    >
      <TitlePortal variant="body1" component="h1" color="inherit" />
      <Box flex="1" />
      <Logo />
      <Box flex="1" />
      <SoundToggle />
    </AppBar>
  );
};

export default CustomAppBar;
