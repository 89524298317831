import { FC } from 'react';
import styled from 'styled-components';

const Span = styled.span`
  display: block;
  margin: 8px 0 0 0;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
`;

interface Props {
  label: string;
  field: string;
  record: any;
}

const OtherDetailTextField: FC<Props> = ({ label, record, field }) => {
  return (
    <>
      <Span>{label}</Span>
      {record.other_details[field] !== undefined && (
        <span>
          {
            record.options[field].options.find(
              (option: any) => option.value === record.other_details[field],
            )?.label
          }
        </span>
      )}
    </>
  );
};

export default OtherDetailTextField;
