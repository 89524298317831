import { SelectInput } from 'react-admin';
import { cloneDeep } from 'lodash';
import { useRecordContext } from 'react-admin';

// Fix duplicate of ReferenceInput.defaultValue
const sanitizeChoices = (choices: Array<any>): Array<any> => {
  for (let i = 1; i < choices?.length; i++) {
    if (choices[i].id === choices[0].id) {
      choices.shift();
      break;
    }
  }
  return choices;
};

const TreeSelectInput = (props: any & { checkTree: boolean }) => {
  const record = useRecordContext();

  let { choices, checkTree, ...rest } = props;

  if (checkTree !== false) {
    checkTree = true;
  }

  let optionText: string = props?.optionText;
  let recordId = null;

  if (checkTree) {
    try {
      recordId = record.id;
    } catch (err) {}
  }

  choices = sanitizeChoices(cloneDeep(choices));

  let disabled = false;
  let disabledLevel = -1;

  for (let i in choices) {
    if (choices[i].level === undefined) {
      continue;
    }
    if (choices[i].level <= disabledLevel) {
      disabled = false;
    }
    if (recordId === choices[i]?.id) {
      disabled = true;
      disabledLevel = choices[i]?.level;
    }
    choices[i].disabled = disabled;
    choices[i][optionText] =
      '-'.repeat(choices[i].level) + ' ' + choices[i][optionText];
  }

  return <SelectInput choices={choices} {...rest} />;
};
export default TreeSelectInput;
