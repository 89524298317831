import {
  Datagrid,
  List,
  TextField,
  NumberField,
  DateField,
  NumberInput,
  SelectInput,
  FunctionField,
} from 'react-admin';
import CustomPagination from 'layout/CustomPagination';
import { Link } from 'react-router-dom';
import GreyButton from '../../layout/buttons/GreyButton';
import {
  activityAction,
  activityStatus,
  activityStatusReason,
  activityType,
} from './field-choices';

const activityFilter = [
  <NumberInput source="user_id" alwaysOn variant="filled" />,
  <SelectInput
    label="Type"
    source="type"
    choices={activityType}
    alwaysOn
    variant="filled"
    sx={{ marginBottom: 0 }}
  />,
  <SelectInput
    label="Action"
    source="action"
    choices={activityAction}
    alwaysOn
    sx={{ marginBottom: 0 }}
    variant="filled"
  />,
  <NumberInput
    source="source_user_id"
    alwaysOn
    style={{ width: '100px' }}
    variant="filled"
  />,
  <SelectInput
    label="Status"
    source="status"
    choices={activityStatus}
    alwaysOn
    sx={{ marginBottom: 0 }}
    variant="filled"
  />,
  <SelectInput
    label="Status reason"
    source="status_reason"
    choices={activityStatusReason}
    alwaysOn
    sx={{ marginBottom: 0 }}
    variant="filled"
  />,
];

const ActivityList = (props: any) => {
  return (
    <List
      filters={activityFilter}
      sort={{ field: 'updated_at', order: 'DESC' }}
      pagination={<CustomPagination />}
      perPage={30}
      {...props}
    >
      <Datagrid bulkActionButtons={false}>
        <NumberField
          source="id"
          options={{ useGrouping: false }}
          sortable={false}
          textAlign="center"
        />

        <FunctionField
          source="user_id"
          render={(record: any) => (
            <GreyButton
              size={'small'}
              component={Link}
              to={`/activity/user/${record.user_id}`}
            >
              {record.user_id}
            </GreyButton>
          )}
        />

        <TextField source="event" textAlign="center" sortable={false} />
        <TextField source="type" textAlign="center" sortable={false} />
        <TextField source="action" textAlign="center" sortable={false} />
        <NumberField
          source="source_user_id"
          options={{ useGrouping: false }}
          sortable={false}
          textAlign="center"
        />
        <NumberField
          source="supply_id"
          options={{ useGrouping: false }}
          sortable={false}
          textAlign="center"
          label={'Supply Id'}
        />
        <TextField source="status" textAlign="center" />
        <TextField source="status_reason" textAlign="center" sortable={false} />

        <DateField
          source="created_at"
          locales="uk-UA"
          showTime
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};

export default ActivityList;
