import { FC } from 'react';
import { Grid } from '@mui/material';
import { TextInput, BooleanInput, required } from 'react-admin';

const apiKeyValidation = [required()];

export const APIConfigs: FC = () => {
  return (
    <>
      <h3>API configs:</h3>

      <Grid container spacing={3}>
        <Grid item xs={3}>
          <TextInput
            source="api_buying_config.api_key"
            defaultValue={null}
            label="Api Key"
            validate={apiKeyValidation}
            variant="filled"
          />
        </Grid>
        <Grid item xs={2}>
          <BooleanInput
            source="api_buying_config.is_reg_enabled"
            defaultValue={false}
            label="Reg Enabled"
          />
        </Grid>
        <Grid item xs={2}>
          <BooleanInput
            source="api_buying_config.is_visit_enabled"
            defaultValue={false}
            label="Visit Enabled"
          />
        </Grid>
      </Grid>
    </>
  );
};
