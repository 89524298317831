import { green } from '@mui/material/colors';

import Button from '@mui/material/Button';
import styled from 'styled-components';
import { CircularProgress } from '@mui/material';

const StyledButton = styled(Button)`
  margin: 0 10px;
  color: #fff;
  background-color: ${() => green[500]};

  &:hover {
    background-color: ${() => green[700]};
  }
`;

const SuccessButton = ({ loading, disabled, children, ...props }: any) => {
  return (
    <StyledButton {...props} variant="contained" disabled={disabled || loading}>
      {children}
      {loading && <CircularProgress size={15} thickness={4} />}
    </StyledButton>
  );
};

export default SuccessButton;
