import { useEffect, useState } from 'react';
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
} from '@mui/material';

import { declineReasons } from 'types/icebreaker';
import { IceBreakerDeclineReason } from 'types/enums/IceBreakersTypes';

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

interface Props {
  isOpen: boolean;
  onConfirm: (reason: IceBreakerDeclineReason) => void;
  onClose: () => void;
}

export const ConfirmationDialog = ({ isOpen, onClose, onConfirm }: Props) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [reason, setReason] = useState<IceBreakerDeclineReason | null>(null);

  const handleConfirm = () => {
    if (reason) {
      setIsLoading(true);

      onConfirm(reason);
    }
  };

  const handleClose = () => {
    setReason(null);

    onClose();
  };

  const handleClick = (reason: IceBreakerDeclineReason) => {
    setReason(reason);
  };

  useEffect(() => {
    if (reason) {
      setIsButtonDisabled(false);
    }
  }, [reason]);

  return (
    <Dialog
      open={isOpen}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
    >
      <DialogTitle id="confirmation-dialog-title">
        Confirm your action
      </DialogTitle>

      <StyledDialogContent>
        {declineReasons.map((item) => (
          <Chip
            key={item.reason}
            variant="outlined"
            label={item.caption}
            style={{
              borderColor:
                item.reason === reason
                  ? 'var(--accent)'
                  : 'rgba(0, 0, 0, 0.23)',
            }}
            onClick={() => handleClick(item.reason)}
          />
        ))}
      </StyledDialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>

        <Button
          color="primary"
          disabled={isButtonDisabled || isLoading}
          onClick={handleConfirm}
        >
          Ok
          {isLoading && (
            <div style={{ position: 'absolute', top: '5px', left: '25px' }}>
              <CircularProgress color="secondary" size="30px" />
            </div>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
