import { ReactNode } from 'react';
import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import { yellow } from '@mui/material/colors';
import styled from 'styled-components';

const Root = styled(Button)`
  margin: 0 10px;
  color: #fff;
  background-color: ${() => yellow[700]};

  &:hover {
    background-color: ${() => yellow[800]};
  }

  &:disabled {
    background-color: ${() => yellow[50]};
  }
`;

interface Props {
  children: ReactNode;
  onClick?: () => void;
  isLoader?: boolean;
  className?: string;
}

const YellowButton = ({ children, onClick, isLoader = false }: Props) => {
  return (
    <Root variant="contained" onClick={onClick}>
      {isLoader ? <CircularProgress size="20px" /> : children}
    </Root>
  );
};

export default YellowButton;
