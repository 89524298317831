import { Link } from 'react-router-dom';
import textSource from '../textSource';
import { useRecordContext } from 'react-admin';
import { Button } from '@mui/material';

const LinkToRelatedSources = () => {
  let record = useRecordContext();

  return record ? (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: '/translations/text-sources',
        search: `filter=${JSON.stringify({ category_id: record.id })}`,
      }}
    >
      <textSource.icon />
      &nbsp;Text Sources
    </Button>
  ) : null;
};

export default LinkToRelatedSources;
