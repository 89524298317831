import { FC, useMemo } from 'react';
import {
  ArrayInput,
  NumberInput,
  SelectInput,
  SimpleFormIterator,
} from 'react-admin';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useWatch } from 'react-hook-form';

export const featureTypes = [
  { id: 2, name: 'text msg send' },
  { id: 5, name: 'send gift (not real)' },
  { id: 8, name: 'photo msg send' },
  { id: 10, name: 'sticker msg send' },
  { id: 11, name: 'photo msg read' },
  { id: 17, name: 'inmail send' },
  { id: 18, name: 'inmail read' },
  { id: 19, name: 'inmail photo send' },
  { id: 20, name: 'inmail photo read' },
  { id: 30, name: 'present sent (real gift)' },
];

const validateMessageLimit = (value: number) => {
  if (value <= 0) {
    return 'Value must be greater than 0';
  }
  if (value > 1) {
    return 'Value must be less than 1';
  }
};

import { Button } from '@mui/material';

export const Coefficients: FC = () => {
  const coefficients = useWatch({ name: 'custom_coefficient' });

  const availableFeatureTypes = useMemo(() => {
    const selectedTypes: number[] = coefficients?.length
      ? coefficients.map((coefItem: { type: number }) => coefItem.type)
      : [];

    return featureTypes.map((featureType) => {
      return {
        ...featureType,
        disabled: selectedTypes.includes(featureType.id),
      };
    });
  }, [coefficients]);

  return (
    <ArrayInput
      source="custom_coefficient"
      label=""
      sx={{ marginTop: '20px' }}
      defaultValue={[]}
    >
      <SimpleFormIterator
        addButton={<Button startIcon={<AddCircleOutlineIcon />}>Add</Button>}
        fullWidth={false}
        disableClear
        disableReordering
        inline
        sx={{
          [`& .RaSimpleFormIterator-line`]: {
            border: 'none',
          },
        }}
      >
        <SelectInput
          source="type"
          defaultValue={null}
          label="Feature"
          choices={availableFeatureTypes}
          sx={{ width: '250px', margin: 0 }}
          required
        />

        <NumberInput
          source="paid"
          label="Paid"
          required
          step={0.001}
          defaultValue={0.0}
          validate={validateMessageLimit}
        />
        <NumberInput
          source="free"
          label="Free"
          required
          step={0.001}
          defaultValue={0.0}
          validate={validateMessageLimit}
        />
      </SimpleFormIterator>
    </ArrayInput>
  );
};
