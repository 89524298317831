import React, { useEffect, useState } from 'react';
import { httpClient } from 'httpClient';
import { AxiosResponse } from 'axios';
import { Card, CardContent, Grid } from '@mui/material';
import { Title } from 'react-admin';
import styled from 'styled-components';

const Container = styled(Grid)`
  & h2 {
    margin: 60px 0 0;
  }
  &:first-child h2 {
    margin-top: 0;
  }
`;

const Caption = styled.h3`
  margin: 15px 0 5px;
`;

const EnumList = styled.div`
  line-height: 1.7;
  color: black;
  font-size: 14px;
  font-family: monospace;
  & span {
    opacity: 0.7;
  }
`;

type PhpEnumAll = {
  [key: string]: EnumModuleMap;
};
type EnumModuleMap = {
  [key: string]: PhpEnum;
};
type PhpEnum =
  | {
      [key: string]: number;
    }
  | Array<string>;

const PhpEnumList = () => {
  const [phpEnumAll, setPhpEnumAll] = useState<PhpEnumAll>({});

  useEffect(getList, []);

  function getList() {
    httpClient.get('/docs/enums').then(fillList);
  }

  function fillList(response: AxiosResponse) {
    setPhpEnumAll(response.data);
  }

  return (
    <Card>
      <Title title="Enums" />
      <CardContent>
        {Object.entries(phpEnumAll).map(([module, enumModuleMap]) => (
          <Container container spacing={1} key={module}>
            <Grid item xs={12}>
              <h2>{module}</h2>
            </Grid>
            {Object.entries(enumModuleMap).map(([enumName, phpEnum]) => (
              <Grid item xs={4} key={enumName}>
                <Caption>{enumName}</Caption>
                <EnumList>
                  {Array.isArray(phpEnum)
                    ? phpEnum.map((value) => (
                        <div key={value}>
                          {value}
                          <br />
                        </div>
                      ))
                    : Object.entries(phpEnum).map(([key, value]) => (
                        <div key={value}>
                          {value} <span>→</span> {key}
                          <br />
                        </div>
                      ))}
                </EnumList>
              </Grid>
            ))}
          </Container>
        ))}
      </CardContent>
    </Card>
  );
};
export default PhpEnumList;
