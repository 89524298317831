import {
  Chip,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { SiteIdChoices } from 'api/PidvalApi';
import styled from 'styled-components';

const Root = styled(FormControl)`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const StyledSelect = styled(Select)`
  background-color: #fff;
`;

const Chips = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

interface Props {
  selectedIds: number[] | null;
  siteIdChoices: SiteIdChoices[];
  onClickSelectOption: (event: any) => void;
}

export const SiteIdsSelect = ({
  selectedIds,
  siteIdChoices,
  onClickSelectOption,
}: Props) => {
  return (
    <Root>
      <InputLabel htmlFor="select-multiple-chip">Site ids</InputLabel>
      <StyledSelect
        multiple
        value={selectedIds}
        input={<Input id="select-multiple-chip" />}
        renderValue={(selected) => {
          if (Array.isArray(selected)) {
            const selectedMirrors = siteIdChoices.filter((item) =>
              selected?.includes(item.id),
            );

            return (
              <Chips>
                {selectedMirrors?.map((value) => (
                  <Chip key={value.id} label={value.name} />
                ))}
              </Chips>
            );
          }
        }}
        onChange={onClickSelectOption}
      >
        {siteIdChoices.map((siteId) => (
          <MenuItem key={siteId.id} value={siteId.id}>
            {siteId.name}
          </MenuItem>
        ))}
      </StyledSelect>
    </Root>
  );
};
