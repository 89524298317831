import { Chip, styled } from '@mui/material';
import { useRecordContext } from 'react-admin';
import { SupportTicketStatus } from 'types/enums/SupportTicket';

const AssignedTicketStatus = styled(Chip)`
  background: #c6c6c6;
`;

const NewTicketStatus = styled(Chip)`
  background: #4caf50;
`;

const StatusField = (props: any) => {
  const record = useRecordContext();

  if (!record) {
    return <></>;
  }

  if (record.status === SupportTicketStatus.Assigned)
    return <AssignedTicketStatus label="Assigned" />;

  return <NewTicketStatus label="New" />;
};

export default StatusField;
