import { FC } from 'react';
import {
  Datagrid,
  DateField,
  ImageField,
  List,
  NumberField,
  SelectField,
  SelectInput,
  TextField,
} from 'react-admin';
import { GiftType } from './field-choices';
import styled from 'styled-components';

const StyledImageField = styled(ImageField)`
  max-width: 50px;
  margin: 0;
  object-fit: contain;
`;

const GiftList: FC = () => {
  return (
    <List
      perPage={25}
      filters={[
        <SelectInput
          label={'Type'}
          source="type"
          choices={GiftType}
          alwaysOn
          variant="filled"
        />,
      ]}
    >
      <Datagrid optimized rowClick="edit">
        <TextField source="id" label="ID" sortable={false} />
        <SelectField source="type" choices={GiftType} sortable={false} />
        <StyledImageField source="url" sortable={false} />
        <NumberField source="credits" sortable={false} />
        <NumberField source="sort_order" sortable={false} />
        <DateField source="available_from" showTime={true} sortable={false} />
        <DateField source="available_to" showTime={true} sortable={false} />
      </Datagrid>
    </List>
  );
};

export default GiftList;
