import { useState } from 'react';

import { useLocation } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Https';

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CircularProgress,
  Container,
} from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';

import { useTranslate } from 'ra-core';
import { useLogin, useNotify, Notification } from 'react-admin';
import { lightTheme } from './themes';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: flex-start;
  background-image: url(https://source.unsplash.com/random/1600x900);
  background-repeat: no-repeat;
  background-size: cover;
`;

const RegistrationBlock = styled(Card)`
  min-width: 300px;
  margin-top: 6em;
`;

const AvatarWrapper = styled.div`
  margin: 1em;
  display: flex;
  justify-content: center;
`;

const FieldWrapper = styled.div`
  margin-top: 1em;
`;

const CardActionsBlock = styled(CardActions)`
  padding: 0 1em 1em 1em;
`;

const InputFields = styled.div`
  padding: 0 1em 1em 1em;
`;

interface FormValues {
  username?: string;
  password?: string;
}

const Login = () => {
  const [loading, setLoading] = useState(false);

  const translate = useTranslate();
  const notify = useNotify();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const login = useLogin();

  const onSubmit: SubmitHandler<FormValues> = (auth) => {
    setLoading(true);

    login(auth, location.state ? location.state.nextPathname : '/').catch(
      (error: Error) => {
        setLoading(false);
        notify(error.message ? error.message : 'ra.auth.sign_in_error', {
          type: 'warning',
        });
      },
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Main>
        <RegistrationBlock>
          <AvatarWrapper>
            <Avatar sx={{ backgroundColor: lightTheme.palette.secondary.main }}>
              <LockIcon color="inherit" />
            </Avatar>
          </AvatarWrapper>
          <InputFields>
            <FieldWrapper>
              <TextField
                id="username"
                label={translate('ra.auth.username')}
                type="text"
                variant="standard"
                fullWidth
                autoFocus
                disabled={loading}
                {...register('username', { required: true })}
              />
              {errors.username && <p>{translate('ra.validation.required')}</p>}
            </FieldWrapper>

            <FieldWrapper>
              <TextField
                id="password"
                label={translate('ra.auth.password')}
                type="password"
                variant="standard"
                fullWidth
                disabled={loading}
                {...register('password', { required: true })}
              />
              {errors.password && <p>{translate('ra.validation.required')}</p>}
            </FieldWrapper>
          </InputFields>
          <CardActionsBlock>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              fullWidth
            >
              {loading && <CircularProgress size={25} thickness={2} />}
              {translate('ra.auth.sign_in')}
            </Button>
          </CardActionsBlock>
        </RegistrationBlock>
        <Notification />
      </Main>
    </form>
  );
};

const LoginWithTheme = (props: any) => (
  <Container>
    <Login {...props} />
  </Container>
);

export default LoginWithTheme;
