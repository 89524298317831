import TextCategoryIcon from '@mui/icons-material/AccountTree';
import TextCategoryList from './TextCategoryList';
import TextCategoryEdit from './TextCategoryEdit';
import TextCategoryCreate from './TextCategoryCreate';

export default {
  list: TextCategoryList,
  edit: TextCategoryEdit,
  create: TextCategoryCreate,
  icon: TextCategoryIcon,
};
