import { PartnerConfigEmail } from 'model-types';

export const validateEmailConfigs = ({
  configEmails,
  errors,
}: {
  configEmails: PartnerConfigEmail[];
  errors: any;
}) => {
  if (!!configEmails.length) {
    const uniqueDomains: string[] = [];
    const partnerConfigEmailsError: any = [];

    let hasDefaultEmailConfig: boolean = false;

    for (let j in configEmails) {
      let configEmail = configEmails[j];

      const emailConfigError: any = {};

      if (!configEmail) {
        continue;
      }

      if (null === configEmail.domain) {
        hasDefaultEmailConfig = true;
      }

      let uniqueDomain = configEmail.domain;

      if (uniqueDomains.indexOf(uniqueDomain) > -1) {
        emailConfigError.domain =
          'Email config with this domain is already configured';
      } else {
        uniqueDomains.push(uniqueDomain);
      }

      let enabledPercentage = configEmail.enabled_percentage || null;

      if (
        enabledPercentage &&
        (enabledPercentage > 100 || enabledPercentage < 0)
      ) {
        emailConfigError.enabled_percentage = 'Not valid field value';
      }

      partnerConfigEmailsError.push(emailConfigError);
    }

    errors.partner_config_emails = [...partnerConfigEmailsError];

    if (!hasDefaultEmailConfig && configEmails[0]) {
      const restErrors = [...partnerConfigEmailsError].filter(
        (_, index) => index !== 0,
      );

      errors.partner_config_emails = [
        {
          domain: 'Need default email domain',
        },
        ...restErrors,
      ];
    }
  }
};
