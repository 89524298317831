import { ChangeEvent, FC, useEffect, useState } from 'react';
import {
  Edit,
  BooleanField,
  useRefresh,
  useRecordContext,
  FunctionField,
  useNotify,
} from 'react-admin';

import { Link } from 'react-router-dom';

import { httpClient } from 'httpClient';
import { formatISOToUtcDate, getUTCDateDifferenceInMinutes } from 'helper/date';

import ModerationStatusBadge from 'layout/ModerationStatusBadge';
import ErrorButton from 'layout/buttons/ErrorButton';
import SuccessButton from 'layout/buttons/SuccessButton';
import GreyButton from 'layout/buttons/GreyButton';
import ToggleActivateButton from 'layout/buttons/ToggleActivateButton';
import CustomSelect from 'layout/input/CustomSelect';
import BooleanFromValueField from 'layout/fields/BooleanFromValueField';

import { gender, logoutTimes } from '../field-choices';

import { PageViews } from './submodules/pageViews';
import { CreditTransactions } from './submodules/creditTransactions';
import { ChangeAge } from './submodules/changeAge';

import {
  Box,
  FormControlLabel,
  Grid,
  Input,
  Switch,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import {
  Close,
  Delete,
  Done,
  RemoveCircle,
  ThumbUp,
  Edit as EditIcon,
} from '@mui/icons-material';
import { ProfileType } from 'types/enums/ProfileType';
import InfoBtn from 'layout/buttons/InfoBtn';

const BooleanInput = styled(BooleanField)`
  margin: '5px 0 0';
`;

const UserAvatar = styled.img`
  width: 100%;
  height: 100%;
  max-height: 35vh;
  margin-bottom: 10px;
  object-fit: contain;
  object-position: center right;
`;

const TableBody = styled.tbody`
  width: 100%;
`;

const StyledTextareaAutosize = styled(TextareaAutosize)`
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  resize: none;
  outline: none;
  font-size: 15px;
`;

const ADD_CREDIT_DEFAULT = {
  amount: '0',
  user_id: 0,
  credits_type: 0,
};

interface AddCredits {
  amount: string;
  user_id: number;
  credits_type: number;
}
const profileTypeChoices = [
  { id: ProfileType.User, label: 'user', value: ProfileType.User },
  { id: ProfileType.Rutn, label: 'rutn', value: ProfileType.Rutn },
];

interface TransactionsInfo {
  spend_segment: number;
  last_transaction: string;
  last_transaction_at: string;
  last_transaction_decline_reason: string;
}
interface PaymentTokenInfo {
  id: number;
  status: 'DECLINED' | 'ACTIVE';
  bin: string;
  has_charge_back: string;
  loading: boolean;
}

const UserTitle: FC = () => {
  const record = useRecordContext();

  return record ? <span>Regular user #{record.id}</span> : null;
};

const EditForm: FC = () => {
  const record = useRecordContext();

  const refresh = useRefresh();
  const notify = useNotify();

  const [isPasswordEdit, setIsPasswordEdit] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');

  const [note, setNote] = useState<string>('');
  const [isNoteEdit, setIsNoteEdit] = useState<boolean>(false);

  const [isAddCredit, setIsAddCredit] = useState<boolean>(false);
  const [addCredit, setAddCredit] = useState<AddCredits>(ADD_CREDIT_DEFAULT);

  const [toggleTestStatusLoader, setToggleTestStatusLoader] = useState(false);

  const [userGender, setUserGender] = useState(record?.gender);
  const [userType, setUserType] = useState(record?.profile_type);
  const [selectedLogoutTime, setSelectedLogoutTime] = useState(
    logoutTimes[0].value,
  );

  const [transactionInfo, setTransactionInfo] =
    useState<TransactionsInfo | null>(null);
  const [paymentTokenInfo, setPaymentTokenInfo] = useState<{
    tokens: PaymentTokenInfo[];
    hasChargeBack: boolean;
  }>({ tokens: [], hasChargeBack: false });

  // TODO migrate to query
  const ban = () => {
    if (record) {
      httpClient.get('/users/' + record.id + '/ban').then(() => refresh());
    }
  };

  const approve = () => {
    if (record) {
      httpClient.get('/users/' + record.id + '/approve').then(() => refresh());
    }
  };

  const remove = () => {
    if (record && confirm('Delete user #' + record.id + ' ?')) {
      httpClient.delete('/users/' + record.id).then(() => refresh());
    }
  };

  const changePassword = () => {
    if (record) {
      httpClient
        .post('/users/' + record.id + '/change-password', {
          password: password,
        })
        .then(() => {
          setIsPasswordEdit(false);
          refresh();
        });
    }
  };

  const changeGender = () => {
    if (record) {
      httpClient
        .put(`/users/${record.id}`, {
          gender: Number(userGender),
        })
        .then(() => {
          refresh();
        })
        .catch((error) => {
          alert(error.message);
        });
    }
  };

  const changeUserType = () => {
    if (record) {
      httpClient
        .put(`/users/${record.id}`, {
          profile_type: Number(userType),
        })
        .then(() => {
          refresh();
        })
        .catch((error) => {
          alert(error.message);
        });
    }
  };

  const changeUserTestStatus = () => {
    if (record) {
      setToggleTestStatusLoader(true);

      const userType = record.trusted_user_id ? 'trusted-user' : 'users';
      const userId = record.trusted_user_id || record.id;

      httpClient
        .put(`/${userType}/${userId}/test`, {
          is_test: !record.is_test,
        })
        .then(() => {
          setToggleTestStatusLoader(false);
          refresh();
        });
    }
  };

  const toggleShowCreditAdd = () => {
    setIsAddCredit((prev) => !prev);
  };

  const handleCreditAmount = (e: any) => {
    setAddCredit((prev) => {
      return { ...prev, amount: e.target.value };
    });
  };

  const handleCreditType = (e: any) => {
    setAddCredit((prev) => {
      return { ...prev, credits_type: e.target.checked ? 1 : 0 };
    });
  };

  const saveNote = () => {
    httpClient
      .post('/users/note', {
        user_id: record.id,
        text: note,
      })
      .then(() => {
        notify('Note saved');

        refresh();
      })
      .catch(() => {
        notify('Oops, something went wrong! Try again later');
      });

    setIsNoteEdit(false);
  };

  const cancelNoteEdit = () => {
    setNote(record.note || '');
    setIsNoteEdit(false);
  };

  const handleNoteChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value);
  };

  const startEdit = () => {
    setIsNoteEdit(true);
  };

  const handleAddCredit = () => {
    httpClient
      .post('/payment/add-credits', {
        ...addCredit,
        amount: Number(addCredit.amount) || 0,
      })
      .then(() => {
        toggleShowCreditAdd();
        refresh();
      });
  };

  const handleLogout = () => {
    if (
      confirm(`Log out user # ${record.id} on ${selectedLogoutTime} minutes?`)
    ) {
      httpClient
        .put(`/users/${record.id}/blocked`, {
          blocked_for: selectedLogoutTime,
        })
        .then(() => refresh());
    }
  };

  const handleTokenActivate = async (tokenId: number) => {
    setPaymentTokenInfo((prevInfo) => ({
      ...prevInfo,
      tokens: prevInfo.tokens.map((tokenItem) => {
        if (tokenItem.id === tokenId) return { ...tokenItem, loading: true };

        return tokenItem;
      }),
    }));

    const { status } = await httpClient.get(
      `/payment/activate-token/${tokenId}`,
    );

    if (status) {
      notify('The token has been activated!');

      setPaymentTokenInfo((prevInfo) => ({
        ...prevInfo,
        tokens: prevInfo.tokens.filter((tokenItem) => tokenItem.id !== tokenId),
      }));
    }
  };

  useEffect(() => {
    if (record?.note) {
      setNote(record.note);
    }
  }, [record?.note]);

  useEffect(() => {
    setAddCredit((prev) => {
      return { ...prev, user_id: Number(record.id) };
    });
  }, []);

  useEffect(() => {
    if (!record.id) return;

    httpClient
      .get<TransactionsInfo>(`/users/${record.id}/transaction-info`)
      .then((response) => {
        setTransactionInfo(response.data);
      });

    httpClient
      .get<{ data: PaymentTokenInfo[] }>(
        `/payment/get-declined-token-list/${record.id}`,
      )
      .then((response) => {
        const tokens = response.data.data;
        const hasChargeBack = !!tokens.find((token) => token.has_charge_back);

        setPaymentTokenInfo({
          tokens,
          hasChargeBack,
        });
      });
  }, [record.id]);

  return (
    <Grid container spacing={3} style={{ padding: '20px' }}>
      <Grid item xs={7} overflow="auto">
        <table cellPadding={7}>
          <TableBody>
            <tr>
              <td>id</td>
              <td>#{record.id}</td>
            </tr>
            <tr>
              <td>ulid</td>
              <td>{record.ulid_id}</td>
            </tr>

            {record.trusted_user_external_id && (
              <tr>
                <td>Carthage TU id</td>
                <td style={{ color: 'rgb(59,130,246)' }}>
                  #{record.trusted_user_external_id}
                </td>
              </tr>
            )}
            {record.trusted_user_id && (
              <tr>
                <td>Trusted user</td>
                <td>
                  <GreyButton
                    component={Link}
                    size="small"
                    to={{ pathname: '/trusted-user/' + record.trusted_user_id }}
                  >
                    #{record.trusted_user_id}
                  </GreyButton>
                </td>
              </tr>
            )}

            {record.related_user_ids?.length > 0 && (
              <tr>
                <td>Related users</td>
                <td>
                  {record.related_user_ids.map((user_id: number) => (
                    <GreyButton
                      key={user_id}
                      component={Link}
                      size="small"
                      style={{ marginRight: '10px' }}
                      to={{ pathname: '/users/' + user_id }}
                    >
                      #{user_id}
                    </GreyButton>
                  ))}
                </td>
              </tr>
            )}

            <tr>
              <td>email</td>
              <td>{record.email}</td>
            </tr>
            <tr>
              <td>site_id</td>
              <td>{record.site_id}</td>
            </tr>
            <tr>
              <td>name</td>
              <td>{record.name}</td>
            </tr>
            <tr>
              <td>age</td>
              <td style={{ display: 'flex', alignItems: 'center' }}>
                <ChangeAge userAge={record.age} userId={Number(record.id)} />
              </td>
            </tr>
            <tr>
              <td>password</td>
              <td>
                {!isPasswordEdit ? (
                  <>
                    ********
                    <GreyButton
                      id="change-password"
                      size="small"
                      style={{ marginLeft: '10px' }}
                      endIcon={<EditIcon />}
                      onClick={() => setIsPasswordEdit(true)}
                    >
                      Change
                    </GreyButton>
                  </>
                ) : (
                  <>
                    <input
                      type="text"
                      value={password}
                      onInput={(e) => setPassword(e.currentTarget.value)}
                    />
                    <SuccessButton
                      size="small"
                      endIcon={<Done />}
                      onClick={changePassword}
                    >
                      Change
                    </SuccessButton>
                    <GreyButton
                      size="small"
                      endIcon={<Close />}
                      onClick={() => setIsPasswordEdit(false)}
                    >
                      Cancel
                    </GreyButton>
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td>created_at</td>
              <td>{formatISOToUtcDate(record.created_at)}</td>
            </tr>
            <tr>
              <td>city</td>
              <td>{record.city}</td>
            </tr>
            <tr>
              <td>country</td>
              <td>{record.country}</td>
            </tr>
            <tr>
              <td>photo_count</td>
              <td>{record.photo_count}</td>
            </tr>

            <tr>
              <td>gender</td>
              <Box component="td" display="flex" alignItems="center">
                <CustomSelect
                  initialValue={userGender}
                  choices={gender}
                  onChange={setUserGender}
                />
                <SuccessButton size="small" onClick={changeGender}>
                  Save
                </SuccessButton>
              </Box>
            </tr>
            <tr>
              <td>profile_type</td>

              {Number(userType) === ProfileType.TuProfile && <td>tu </td>}

              {!!userType &&
                [ProfileType.User, ProfileType.Rutn].includes(
                  Number(userType),
                ) && (
                  <Box component="td" display="flex" alignItems="center">
                    <CustomSelect
                      initialValue={userType}
                      choices={profileTypeChoices}
                      onChange={setUserType}
                    />
                    <SuccessButton size="small" onClick={changeUserType}>
                      Save
                    </SuccessButton>
                  </Box>
                )}
            </tr>

            <tr>
              <td>moderation_status</td>
              <td>
                <ModerationStatusBadge status={record.moderation_status} />
                <SuccessButton
                  size="small"
                  endIcon={<ThumbUp />}
                  onClick={approve}
                  disabled={record.moderation_status === 'ok'}
                >
                  Approve
                </SuccessButton>
                <ErrorButton
                  size="small"
                  endIcon={<RemoveCircle />}
                  onClick={ban}
                  disabled={record.moderation_status === 'ban'}
                >
                  Ban
                </ErrorButton>
              </td>
            </tr>
            <tr>
              <td>is_deleted</td>
              <td>
                <BooleanInput record={record} source="is_deleted" />
                <ErrorButton
                  size="small"
                  endIcon={<Delete />}
                  onClick={remove}
                  disabled={record.is_deleted}
                >
                  Delete
                </ErrorButton>
              </td>
            </tr>
            <tr>
              <td>is_test</td>
              <td>
                <BooleanFromValueField source="is_test" />
                <ToggleActivateButton
                  isActivate={!record.is_test}
                  handleActivate={changeUserTestStatus}
                  handleDeactivate={changeUserTestStatus}
                  isLoader={toggleTestStatusLoader}
                />
              </td>
            </tr>

            <tr>
              <td>logout user on</td>
              <td style={{ display: 'flex', alignItems: 'center' }}>
                <CustomSelect
                  choices={logoutTimes}
                  onChange={setSelectedLogoutTime}
                />
                <ErrorButton size="small" onClick={handleLogout}>
                  Log out
                </ErrorButton>
              </td>
              {record.blocked_for_at &&
                getUTCDateDifferenceInMinutes(record.blocked_for_at) > 0 && (
                  <td>
                    User blocked at{' '}
                    {getUTCDateDifferenceInMinutes(record.blocked_for_at)}{' '}
                    minutes
                  </td>
                )}
            </tr>

            {!!paymentTokenInfo.tokens.length && (
              <tr>
                <td>tokens</td>
                <td>
                  <Box display="flex" flexWrap="wrap">
                    {paymentTokenInfo.tokens.map((tokenData) => (
                      <Box
                        key={tokenData.id}
                        display="flex"
                        alignItems="center"
                        marginBottom="3px"
                      >
                        bin: {tokenData.bin}
                        {!tokenData.has_charge_back && (
                          <SuccessButton
                            size="small"
                            style={{ marginLeft: '10px' }}
                            onClick={() => handleTokenActivate(tokenData.id)}
                            disabled={
                              tokenData.loading ||
                              paymentTokenInfo.hasChargeBack
                            }
                          >
                            Activate
                          </SuccessButton>
                        )}
                        {tokenData.has_charge_back && (
                          <ErrorButton size="small" disabled>
                            CB Inactive
                          </ErrorButton>
                        )}
                      </Box>
                    ))}
                  </Box>
                </td>
              </tr>
            )}

            <tr>
              <td>spend_segment</td>
              <td>
                <FunctionField
                  sx={{ textTransform: 'capitalize' }}
                  render={() => transactionInfo?.spend_segment || '-'}
                />
              </td>
            </tr>
            <tr>
              <td>last_transaction</td>
              <td>
                <FunctionField
                  render={() => transactionInfo?.last_transaction || '-'}
                />
              </td>
            </tr>
            <tr>
              <td>last_transaction_at</td>
              <td>
                <FunctionField
                  render={() =>
                    formatISOToUtcDate(transactionInfo?.last_transaction_at) ||
                    '-'
                  }
                />
              </td>
            </tr>
            <tr>
              <td>last_transaction_decline_reason</td>
              <td>
                <FunctionField
                  render={() =>
                    transactionInfo?.last_transaction_decline_reason || '-'
                  }
                />
              </td>
            </tr>

            <tr>
              <td>balance</td>
              <td>
                {!isAddCredit ? (
                  <>
                    {record.credits}
                    <SuccessButton
                      size="small"
                      style={{ marginLeft: '10px' }}
                      endIcon={<EditIcon />}
                      onClick={toggleShowCreditAdd}
                    >
                      Add credit
                    </SuccessButton>
                  </>
                ) : (
                  <>
                    <Input
                      type="number"
                      value={addCredit.amount}
                      onInput={handleCreditAmount}
                    />
                    <FormControlLabel
                      control={
                        <Switch color="primary" onChange={handleCreditType} />
                      }
                      labelPlacement="top"
                      label="Is free"
                    />

                    <SuccessButton
                      size="small"
                      endIcon={<Done />}
                      onClick={handleAddCredit}
                    >
                      Add
                    </SuccessButton>
                    <GreyButton
                      size="small"
                      endIcon={<Close />}
                      onClick={toggleShowCreditAdd}
                    >
                      Cancel
                    </GreyButton>
                  </>
                )}
              </td>
            </tr>
          </TableBody>
        </table>
      </Grid>

      <Grid item xs={5}>
        {!!record.thumbnail && <UserAvatar src={record.thumbnail} />}

        <Box display="flex" flexDirection="column">
          <Typography fontWeight="600" marginBottom="10px">
            Notes
          </Typography>

          {isNoteEdit && (
            <StyledTextareaAutosize
              value={note}
              onChange={handleNoteChange}
              minRows={10}
              maxRows={17}
            />
          )}

          {!isNoteEdit && (
            <Typography
              minHeight="202px"
              borderLeft="1px solid black"
              padding="10px"
              fontSize="16px"
              whiteSpace="pre-line"
              lineHeight="1.1"
              maxHeight="328px"
              overflow="auto"
            >
              {note}
            </Typography>
          )}

          {!isNoteEdit && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              mt="20px"
            >
              <InfoBtn size="small" endIcon={<EditIcon />} onClick={startEdit}>
                Edit
              </InfoBtn>
            </Box>
          )}
          {isNoteEdit && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              mt="20px"
            >
              <GreyButton
                size="small"
                endIcon={<Close />}
                onClick={cancelNoteEdit}
              >
                Discard
              </GreyButton>
              <SuccessButton size="small" endIcon={<Done />} onClick={saveNote}>
                Save
              </SuccessButton>
            </Box>
          )}
        </Box>
      </Grid>

      <Grid item xs={12}>
        <PageViews userId={Number(record.id)} />
      </Grid>

      <Grid item xs={12}>
        <CreditTransactions userId={Number(record.id)} />
      </Grid>
    </Grid>
  );
};

const RegularUserEdit: FC = () => {
  return (
    <Edit title={<UserTitle />}>
      <EditForm />
    </Edit>
  );
};

export default RegularUserEdit;
