import { sanitizeFieldRestProps, useRecordContext } from 'react-admin';
import { Link } from 'react-router-dom';
import { SupportTicket } from 'types/SupportTicket';

const LinkField = (props: any) => {
  const record = useRecordContext<SupportTicket>();

  if (!record.user?.id) return null;

  return (
    <Link
      to={`/users/${record.user.id}`}
      variant="body2"
      target="_blank"
      {...sanitizeFieldRestProps(props)}
    >
      {record.user.id}
    </Link>
  );
};

export default LinkField;
