import { FC, useCallback, useEffect, useState } from 'react';
import { Edit, FormTab, TabbedForm, useRecordContext } from 'react-admin';

import { httpClient } from 'httpClient';
import styled, { css } from 'styled-components';
import { Card, CircularProgress } from '@mui/material';

import {
  TrustedUserMedia,
  TrustedUserModerationData,
} from 'types/trustedUser/trustedUserModeration';

import { ShortInfoBar } from 'component/trustedUser/ShortInfoBar';
import { DocumentSection } from 'component/trustedUser/DocumentSection';
import { OtherDetailsSection } from 'component/trustedUser/OtherDetailsSection';

import { MediaList } from './submodules/MediaList';
import CustomToolbar from './submodules/CustomToolbar';

import { DEFAULT_MODERATION_RESPONSE } from './constants';

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  height: 90vh;
  background-color: #fff;
  box-shadow: none;
  border: none;
`;

const StyledCard = styled(Card)`
  width: 100%;
  height: 100%;
  border-radius: 0;
  border-left: 1px solid #eee;
  box-shadow: none;
`;

const StyledTabbedForm = styled(TabbedForm)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  box-shadow: none;
`;

const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin: 150px 0;
`;

const Tab = styled(FormTab)<{ $declined?: boolean; $changed?: boolean }>`
  min-width: 16.66%;
  width: 16.66%;
  font-weight: ${({ $declined }) => ($declined ? 'bold' : 'inherit')};
  color: ${({ $declined }) => ($declined ? '#d32f2f' : 'inherit')};

  ${({ $changed }) =>
    $changed &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: 15px;
        right: 5px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: #d32f2f;
      }
    `}
`;

const applyMediaDraft = (
  media: TrustedUserMedia[],
  draftMedia: TrustedUserMedia[],
) => {
  if (!draftMedia?.length)
    return {
      data: media,
      isChanged: false,
    };

  return {
    isChanged: media.length !== draftMedia.length,
    data: media.map((mediaItem) => {
      const draftItem = draftMedia.find((draft) => draft.id === mediaItem.id);

      if (draftItem) return { ...mediaItem, ...draftItem };

      return mediaItem;
    }),
  };
};

const EditForm: FC = () => {
  const record = useRecordContext();

  const [isDraftSaving, setIsDraftSaving] = useState(false);

  const [moderationData, setModerationData] =
    useState<TrustedUserModerationData>(DEFAULT_MODERATION_RESPONSE);

  const setPublicPhotos = useCallback(
    (setStateAction: (prevMedia: TrustedUserMedia[]) => TrustedUserMedia[]) => {
      setModerationData((prevModerationData) => ({
        ...prevModerationData,
        public_photos: setStateAction(prevModerationData.public_photos),
        statuses: {
          ...prevModerationData.statuses,
          is_public_photos_changed: false,
        },
      }));
    },
    [],
  );

  const setPrivatePhotos = useCallback(
    (setStateAction: (prevMedia: TrustedUserMedia[]) => TrustedUserMedia[]) => {
      setModerationData((prevModerationData) => ({
        ...prevModerationData,
        private_photos: setStateAction(prevModerationData.private_photos),
        statuses: {
          ...prevModerationData.statuses,
          is_private_photos_changed: false,
        },
      }));
    },
    [],
  );

  const setPrivateLibrary = useCallback(
    (setStateAction: (prevMedia: TrustedUserMedia[]) => TrustedUserMedia[]) => {
      setModerationData((prevModerationData) => ({
        ...prevModerationData,
        private_library: setStateAction(prevModerationData.private_library),
        statuses: {
          ...prevModerationData.statuses,
          is_private_library_changed: false,
        },
      }));
    },
    [],
  );

  const setVideos = useCallback(
    (setStateAction: (prevMedia: TrustedUserMedia[]) => TrustedUserMedia[]) => {
      setModerationData((prevModerationData) => ({
        ...prevModerationData,
        videos: setStateAction(prevModerationData.videos),
        statuses: {
          ...prevModerationData.statuses,
          is_videos_changed: false,
        },
      }));
    },
    [],
  );

  const loadModerationDraft = useCallback(async (userId: number) => {
    const data = await httpClient.get<TrustedUserModerationData>(
      `/trusted-user/moderation/${userId}/draft`,
    );

    return { draft: data.data };
  }, []);

  const saveDraft = useCallback(async () => {
    if (!record?.id || !moderationData?.retrieved_at) return;

    setIsDraftSaving(true);

    try {
      await httpClient.post(
        `/trusted-user/moderation/${record.id}/draft`,
        moderationData,
      );
    } finally {
      setIsDraftSaving(false);
    }
  }, [record?.id, moderationData]);

  useEffect(() => {
    if (
      !record?.id ||
      !record?.public_photos ||
      !record?.private_photos ||
      !record?.private_library
    ) {
      return;
    }

    const retrieved_at = Math.floor(new Date().getTime() / 1000);

    loadModerationDraft(Number(record?.id))
      .then(({ draft }) => {
        setModerationData((initData) => {
          const documentDeclineReasonDraft =
            draft.document_decline_reason || initData.document_decline_reason;

          const otherDetailDeclineReasonDraft =
            draft.document_decline_reason || initData.document_decline_reason;

          const publicPhotosDraft = applyMediaDraft(
            record.public_photos,
            draft.public_photos,
          );

          const privatePhotosDraft = applyMediaDraft(
            record.private_photos,
            draft.private_photos,
          );

          const privateLibraryDraft = applyMediaDraft(
            record.private_library,
            draft.private_library,
          );

          const videosDraft = applyMediaDraft(record.videos, draft.videos);

          const isDocumentsChanged =
            JSON.stringify({ ...record.documents, avatar: record.avatar }) !==
            JSON.stringify(draft.documents || {});

          const isOtherDetailsChanged =
            JSON.stringify(record.other_details) !==
            JSON.stringify(draft.other_details || {});

          return {
            document_decline_reason: documentDeclineReasonDraft,
            document_status: !documentDeclineReasonDraft,
            documents: { ...record.documents, avatar: record.avatar },

            other_detail_decline_reason: otherDetailDeclineReasonDraft,
            other_detail_status: !otherDetailDeclineReasonDraft,
            other_details: record.other_details,

            public_photos: publicPhotosDraft.data,
            private_photos: privatePhotosDraft.data,
            private_library: privateLibraryDraft.data,
            videos: videosDraft.data,

            statuses: {
              is_documents_changed:
                isDocumentsChanged && !!initData.retrieved_at,
              is_other_details_changed:
                isOtherDetailsChanged && !!initData.retrieved_at,

              is_private_library_changed:
                privateLibraryDraft.isChanged && !!initData.retrieved_at,
              is_private_photos_changed:
                privatePhotosDraft.isChanged && !!initData.retrieved_at,
              is_public_photos_changed:
                publicPhotosDraft.isChanged && !!initData.retrieved_at,
              is_videos_changed:
                videosDraft.isChanged && !!initData.retrieved_at,
            },

            retrieved_at,
          };
        });
      })
      .catch(() => {
        setModerationData((initData) => ({
          ...initData,
          public_photos: record.public_photos,
          private_photos: record.private_photos,
          private_library: record.private_library,
          videos: record.videos,
          retrieved_at,
        }));
      });
  }, [loadModerationDraft, record]);

  // ? save draft with debounce
  useEffect(() => {
    const handler = setTimeout(saveDraft, 7000);

    return () => clearTimeout(handler);
  }, [saveDraft]);

  if (!record || !moderationData.retrieved_at) {
    return (
      <Loader>
        <CircularProgress color="secondary" size="30px" />
      </Loader>
    );
  }

  return (
    <Root>
      <ShortInfoBar isModeration />

      <StyledCard>
        <StyledTabbedForm
          toolbar={
            <CustomToolbar
              saveDraft={saveDraft}
              isDraftSaving={isDraftSaving}
              moderationData={moderationData}
              setModerationData={setModerationData}
            />
          }
        >
          <Tab
            label="Documents"
            $declined={!!moderationData.document_decline_reason}
            $changed={moderationData.statuses.is_documents_changed}
          >
            <DocumentSection
              declineReason={
                moderationData.document_decline_reason ||
                record.documents?.decline_reason
              }
              blockReason={record?.blocked_reason}
              deactivateReason={record?.deactivate_reason}
            />
          </Tab>

          <Tab
            label="Other Details"
            $declined={!!moderationData.other_detail_decline_reason}
            $changed={moderationData.statuses.is_other_details_changed}
          >
            <OtherDetailsSection
              declineReason={
                moderationData.other_detail_decline_reason ||
                record.other_details?.decline_reason
              }
            />
          </Tab>

          <Tab
            label="Public Photos"
            $changed={moderationData.statuses.is_public_photos_changed}
          >
            <MediaList
              media={moderationData.public_photos}
              setMedia={setPublicPhotos}
              tabNumber={2}
            />
          </Tab>

          <Tab
            label="Private Photos"
            $changed={moderationData.statuses.is_private_photos_changed}
          >
            <MediaList
              media={moderationData.private_photos}
              setMedia={setPrivatePhotos}
              tabNumber={3}
            />
          </Tab>

          <Tab
            label="Private library"
            $changed={moderationData.statuses.is_private_library_changed}
          >
            <MediaList
              media={moderationData.private_library}
              setMedia={setPrivateLibrary}
              tabNumber={4}
            />
          </Tab>

          <Tab
            label="Videos"
            $changed={moderationData.statuses.is_videos_changed}
          >
            <MediaList
              media={moderationData.videos}
              setMedia={setVideos}
              tabNumber={5}
            />
          </Tab>
        </StyledTabbedForm>
      </StyledCard>
    </Root>
  );
};

const UserTitle: FC = () => {
  const record = useRecordContext();

  return record ? <span>Trusted user #{record.id}</span> : null;
};

const TrustedUserModeration = () => {
  return (
    <Edit title={<UserTitle />}>
      <EditForm />
    </Edit>
  );
};

export default TrustedUserModeration;
