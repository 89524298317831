import { FC } from 'react';
import { Grid } from '@mui/material';
import { TextInput } from 'react-admin';

export const FacebookConfig: FC = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={3}>
        <TextInput
          source="pixel_id"
          sx={{ width: '100%' }}
          required
          variant="filled"
        />
      </Grid>
      <Grid item xs={9}>
        <TextInput
          source="access_token"
          sx={{ width: '100%' }}
          variant="filled"
          required
        />
      </Grid>
    </Grid>
  );
};
