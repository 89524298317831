import { FC, memo } from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import 'react-medium-image-zoom/dist/styles.css';
import { IconButton } from '@mui/material';
import styled from 'styled-components';

const FULL_PAGINATION_LENGTH = 5;

const ButtonList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  padding: 20px 0;
`;

const StyledButton = styled.button`
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  background-color: transparent;

  &:hover {
    cursor: pointer;
    background-color: #eee;
  }
`;

interface Props {
  pages: number[];
  activePage: number;
  onChangeActivePage: (page: number) => void;
}

const CustomPagination: FC<Props> = memo(
  ({ pages, activePage, onChangeActivePage }) => {
    const handlePageChange = (event: any) => {
      const nextTargetPage = event.target.innerText;

      if (activePage === Number(nextTargetPage)) {
        return;
      }

      onChangeActivePage(Number(nextTargetPage));
    };

    const handlePrevPage = () => {
      onChangeActivePage(activePage - 1);
    };

    const handleNextPage = () => {
      onChangeActivePage(activePage + 1);
    };

    return (
      <ButtonList>
        <IconButton
          aria-label="back"
          disabled={activePage === 1}
          onClick={handlePrevPage}
        >
          <ArrowBackIosNewIcon fontSize="inherit" />
        </IconButton>
        {pages.length <= FULL_PAGINATION_LENGTH &&
          pages.map((item) => (
            <StyledButton
              key={item}
              type="button"
              style={{
                color: `${
                  item === activePage
                    ? 'rgb(59, 130, 246)'
                    : 'rgba(0, 0, 0, 0.87)'
                }`,
              }}
              onClick={handlePageChange}
            >
              {item}
            </StyledButton>
          ))}

        {pages.length > FULL_PAGINATION_LENGTH && (
          <>
            <StyledButton
              type="button"
              style={{
                color: `${
                  activePage === pages[0]
                    ? 'rgb(59, 130, 246)'
                    : 'rgba(0, 0, 0, 0.87)'
                }`,
              }}
              onClick={handlePageChange}
            >
              {pages[0]}
            </StyledButton>
            {(activePage === pages[0] || activePage === pages[1]) && (
              <>
                <StyledButton
                  type="button"
                  style={{
                    color: `${
                      activePage === pages[1]
                        ? 'rgb(59, 130, 246)'
                        : 'rgba(0, 0, 0, 0.87)'
                    }`,
                  }}
                  onClick={handlePageChange}
                >
                  {pages[1]}
                </StyledButton>
                <StyledButton
                  type="button"
                  style={{
                    color: `${
                      activePage === pages[2]
                        ? 'rgb(59, 130, 246)'
                        : 'rgba(0, 0, 0, 0.87)'
                    }`,
                  }}
                  onClick={handlePageChange}
                >
                  {pages[2]}
                </StyledButton>
                ...
              </>
            )}

            {activePage !== pages[0] &&
              activePage !== pages[1] &&
              activePage !== pages.length &&
              activePage !== pages.length - 1 && (
                <>
                  {activePage !== pages[2] && '...'}
                  <StyledButton
                    type="button"
                    style={{
                      color: 'rgba(0, 0, 0, 0.87)',
                    }}
                    onClick={handlePageChange}
                  >
                    {activePage - 1}
                  </StyledButton>
                  <StyledButton
                    type="button"
                    style={{
                      color: 'rgb(59, 130, 246)',
                    }}
                    onClick={handlePageChange}
                  >
                    {activePage}
                  </StyledButton>
                  <StyledButton
                    type="button"
                    style={{
                      color: 'rgba(0, 0, 0, 0.87)',
                    }}
                    onClick={handlePageChange}
                  >
                    {activePage + 1}
                  </StyledButton>
                  {activePage !== pages.length - 2 && '...'}
                </>
              )}

            {(activePage === pages.length - 1 ||
              activePage === pages.length) && (
              <>
                ...
                <StyledButton
                  type="button"
                  style={{
                    color: `${
                      activePage === pages.length - 2
                        ? 'rgb(59, 130, 246)'
                        : 'rgba(0, 0, 0, 0.87)'
                    }`,
                  }}
                  onClick={handlePageChange}
                >
                  {pages.length - 2}
                </StyledButton>
                <StyledButton
                  type="button"
                  style={{
                    color: `${
                      activePage === pages.length - 1
                        ? 'rgb(59, 130, 246)'
                        : 'rgba(0, 0, 0, 0.87)'
                    }`,
                  }}
                  onClick={handlePageChange}
                >
                  {pages.length - 1}
                </StyledButton>
              </>
            )}

            <StyledButton
              type="button"
              style={{
                color: `${
                  activePage === pages.length
                    ? 'rgb(59, 130, 246)'
                    : 'rgba(0, 0, 0, 0.87)'
                }`,
              }}
              onClick={handlePageChange}
            >
              {pages.length}
            </StyledButton>
          </>
        )}

        <IconButton
          aria-label="next"
          disabled={activePage === pages.length}
          onClick={handleNextPage}
        >
          <ArrowForwardIosIcon fontSize="inherit" />
        </IconButton>
      </ButtonList>
    );
  },
);

export default CustomPagination;
