import { Grid } from '@mui/material';

const ActivityQueueTable = ({ items }: { items: any }) => {
  return (
    <>
      {items.map((queueItem: any) => {
        return (
          <Grid container spacing={0} key={queueItem.id}>
            <Grid item xs={2}>
              ID: {queueItem.id}
            </Grid>

            <Grid item xs={2}>
              Type: {queueItem.type}
            </Grid>

            <Grid item xs={3}>
              Action: {queueItem.action}
            </Grid>

            <Grid item xs={5}>
              Run at: {queueItem.run_at}
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

export default ActivityQueueTable;
