import RedeemIcon from '@mui/icons-material/Redeem';
import GiftList from './GiftList';
import GiftCreate from './GiftCreate';
import GiftEdit from './GiftEdit';

export default {
  list: GiftList,
  create: GiftCreate,
  edit: GiftEdit,
  icon: RedeemIcon,
};
