import {
  Datagrid,
  List,
  TextField,
  NumberField,
  FunctionField,
  DateField,
  NumberInput,
  SelectInput,
  ImageField,
  SimpleList,
  ArrayField,
  useRecordContext,
  TopToolbar,
} from 'react-admin';
import styled, { css } from 'styled-components';

import { ModerationBtns } from 'component/common/ModerationBtns';
import CustomPagination from 'layout/CustomPagination';
import IceBreakerType from 'layout/IceBreakerType';
import { declineReasons } from 'types/icebreaker';

import { IceBreakerBtns } from './submodules/IceBreakerBtns';
import { iceBreakerType } from './field-choices';
import { useEffect, useState } from 'react';
import { getArrayFromLinkedList } from '../../utils';
import BooleanFromValueField from 'layout/fields/BooleanFromValueField';
import { CsvExport } from 'component/common/CsvExport';
import { CSVSource } from 'types/enums/CSVSource';

const StyledNumberField = styled(NumberField)<{ $accentColor?: boolean }>`
  width: 30px;
  text-align: center;

  ${({ $accentColor }) =>
    $accentColor &&
    css`
      color: rgb(59, 130, 246);
    `};
`;

const StyledTextField = styled(TextField)`
  min-width: 320px;
  width: 320px;
`;

const StyledArrayField = styled(ArrayField)`
  min-width: 320px;
  width: 320px;
`;

const DeclineReasonField = (props: any) => {
  const record = useRecordContext();

  const reason = declineReasons.find(
    (item) => item.reason === record.decline_reason,
  );

  return (
    <TextField
      {...props}
      record={{ ...record, decline_reason: reason?.caption }}
    />
  );
};

const Actions = () => {
  return (
    <TopToolbar
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
      }}
    >
      <CsvExport source={CSVSource.IceBreaker} />
    </TopToolbar>
  );
};

const IceBreakerList = () => {
  const [suppliesSelectChoses, setSuppliesSelectChoses] = useState<any[]>([]);
  const [staffSelectChoses, setStaffSelectChoses] = useState<any[]>([]);

  const fetchSupplies = async () => {
    const suppliesArray = await getArrayFromLinkedList('/supply');

    setSuppliesSelectChoses(suppliesArray);
  };

  const fetchStaff = async () => {
    const staffArray = await getArrayFromLinkedList('/staff-list');

    setStaffSelectChoses(staffArray);
  };

  useEffect(() => {
    fetchSupplies();
    fetchStaff();
  }, []);

  return (
    <List
      title="All ice-breakers"
      perPage={15}
      actions={<Actions />}
      filters={[
        <NumberInput
          source="id"
          label="Id"
          alwaysOn
          style={{ width: '120px' }}
          variant="filled"
        />,

        !!suppliesSelectChoses && (
          <SelectInput
            alwaysOn
            optionText="id"
            optionValue="id"
            label="Supply ID"
            source="supply_id"
            choices={suppliesSelectChoses}
            style={{ minWidth: '120px', margin: 0 }}
            variant="filled"
          />
        ),

        <NumberInput
          source="trusted_user_id"
          alwaysOn
          label="TU id"
          style={{ width: '120px' }}
          variant="filled"
        />,

        <SelectInput
          label={'Type'}
          source="type"
          choices={iceBreakerType}
          alwaysOn
          style={{ width: '120px', margin: 0 }}
          variant="filled"
        />,

        !!staffSelectChoses && (
          <SelectInput
            alwaysOn
            optionText="name"
            optionValue="id"
            label="Moderator ID"
            source="moderator_id"
            choices={staffSelectChoses}
            style={{ minWidth: '120px', margin: 0 }}
            variant="filled"
          />
        ),
      ]}
      sort={{ field: 'updated_at', order: 'DESC' }}
      pagination={<CustomPagination />}
    >
      <Datagrid
        bulkActionButtons={false}
        optimized
        rowSx={(row) => {
          if (row.moderation_status === 3) {
            return { background: 'rgb(226, 222, 222)' };
          }
          return { background: 'transparent' };
        }}
      >
        <StyledNumberField
          source="id"
          options={{ useGrouping: false }}
          sortable={false}
        />

        <StyledNumberField
          label="Supply ID"
          source="trusted_user.supply_id"
          options={{ useGrouping: false }}
          sortable={false}
        />

        <StyledNumberField
          label="TU id"
          source="trusted_user.id"
          options={{ useGrouping: false }}
          sortable={false}
        />

        <TextField
          source="trusted_user.name"
          label="TU Name"
          sortable={false}
        />

        <StyledNumberField
          label="Carthage TU id"
          source="trusted_user.external_id"
          options={{ useGrouping: false }}
          sortable={false}
          textAlign="center"
          $accentColor
        />
        <StyledTextField source="message" sortable={false} />

        <StyledArrayField source="childrens" label="Childrens" sortable={false}>
          <SimpleList
            primaryText={(record) => record.message}
            secondaryText={(record) =>
              `Delay: ${record.delay}, Sent: ${record.sent_count}, Read: ${record.read_count}`
            }
            linkType={false}
          />
        </StyledArrayField>

        <ImageField
          source="media"
          src="big_url"
          sx={{
            [`& .RaImageField-list`]: {
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(50px, 1fr))',
              gridGap: '5px',
              maxWidth: '170px',
            },

            [`& .RaImageField-image`]: {
              width: '100%',
              margin: 0,
              objectFit: 'contain',
            },
          }}
        />

        <NumberField source="sent_count" sortable={false} />

        <NumberField source="read_count" sortable={false} />

        <FunctionField
          label="Type"
          render={(record?: Record<string, any>) => (
            <IceBreakerType record={record} />
          )}
        />

        <DateField source="created_at" locales="UK" showTime sortable={false} />

        <StyledNumberField
          label="Moderator ID"
          source="moderator_id"
          options={{ useGrouping: false }}
          sortable={false}
        />

        <BooleanFromValueField source="is_deleted" />

        <IceBreakerBtns />

        <ModerationBtns />

        <DeclineReasonField source="decline_reason" sortable={false} />
      </Datagrid>
    </List>
  );
};

export default IceBreakerList;
