import { SVGProps } from 'react';

const Logo = (props: SVGProps<SVGSVGElement>) => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 36 45"
      x="0px"
      y="0px"
      height="50px"
      style={{ transform: 'translateY(5px)', fill: '#7077A1' }}
    >
      <path d="M20.5631,8.80921,18,6.24611l-2.5631,2.5631A8.169,8.169,0,0,0,20.5631,8.80921Z" />
      <rect
        style={{ fill: '#424769' }}
        x="26.70123"
        y="15.80541"
        width="2.37611"
        height="3.3661"
      />
      <rect
        style={{ fill: '#424769' }}
        x="21.7621"
        y="20.75556"
        width="7.31525"
        height="3.35516"
      />
      <rect
        style={{ fill: '#424769' }}
        x="16.81195"
        y="25.69476"
        width="12.2654"
        height="3.3661"
      />
      <rect
        style={{ fill: '#424769' }}
        x="11.87281"
        y="30.64491"
        width="17.20454"
        height="3.35509"
      />
      <path d="M25.83223,5.20113A10.97741,10.97741,0,0,0,18.792,2V4.80507l3.71812,3.71812a.77867.77867,0,0,1-.198,1.26506,9.69273,9.69273,0,0,1-8.62422,0,.77867.77867,0,0,1-.198-1.26506L17.208,4.80507V2A11.10182,11.10182,0,0,0,6.92266,13.04438V34h3.3661V29.85285a.78873.78873,0,0,1,.792-.792h4.14708V24.9027a.79579.79579,0,0,1,.79206-.792H20.178V19.96356a.78876.78876,0,0,1,.79206-.79206h4.14708V15.01341a.7958.7958,0,0,1,.79206-.79206h3.1681v-1.177A11.01519,11.01519,0,0,0,25.83223,5.20113ZM15.15088,12.37331l-1.155,1.59506a.8074.8074,0,0,1-.649.33.82943.82943,0,0,1-.46206-.154.78982.78982,0,0,1-.176-1.1l1.166-1.60607a.78968.78968,0,0,1,1.1-.176A.80443.80443,0,0,1,15.15088,12.37331Zm3.64111,2.6401a.792.792,0,1,1-1.584,0v-1.969a.792.792,0,1,1,1.584,0Zm4.32319-.869a.82943.82943,0,0,1-.46206.154.8074.8074,0,0,1-.649-.33l-1.155-1.59506a.80443.80443,0,0,1,.176-1.111.78968.78968,0,0,1,1.1.176l1.166,1.60607A.78982.78982,0,0,1,23.11518,14.14441Z" />
    </svg>
    <p
      style={{
        fontFamily: 'Inter',
        fontSize: '30px',
        textTransform: 'uppercase',
        transform: 'translateY(2px)',
        color: '#424769',
      }}
    >
      pidval
    </p>
  </>
);

export default Logo;
