import { FC } from 'react';
import Zoom from 'react-medium-image-zoom';

import MediaModerationStatus from './MediaModerationStatus';

import { MediaType } from 'types/mediaType';
import { TrustedUserMedia } from 'types/trustedUser/trustedUserModeration';

import 'react-medium-image-zoom/dist/styles.css';
import { ImageList, ImageListItem } from '@mui/material';
import styled from 'styled-components';

const StyledImageList = styled(ImageList)`
  grid-template-rows: auto 1fr;
  gap: 10px;
  padding: 10px 0s;
`;

const StyledImageListItem = styled(ImageListItem)`
  display: flex;
  justify-content: center;
  margin-right: 5px;
`;
const PhotoItem = styled.img`
  height: 300px;
  max-width: 100%;
  min-width: 150px;
  object-fit: contain;
`;

const VideoItem = styled.video`
  max-width: 100%;
  min-width: 150px;
  height: 100%;
  object-fit: contain;
`;

interface Props {
  media: TrustedUserMedia[];
}

const MediaList: FC<Props> = ({ media }) => {
  if (!media) {
    return null;
  }

  if (media.length === 0) {
    return <p>No media</p>;
  }

  return (
    <StyledImageList cols={4} gap={10}>
      {media.map((item, index) => (
        <StyledImageListItem
          key={index}
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginRight: '5px',
          }}
        >
          {item.type === MediaType.Video ||
          item.type === MediaType.PrivateLibraryVideo ? (
            <VideoItem src={item.file_url} controls />
          ) : (
            <Zoom>
              <PhotoItem src={item.file_url} loading="lazy" />
            </Zoom>
          )}
          <MediaModerationStatus
            item={item}
            isEroticVisible={
              item.type === MediaType.PrivateLibraryPhoto ||
              item.type === MediaType.PrivateLibraryVideo
            }
          />
        </StyledImageListItem>
      ))}
    </StyledImageList>
  );
};

export default MediaList;
