import { FC, memo } from 'react';
import {
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
} from 'react-admin';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { postbackEmailDomainChoices } from 'pages/partner/submodules/field-choices';

import { Button, Grid } from '@mui/material';

interface Props {
  source: string;
  enabledPercentage?: number;
}

export const EmailConfigs: FC<Props> = memo(({ source, enabledPercentage }) => {
  return (
    <Grid container paddingLeft={0} sx={{ width: '100%' }}>
      <p>Email configs:</p>

      <ArrayInput source={source} label="">
        <SimpleFormIterator
          addButton={<Button startIcon={<AddCircleOutlineIcon />}>Add</Button>}
          disableClear
          inline
          getItemLabel={(index) => `#${index + 1}`}
          sx={{
            justifyContent: 'space-between',

            [`& .RaSimpleFormIterator-inline`]: {
              flexGrow: '1',
            },

            [`& .RaSimpleFormIterator-action`]: {
              width: '10%',
            },
          }}
        >
          <SelectInput
            source="domain"
            defaultValue={null}
            label="Domain"
            choices={postbackEmailDomainChoices}
            variant="filled"
          />
          <NumberInput
            source="enabled_percentage"
            defaultValue={enabledPercentage}
            min={0}
            max={100}
            step={1}
            label="% Enabled"
            variant="filled"
            sx={{ marginTop: '8px', width: '100px' }}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </Grid>
  );
});
