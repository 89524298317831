import { FC, useCallback, useEffect, useState } from 'react';


import { SupplyItem } from './SupplyItem';
import { SupplyItemType } from './WizardEditConfigs';
import { Button, Divider, Grid } from '@mui/material';
import styled from 'styled-components';

const ListItem = styled.li`
  padding: 10px 0;
`

const StyledGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin-bottom: 10px;
  background-color: #626262;
`;


const PlacementSupplyIds = styled.ul`
  margin-bottom: 10px;
`;

interface PlacementItems {
  [key: string]: SupplyItemType
}

interface Props{
  placementItems: PlacementItems[];
  placementPosition: number;
  index: number;
  supplyIdList: number[];
  onAddSupplyItem: (placementIndex: number) => void;
  onRemoveSupplyItem: (updatedPlacement: any, index: number) => void;
  setPlacementSupplyItem: (placementItem: SupplyItemType, index: number, supplyItemIndex: number)=> void;
}

export const PlacementItem: FC<Props> = ({ index, placementPosition, placementItems, supplyIdList, setPlacementSupplyItem, onAddSupplyItem, onRemoveSupplyItem}) => {
  const [addedItem, setAddedItem] = useState<string[]>([]);

  const handleSetSupplyItem = useCallback(({supply_id, weight}, supplyItemIndex) => {    
    setPlacementSupplyItem({supply_id, weight}, index, supplyItemIndex);
    setAddedItem(state => {
      state[supplyItemIndex] = supply_id;
      return state
    })
  }, [setPlacementSupplyItem]);

  const handleAddSupply = useCallback(() => {
    onAddSupplyItem(index);
  }, []);

  const handleRemoveItem = useCallback(supplyItemIndex => {   
    const filteredPlacements = placementItems
    .filter((supplyItem, supplyIndex) => supplyItemIndex !== supplyIndex && supplyItem)
    .map((item, index) => ({ [String(index)]: Object.values(item)[0] }));

    onRemoveSupplyItem(filteredPlacements, index);
    setAddedItem(filteredPlacements.map((item, index) => item[index].supply_id))
  }, [placementItems]);

  useEffect(() => {
    const idList = placementItems.map((item, index) => item[index].supply_id)
    
    setAddedItem(idList)
  }, [placementItems]);

  return ( 
    <ListItem>
      <Grid container alignItems="center" spacing={5}>
        <StyledGrid item xs={2}>
          Placement {placementPosition}
          <StyledButton 
            variant="contained" 
            type="button"
            color="primary" 
            size="small" 
            onClick={handleAddSupply}
          >
            Add supply id 
          </StyledButton>
        </StyledGrid>
        <Grid  item xs={10}>
          <PlacementSupplyIds>
              {placementItems.map((item, index) => {     
                return <SupplyItem 
                  id={item[index].supply_id} 
                  key={item[index].supply_id} 
                  weight={item[index].weight}
                  supplyItemIndex={index}
                  isDeleteButtonDisabled={placementItems.length === 1} 
                  usedIdList={addedItem}
                  supplyIdList={supplyIdList}
                  setSupplyItem={handleSetSupplyItem}
                  deleteSupplyItem={handleRemoveItem}
                />
              })}
          </PlacementSupplyIds>
        </Grid>
      </Grid>
      <Divider />
    </ListItem>
  );
};


