import { FC, useCallback, useState } from 'react';
import { useRefresh } from 'react-admin';

import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

import SuccessButton from 'layout/buttons/SuccessButton';
import GreyButton from 'layout/buttons/GreyButton';
import CustomSelect from 'layout/input/CustomSelect';
import { MONTHS } from 'pages/users/field-choices';
import { httpClient } from 'httpClient';
import styled from 'styled-components';

const InputContainer = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  margin-left: -5px;
`;

const Field = styled.div`
  position: relative;
`;

const Label = styled.label`
  position: absolute;
  top: -15px;
  left: 10px;
  font-size: 11px;
`;

const Input = styled.input`
  width: 100px;
  padding: 6px 12px 6px 6px;
  border: 0;
  outline: 0;
  font: inherit;
  border-radius: 0.25em;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
`;

const Error = styled.p`
  position: absolute;
  bottom: -15px;
  left: 5px;
  font-size: 11px;
  color: red;
`;

interface Props {
  userAge: number;
  userId: number;
}

export const ChangeAge: FC<Props> = ({ userAge, userId }) => {
  const [isAgeEdit, setIsAgeEdit] = useState<boolean>(false);
  const [month, setMonth] = useState(MONTHS[0]);
  const [day, setDay] = useState('');
  const [year, setYear] = useState('');
  const [error, setError] = useState('');

  const refresh = useRefresh();

  const changeMonth = useCallback((newValue: string) => {
    const filtredMonth = MONTHS.find((month) => month.value === newValue);

    if (filtredMonth) {
      setMonth(filtredMonth);
    }
  }, []);

  const changeDay = useCallback((newValue: string) => {
    setError('');
    setDay(newValue);
  }, []);

  const changeYear = useCallback((newValue: string) => {
    setError('');
    setYear(newValue);
  }, []);

  const cancelButtonClick = useCallback(() => {
    setIsAgeEdit(false);
    setError('');
  }, []);

  const submitChangedAge = useCallback(() => {
    if (!day || !year) {
      setError('The "Day" and "Year" fields must be completed');

      return;
    }

    if (Number(day) < 0) {
      setError('The value of the "Day" field cannot be negative');

      return;
    }

    if (Number(day) === 0) {
      setError('The value of the "Day" field cannot be zero');

      return;
    }

    if (Number(day) > 31) {
      setError('The maximum value of the "Day" field is 31');

      return;
    }

    if (Number(year) < 0) {
      setError('The value of the "Year" field cannot be negative');

      return;
    }

    if (year.length !== 4) {
      setError('The value in the "Year" field must be a four-digit number');

      return;
    }

    const formatedMonth = month.value;
    const formatedDay = day.length === 2 ? day : `0${day}`;

    const currentDate = new Date(Date.now());
    const enteredDate = new Date(`${year}-${formatedMonth}-${formatedDay}`);

    const timeDiff = currentDate.getTime() - enteredDate.getTime();
    const fullYearDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 365));

    if (fullYearDiff < 18) {
      setError('The user must be at least 18 years old');

      return;
    }

    if (fullYearDiff > 100) {
      setError("The user's age cannot exceed 100 years");

      return;
    }

    httpClient
      .put(`/users/${userId}`, {
        date_of_birth: `${year}-${formatedMonth}-${formatedDay}`,
      })
      .catch((error) => {
        alert(error.message);
      })
      .finally(() => {
        setMonth(MONTHS[0]);
        setDay('');
        setYear('');
        setIsAgeEdit(false);
        refresh();
      });
  }, [day, year, month, setDay, setYear]);

  return (
    <>
      {!isAgeEdit ? (
        <>
          <span>{userAge}</span>
          <GreyButton
            id="change-age"
            size="small"
            style={{ marginLeft: '10px' }}
            endIcon={<EditIcon />}
            onClick={() => setIsAgeEdit(true)}
          >
            Change
          </GreyButton>
        </>
      ) : (
        <>
          <InputContainer>
            <Field>
              <Label>Month:</Label>
              <CustomSelect choices={MONTHS} onChange={changeMonth} />
            </Field>

            <Field>
              <Label>Day:</Label>
              <Input
                value={day}
                type="number"
                min={1}
                max={31}
                onInput={(e) => changeDay(e.currentTarget.value)}
              />
            </Field>

            <Field>
              <Label>Year:</Label>
              <Input
                value={year}
                type="number"
                onInput={(e) => changeYear(e.currentTarget.value)}
              />
            </Field>
            <Error>{error}</Error>
          </InputContainer>

          <SuccessButton
            size="small"
            endIcon={<DoneIcon />}
            onClick={submitChangedAge}
          >
            Change
          </SuccessButton>
          <GreyButton
            size="small"
            endIcon={<CloseIcon />}
            onClick={cancelButtonClick}
          >
            Cancel
          </GreyButton>
        </>
      )}
    </>
  );
};
