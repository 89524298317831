import Zoom from 'react-medium-image-zoom';
import { Photo } from '../../../model-types';
import styled from 'styled-components';
import { ImageList, ImageListItem } from '@mui/material';

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow: hidden;
`;

const Image = styled.img`
  aspect-ratio: '1/1';
  object-fit: 'cover';
  object-position: 'center';
  width: 100%;
  max-width: 100%;
  max-height: 100%;
`;

export type ImageGridListProps = {
  tiles: Photo[];
};
const ImageGridList = ({ tiles }: ImageGridListProps) => {
  return (
    <Root>
      <ImageList cols={2} rowHeight="auto">
        {tiles.map((tile) => (
          <ImageListItem key={tile.profile_url}>
            <Zoom>
              <Image src={tile.profile_url} />
            </Zoom>
          </ImageListItem>
        ))}
      </ImageList>
    </Root>
  );
};
export default ImageGridList;
