import SupplyIcon from '@mui/icons-material/PersonAdd';
import SupplyList from './SupplyAgentsList';
import SupplyEdit from './SupplyAgentsEdit';
import SupplyCreate from './SupplyAgentsCreate';

export default {
  list: SupplyList,
  edit: SupplyEdit,
  create: SupplyCreate,
  icon: SupplyIcon,
};
