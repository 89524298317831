import { FC, memo, useState } from 'react';
import {
  TextInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  required,
  FormDataConsumer,
  useRecordContext,
} from 'react-admin';

import { Subsource, SubsourceGroup } from 'model-types';
import {
  eventTypeChoices,
  PostbackSendType,
  sendTypeChoices,
} from 'pages/partner/submodules/field-choices';
import ErrorButton from 'layout/buttons/ErrorButton';

import { Delete } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { SubsourcesList } from './SubsourcesList';
import { EmailConfigs } from './EmailConfigs';
import { SubsourceRules } from './SubsourceRules';

const requiredValidation = [required()];

export const SubsourceGroupsConfigs: FC = memo(() => {
  const record = useRecordContext();

  const [groupId, setGroupId] = useState(0);

  const handleRemoveAll = (configs: SubsourceGroup[]) => {
    configs.forEach((_, i) => {
      record.subsource_groups.remove(0);
    });
  };

  const handleShowSubsourcesList = (groupId: number) => {
    setGroupId(groupId);
  };

  const handleCloseSubsourcesList = () => {
    setGroupId(0);
  };

  return (
    <>
      <h3>Subsource groups:</h3>

      <Grid container justifyContent="flex-end">
        <FormDataConsumer>
          {({ formData }) => {
            return (
              <ErrorButton
                startIcon={<Delete />}
                size="small"
                onClick={() => handleRemoveAll(formData.subsource_groups)}
              >
                Remove groups
              </ErrorButton>
            );
          }}
        </FormDataConsumer>
      </Grid>

      <ArrayInput source="subsource_groups" label="">
        <SimpleFormIterator
          addButton={<Button startIcon={<AddCircleOutlineIcon />}>Add</Button>}
          disableClear
          inline
          getItemLabel={(index) => `#${index + 1}`}
          sx={{
            [`& .RaSimpleFormIterator-line`]: {
              justifyContent: 'space-between',
            },

            [`& .RaSimpleFormIterator-form`]: {
              flexGrow: '1',
            },
          }}
        >
          <FormDataConsumer>
            {(args: any) => {
              return (
                <>
                  {args?.scopedFormData?.first_subsource && (
                    <>
                      <Button
                        variant="outlined"
                        onClick={() =>
                          handleShowSubsourcesList(args.scopedFormData.id)
                        }
                      >
                        Included subsources
                      </Button>
                      <SubsourcesList
                        open={args.scopedFormData?.id === groupId}
                        groupId={groupId}
                        onClose={handleCloseSubsourcesList}
                      />
                    </>
                  )}
                </>
              );
            }}
          </FormDataConsumer>

          <TextInput
            source="name"
            defaultValue={null}
            label="Name"
            validate={requiredValidation}
            variant="filled"
            sx={{ width: '100px', marginTop: '8px' }}
          />
          <SelectInput
            source="event_type"
            defaultValue={null}
            label="Event Type"
            choices={eventTypeChoices}
            validate={requiredValidation}
            variant="filled"
            sx={{ width: '150px' }}
          />
          <SelectInput
            source="send_type"
            label="Send Type"
            choices={sendTypeChoices}
            defaultValue={PostbackSendType.RegistrationClick}
            variant="filled"
            validate={requiredValidation}
          />
          <NumberInput
            source="enabled_percentage"
            defaultValue={100}
            min={0}
            max={100}
            step={1}
            label="Shave %"
            variant="filled"
            sx={{ marginTop: '8px' }}
          />
          <NumberInput
            source="percentage_apply_offset"
            defaultValue={0}
            min={0}
            label="Shave Offset"
            variant="filled"
            sx={{ marginTop: '8px', width: '100px' }}
          />
          <NumberInput
            source="payout"
            defaultValue={0.0}
            min={0}
            step={0.1}
            label="Payout"
            variant="filled"
            sx={{ marginTop: '8px', width: '100px' }}
          />
          <TextInput
            source="url_pattern"
            defaultValue={null}
            label="Postback url template"
            variant="filled"
            validate={
              record?.traffic_type === 'api' ? requiredValidation : undefined
            }
            sx={{ width: '100%' }}
          />

          <FormDataConsumer>
            {({ scopedFormData, getSource }) => (
              <>
                <EmailConfigs
                  source={getSource ? getSource('partner_config_emails') : ''}
                  enabledPercentage={scopedFormData?.enabled_percentage}
                />

                <SubsourceRules
                  source={getSource ? getSource('subsource_rules') : ''}
                />
              </>
            )}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
});
