import { useRecordContext } from 'react-admin';
import { eventTypeChoices } from './submodules/field-choices';

function getEventName(id: number) {
  for (let i = 0, len = eventTypeChoices.length; i < len; i++) {
    if (eventTypeChoices[i].id === id) {
      return eventTypeChoices[i].name;
    }
  }
}

const ConfigListView = () => {
  const record = useRecordContext();

  const eventName =
    (record.is_enabled ? 'on' : 'off') +
    ':' +
    (record.subsource_id ? record.subsource_id : 'default') +
    ':' +
    getEventName(record.event_type) +
    ':' +
    record.payout;

  return <div>{eventName}</div>;
};
export default ConfigListView;
