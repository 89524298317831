import { FC } from 'react';
import { Button } from '@mui/material';
import {
  BooleanInput,
  NumberInput,
  ArrayInput,
  ReferenceInput,
  SimpleFormIterator,
  AutocompleteInput,
} from 'react-admin';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const DEFAULT_VALUE: unknown = [];

export const SubsourceConfigs: FC = () => {
  return (
    <>
      <h3>Subsource configs:</h3>

      <ArrayInput
        source="tds_path_configs"
        label=""
        defaultValue={DEFAULT_VALUE}
      >
        <SimpleFormIterator
          addButton={<Button startIcon={<AddCircleOutlineIcon />}>Add</Button>}
          disableClear
          inline
          getItemLabel={(index) => `#${index + 1}`}
          sx={{
            justifyContent: 'space-between',

            [`& .RaSimpleFormIterator-inline`]: {
              flexGrow: '1',
            },

            [`& .RaSimpleFormIterator-action`]: {
              width: '10%',
            },
          }}
        >
          <NumberInput
            source="subsource_id"
            defaultValue={null}
            min={0}
            label="Subsource ID"
            variant="filled"
            sx={{
              [`& .MuiInputBase-root`]: {
                height: '56px',
              },
            }}
          />
          <ReferenceInput
            label="Tds Path"
            source="tds_path_id"
            reference="tds/tds-paths"
            filterToQuery={(searchText: any) => ({
              name: '%' + searchText + '%',
            })}
            alwaysOn
          >
            <AutocompleteInput optionText="name" variant="filled" />
          </ReferenceInput>

          <BooleanInput
            source="is_api_blocked"
            defaultValue={false}
            label="Block API"
          />
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};
