import { Admin, CustomRoutes, Resource } from 'react-admin';
import { BrowserRouter } from 'react-router-dom';
import { Route } from 'react-router-dom';
import 'react-toggle/style.css';

import { authProvider } from 'authProvider';
import restProvider from 'restProvider';
import { Login, Layout } from 'layout';
import { lightTheme } from 'layout/themes';

import trustedUserModeration from 'pages/moderation/trustedUser';
import { ActivityByUser } from 'pages/activity/ActivityByUser';
import { WizardEditConfigs } from 'pages/wizard/config/WizardEditConfigs';
import UserPhotoModeration from 'pages/moderation/photo/UserPhotoModeration';
import trustedUser from 'pages/users/trustedUsers';
import users from 'pages/users/regularUsers';
import partner from 'pages/partner';
import partnerNetworks from 'pages/partner/network';
import textCategories from 'pages/translations/textCategory';
import textSources from 'pages/translations/textSource';
import systemSettings from 'pages/systemSettings';
import PhpEnumList from 'pages/docs/PhpEnumList';
import supportSettings from 'pages/support/notifications';
import supply from 'pages/supply/supply';
import supplyAgents from 'pages/supply/supplyAgents';
import supplyNotification from 'pages/supply/notifications';
import iceBreaker from 'pages/moderation/iceBreaker';
import iceBreakerAll from 'pages/iceBreakerAll';
import iceBreakerConfig from 'pages/iceBreakerConfig';
import activity from 'pages/activity';
import activityConfig from 'pages/activityConfig';
import gifts from 'pages/gifts';

const App = () => {
  return (
    <BrowserRouter>
      <Admin
        title="Pidval"
        loginPage={Login}
        layout={Layout}
        authProvider={authProvider}
        dataProvider={restProvider}
        theme={lightTheme}
        defaultTheme="light"
        disableTelemetry
      >
        <CustomRoutes>
          <Route
            path="/photo/moderation/:initPhotoId"
            element={<UserPhotoModeration />}
          />
          <Route path="/activity/user/:userId" element={<ActivityByUser />} />
          <Route path="/wizard-finder-config" element={<WizardEditConfigs />} />
        </CustomRoutes>

        <Resource name="photo/moderation" list={UserPhotoModeration} />
        <Resource name="trusted-user/moderation" {...trustedUserModeration} />
        <Resource
          name="trusted-user/re-moderation"
          {...trustedUserModeration}
        />
        <Resource name="trusted-user" {...trustedUser} />
        <Resource name="users" {...users} />
        <Resource name="partners" {...partner} />
        <Resource name="partners-networks" {...partnerNetworks} />
        <Resource name="translations/text-sources" {...textSources} />
        <Resource name="translations/text-categories" {...textCategories} />
        <Resource name="system-settings" {...systemSettings} />
        <Resource name="activity/config" {...activityConfig} />
        <Resource name="support/ticket" {...supportSettings} />
        <Resource name="docs/enums" list={PhpEnumList} />
        <Resource name="supply" {...supply} />
        <Resource name="supply-agent" {...supplyAgents} />
        <Resource name="supply-notification" {...supplyNotification} />
        <Resource name="ice-breaker/moderation" {...iceBreaker} />
        <Resource name="ice-breaker/all" {...iceBreakerAll} />
        <Resource name="ice-breaker/config" {...iceBreakerConfig} />
        <Resource name="activity" {...activity} />
        <Resource name="gifts" {...gifts} />
      </Admin>
    </BrowserRouter>
  );
};

export default App;
