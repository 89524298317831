import React, { useCallback, useEffect } from 'react';
import { Box } from '@mui/material';
import { PidvalApi } from 'api/PidvalApi';
import GreyButton from 'layout/buttons/GreyButton';
import SuccessButton from 'layout/buttons/SuccessButton';
import { useMutation, useQuery } from 'react-query';
import { CSVSource } from 'types/enums/CSVSource';
import { useListContext } from 'react-admin';

interface Props {
  source: CSVSource;
}

export const CsvExport: React.FC<Props> = ({ source }) => {
  const { filterValues } = useListContext();

  const {
    data: exportInfo,
    refetch: refreshExportInfo,
    isLoading: isLoadingInfo,
  } = useQuery(
    [`export-${source}`, filterValues],
    () => PidvalApi.fetchCSVExportInfo(source, filterValues),
    {
      enabled: !!source,
    },
  );

  const { mutate: generateCsv, isLoading: isGenerating } = useMutation(
    `export-generate-${source}`,
    () => PidvalApi.generateCSVFile(source, filterValues),
    {
      onSuccess: () => {
        refreshExportInfo();
      },
    },
  );

  const refreshExportInfoHandler = useCallback(() => {
    if (exportInfo?.processing) {
      setTimeout(async () => {
        const { data: refreshData } = await refreshExportInfo();

        if (refreshData?.processing) refreshExportInfoHandler();
      }, 15000);
    }
  }, [exportInfo?.processing]);

  const handleGenerateClick = () => {
    if (exportInfo?.processing) return;

    generateCsv();
  };

  const handleDownloadClick = () => {
    if (exportInfo?.file_url) {
      window.location.href = exportInfo.file_url;
    }
  };

  useEffect(() => {
    refreshExportInfoHandler();
  }, [refreshExportInfoHandler]);

  return (
    <Box display="flex" alignItems="center">
      <GreyButton
        onClick={handleGenerateClick}
        loading={isGenerating || exportInfo?.processing}
        disabled={isGenerating || isLoadingInfo}
      >
        Generate XLSX
      </GreyButton>
      <SuccessButton
        onClick={handleDownloadClick}
        disabled={isLoadingInfo || !exportInfo?.file_url}
      >
        Download XLSX
      </SuccessButton>
    </Box>
  );
};
