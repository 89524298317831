import { Datagrid, List, TextField } from 'react-admin';

const SystemSettingsList = () => {
  return (
    <List>
      <Datagrid optimized rowClick="edit">
        <TextField source="id" label="Key" />
        <TextField source="value" />
      </Datagrid>
    </List>
  );
};

export default SystemSettingsList;
