import { TextField, useRecordContext } from 'react-admin';

const ShortTextField = (props: any) => {
  const record = useRecordContext();

  return record ? (
    <>
      <TextField
        style={{ maxHeight: '100px', display: 'block', overflow: 'hidden' }}
        {...props}
      />
    </>
  ) : (
    <></>
  );
};
export default ShortTextField;
