import { FC, useEffect, useState } from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  AutocompleteArrayInput,
  NumberInput,
  useRecordContext,
  BooleanInput,
  ReferenceArrayInput,
} from 'react-admin';
import { SystemSetting } from 'model-types';
import { getCountryNameChoices } from 'countries';
import { PidvalApi, SiteIdChoices } from 'api/PidvalApi';

interface SystemSettingsProps {
  record?: SystemSetting;
}

const SystemSettingsTitle: FC<SystemSettingsProps> = () => {
  const record = useRecordContext();

  return record ? <span>System setting [{record.id}]</span> : null;
};

const SystemSettingsEditForm: FC = () => {
  const record = useRecordContext();

  const [siteIdChoices, setSiteIdChoices] = useState<SiteIdChoices[]>([]);

  useEffect(() => {
    const setSiteId = async () => {
      try {
        const siteInfo = await PidvalApi.fetchMirrorShortInfo();
        setSiteIdChoices(siteInfo);
      } catch (error) {
        console.error(error);
      }
    };

    if (record?.id === 'detect_profile_type_site_ids') setSiteId();
  }, [record?.id]);

  return (
    <SimpleForm>
      <TextInput disabled source="id" label="Key" variant="filled" />

      {record.id === 'api_buying_allow_countries' && (
        <AutocompleteArrayInput
          source="value"
          choices={getCountryNameChoices()}
          style={{ width: '100%' }}
          variant="filled"
        />
      )}

      {record.id === 'epc_min_clicks' && (
        <NumberInput source="value" label="Value" variant="filled" />
      )}

      {record.id === 'detect_profile_type_enabled' && (
        <BooleanInput source="value" label="Value" variant="filled" />
      )}

      {record.id === 'detect_profile_type_network_ids' && (
        <ReferenceArrayInput
          label="Network"
          source="value"
          optionValue="network_id"
          reference="partners-networks"
          allowEmpty={true}
        >
          <AutocompleteArrayInput optionText="name" variant="filled" />
        </ReferenceArrayInput>
      )}

      {record.id === 'detect_profile_type_site_ids' && (
        <AutocompleteArrayInput
          label="Site ids"
          source="value"
          choices={siteIdChoices}
          variant="filled"
        />
      )}
    </SimpleForm>
  );
};

const SystemSettingsEdit: FC = () => {
  return (
    <Edit title={<SystemSettingsTitle />}>
      <SystemSettingsEditForm />
    </Edit>
  );
};

export default SystemSettingsEdit;
