import { FC } from 'react';

import SuccessButton from 'layout/buttons/SuccessButton';

import { CircularProgress } from '@mui/material';

interface Props {
  isSubmitting: boolean;
  handleSubmitBtn: () => void;
}

export const SubmitBtn: FC<Props> = ({ isSubmitting, handleSubmitBtn }) => {
  return (
    <SuccessButton onClick={handleSubmitBtn} disabled={isSubmitting}>
      Submit
      {isSubmitting && <CircularProgress size={15} thickness={4} />}
    </SuccessButton>
  );
};

export default SubmitBtn;
