import { red } from '@mui/material/colors';

import Button from '@mui/material/Button';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  margin: 0 10px;
  color: #fff;
  background-color: ${() => red[700]};

  &:hover {
    background-color: ${() => red[900]};
  }

  &:disabled {
    background-color: ${() => red[50]};
  }
`;

const ErrorButton = (props: any) => {
  return <StyledButton variant="contained" {...props} />;
};

export default ErrorButton;
