import { FC } from 'react';
import {
  TextInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import styled from 'styled-components';
import { Grid } from '@mui/material';

import { costModelChoices } from 'consts';

import {
  partnerGroupChoices,
  platformChoices,
  trafficTypeChoices,
} from './field-choices';

const Root = styled.div`
  flex-grow: 1;
  width: 100%;
`;

const StyledBooleanInput = styled(BooleanInput)`
  width: 100%;
`;

const StyledSelectInput = styled(SelectInput)`
  width: 100%;
`;

export const MainConfigs: FC = () => {
  return (
    <Root>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <TextInput fullWidth source="name" variant="filled" required />
        </Grid>

        <Grid item xs={2}>
          <ReferenceInput
            label="Network"
            source="network_id"
            reference="partners-networks"
            allowEmpty={true}
            filterToQuery={(searchText: any) => ({
              name: '%' + searchText + '%',
            })}
            alwaysOn
          >
            <SelectInput
              variant="filled"
              optionText="name"
              sx={{
                marginTop: 0,
              }}
              fullWidth
            />
          </ReferenceInput>
        </Grid>

        <Grid item xs={2}>
          <TextInput source="region" variant="filled" required fullWidth />
        </Grid>

        <Grid item xs={2}>
          <TextInput
            source="country_code"
            variant="filled"
            required
            fullWidth
          />
        </Grid>

        <Grid item xs={2}>
          <StyledSelectInput
            source="traffic_type"
            defaultValue={null}
            choices={trafficTypeChoices}
            variant="filled"
            sx={{
              marginTop: '0px',
            }}
          />
        </Grid>

        <Grid item xs={2}>
          <StyledSelectInput
            source="cost_model"
            defaultValue={null}
            choices={costModelChoices}
            variant="filled"
          />
        </Grid>
        <Grid item xs={2}>
          <StyledSelectInput
            source="platform"
            defaultValue={null}
            choices={platformChoices}
            variant="filled"
          />
        </Grid>
        <Grid item xs={2}>
          <TextInput
            source="manager"
            required
            variant="filled"
            fullWidth
            sx={{
              marginTop: '8px',

              [`& .MuiFormHelperText-root`]: {
                height: 0,
              },
            }}
          />
        </Grid>

        <Grid item xs={2}>
          <TextInput
            source="direction"
            variant="filled"
            required
            fullWidth
            sx={{
              marginTop: '8px',

              [`& .MuiFormHelperText-root`]: {
                height: 0,
              },
            }}
          />
        </Grid>

        <Grid item xs={2}>
          <StyledSelectInput
            source="partner_group"
            defaultValue={null}
            choices={partnerGroupChoices}
            variant="filled"
          />
        </Grid>

        <Grid item xs={2}>
          <TextInput
            source="partner_tag"
            variant="filled"
            fullWidth
            sx={{
              marginTop: '8px',
            }}
          />
        </Grid>

        <Grid item xs={5}>
          <TextInput source="spammer" variant="filled" fullWidth />
        </Grid>

        <Grid item>
          <StyledBooleanInput
            source="is_shave_invalid_email"
            label="Shave invalid email"
            variant="filled"
          />
        </Grid>
      </Grid>
    </Root>
  );
};
