import { FC, useEffect, useState } from 'react';
import {
  SimpleForm,
  SelectArrayInput,
  required,
  Toolbar,
  TextInput,
  SaveButton,
  NumberInput,
  BooleanInput,
  SelectInput,
  useNotify,
  useRedirect,
  useRecordContext,
  PasswordInput,
  useUpdate,
} from 'react-admin';

import { PidvalApi, SiteIdChoices } from 'api/PidvalApi';

import { supplyRank } from './field-choices';
import { CircularProgress, Typography } from '@mui/material';
import styled from 'styled-components';
import { Coefficients } from './Coefficients';

export const featureTypes = [
  { id: 2, name: 'text msg send' },
  { id: 5, name: 'send gift (not real)' },
  { id: 8, name: 'photo msg send' },
  { id: 10, name: 'sticker msg send' },
  { id: 11, name: 'photo msg read' },
  { id: 17, name: 'inmail send' },
  { id: 18, name: 'inmail read' },
  { id: 19, name: 'inmail photo send' },
  { id: 20, name: 'inmail photo read' },
  { id: 30, name: 'present sent (real gift)' },
];

const validateFloatNumber = (value: number) => {
  if (value < 0.01) {
    return 'Value must be greater than 0.01';
  }
  if (value > 1) {
    return 'Value must be less than 1';
  }
};

const validateGiftCoefficient = (value: number) => {
  if (value < 0) {
    return 'Value must be greater than 0';
  }
  if (value > 1) {
    return 'Value must be less than 1';
  }
};

const validateRateLimit = (value: number) => {
  if (value < 60) {
    return 'Value must be at least 60';
  }
};

const validateMessageLimit = (value: number) => {
  if (value !== null && value <= 0) {
    return 'Value must be greater than 0';
  }
};

const StyledDivider = styled(Typography)`
  width: 100%;
  text-align: center;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
`;

interface SupplyProps {
  isEdit?: boolean;
}

const SupplyForm: FC<SupplyProps> = ({ isEdit = false }) => {
  const record = useRecordContext();

  const [update, { isLoading }] = useUpdate();

  const [siteIdChoices, setSiteIdChoices] = useState<SiteIdChoices[]>([]);
  const [prevSiteIdsList, setPrevSiteIdsList] = useState<number[] | undefined>(
    [],
  );

  const notify = useNotify();
  const redirectTo = useRedirect();

  const handleSave = (data: any) => {
    update(
      'supply',
      {
        id: record?.id || data?.id,
        data: data,
        previousData: record,
      },
      {
        onSuccess: (response) => {
          if (!prevSiteIdsList) {
            return;
          }

          if (response?.site_ids?.length < prevSiteIdsList?.length) {
            const removedItems = prevSiteIdsList.filter(
              (item) => !response.site_ids.includes(item),
            );

            const removedMirrorsName = siteIdChoices
              .filter((item) => removedItems.includes(item.id))
              .map((item) => item.name);

            notify(
              `You will deactivate ${
                response?.trusted_users_count
              } active profiles from  ${removedMirrorsName.join(', ')}`,
              { type: 'success' },
            );

            redirectTo('/supply');

            return;
          }

          if (response?.site_ids?.length > prevSiteIdsList?.length) {
            const availableMirrors = siteIdChoices
              .filter((item) => response?.site_ids.includes(item.id))
              .map((item) => item.name);

            notify(
              `You will duplicate ${
                response?.trusted_users_count
              } active profiles on ${availableMirrors.join(', ')}`,
              { type: 'success' },
            );

            redirectTo('/supply');
          }

          notify(`Changes were saved successfully!`, { type: 'success' });

          redirectTo('/supply');
        },
        onError: (error: any) => notify(error?.message, { type: 'error' }),
      },
    );
  };

  useEffect(() => {
    if (!prevSiteIdsList?.length && record?.site_ids) {
      setPrevSiteIdsList(record?.site_ids);
    }
  }, [record?.site_ids, prevSiteIdsList?.length]);

  useEffect(() => {
    const setSiteId = async () => {
      try {
        const siteInfo = await PidvalApi.fetchMirrorShortInfo();
        setSiteIdChoices(siteInfo);
      } catch (error) {
        console.error(error);
      }
    };

    setSiteId();
  }, []);

  return (
    <SimpleForm
      toolbar={
        <Toolbar style={{ position: 'relative' }}>
          <SaveButton disabled={isLoading} />

          {isLoading && (
            <div style={{ position: 'absolute', top: '17px', left: '60px' }}>
              <CircularProgress color="secondary" size="30px" />
            </div>
          )}
        </Toolbar>
      }
      {...(isEdit && { onSubmit: handleSave })}
    >
      <StyledDivider variant="h6" gutterBottom>
        General
      </StyledDivider>

      <TextInput source="name" validate={[required()]} variant="filled" />

      {siteIdChoices && (
        <SelectArrayInput
          label="Site ids"
          source="site_ids"
          choices={siteIdChoices}
          validate={[required()]}
          variant="filled"
        />
      )}

      <SelectInput
        label="Rank"
        source="rank"
        choices={supplyRank}
        defaultValue={0}
        validate={[required()]}
        variant="filled"
        sx={{ width: '200px' }}
      />
      <NumberInput
        source="coefficient"
        label="Paid credits coefficient (default)"
        min={0.04}
        step={0.001}
        max={1}
        defaultValue={0.0}
        validate={validateFloatNumber}
        sx={{ width: '200px' }}
        variant="filled"
      />
      <NumberInput
        label="Free credits coefficient (default)"
        source="free_coefficient"
        min={0.04}
        step={0.001}
        max={1}
        defaultValue={0.0}
        validate={validateFloatNumber}
        sx={{ width: '200px' }}
        variant="filled"
      />
      <NumberInput
        source="rate_limit_for_api"
        label="Rate limit for API"
        min={60}
        defaultValue={60}
        validate={validateRateLimit}
        sx={{ width: '200px' }}
        variant="filled"
      />
      <BooleanInput source="code_available" defaultValue={false} />

      <StyledDivider variant="h6" gutterBottom>
        Custom Coefficient
      </StyledDivider>

      <Coefficients />

      <StyledDivider variant="h6" gutterBottom>
        Gifts
      </StyledDivider>

      <BooleanInput source="gift_available" defaultValue={false} />
      <NumberInput
        source="gift_coefficient"
        label="Gift coefficient (default)"
        min={0}
        max={1}
        defaultValue={0.0}
        validate={validateGiftCoefficient}
        variant="filled"
        sx={{ width: '200px' }}
      />

      <StyledDivider variant="h6" gutterBottom>
        General Message limitation per 60 sec per RU
      </StyledDivider>

      <NumberInput
        source="rate_limit_chat_requests"
        label="Rate limit for Chat Requests"
        defaultValue={null}
        validate={validateMessageLimit}
      />

      <NumberInput
        source="rate_limit_messages"
        label="Rate limit for Messages"
        defaultValue={null}
        validate={validateMessageLimit}
      />

      {isEdit && (
        <PasswordInput
          source="secret_id"
          label="Secret id"
          disabled
          variant="filled"
        />
      )}

      {isEdit && (
        <PasswordInput
          source="secret_key"
          label="Secret key"
          disabled
          variant="filled"
        />
      )}
    </SimpleForm>
  );
};

export default SupplyForm;
