import { Datagrid, DateField, List, TextField, TopToolbar } from 'react-admin';

import MyPagination from 'layout/CustomPagination';
import StatusField from './submodules/StatusField';
import TicketField from './submodules/TicketField';
import ReasonField from './submodules/ReasonField';
import LinkField from './submodules/LinkField';
import { CsvExport } from 'component/common/CsvExport';
import { CSVSource } from 'types/enums/CSVSource';

const Actions = () => {
  return (
    <TopToolbar
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
      }}
    >
      <CsvExport source={CSVSource.SupportTicket} />
    </TopToolbar>
  );
};

const NotificationsList = () => {
  return (
    <List
      title="Tickets"
      perPage={15}
      actions={<Actions />}
      pagination={<MyPagination />}
      sort={{ field: 'status', order: 'DESC' }}
    >
      <Datagrid optimized bulkActionButtons={false}>
        <TextField source="id" sortable={false} align="left" />

        <LinkField
          source="user.id"
          label="UserId"
          sortable={false}
          align="left"
        />

        <TextField
          source="assignee.id"
          label="Assignee"
          sortable={false}
          align="left"
        />

        <TicketField label="Ticket" />

        <ReasonField source="reason" sortable={false} />

        <StatusField source="status" sortable={false} />

        <TextField
          source="user.username"
          label="Username"
          sortable={false}
          align="left"
        />

        <TextField
          source="user.country_name"
          label="Country"
          sortable={false}
          align="left"
        />

        <TextField
          source="user.host"
          label="Host"
          sortable={false}
          align="left"
        />

        <DateField
          source="updated_at"
          sortable={false}
          showTime
          locales="ru-RU"
        />

        <DateField
          source="created_at"
          sortable={false}
          showTime
          locales="ru-RU"
        />
      </Datagrid>
    </List>
  );
};

export default NotificationsList;
