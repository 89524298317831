import { FC } from 'react';
import { ReferenceInput, required, SimpleForm, TextInput } from 'react-admin';

import TreeSelectInput from '../../../layout/input/TreeSelectInput';

const TextCategoryForm: FC = () => {
  return (
    <SimpleForm>
      <div>
        <TextInput
          variant="filled"
          source="name"
          validate={[required()]}
          sx={{ width: '100%' }}
        />

        <ReferenceInput
          label="Parent category"
          source="parent_id"
          reference="translations/text-categories-select"
          alwaysOn
          allowEmpty={true}
        >
          <TreeSelectInput
            optionText="name"
            variant="filled"
            style={{ width: '100%' }}
          />
        </ReferenceInput>
      </div>
    </SimpleForm>
  );
};
export default TextCategoryForm;
