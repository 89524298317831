import {
  FC,
  ChangeEvent,
  useState,
  useMemo,
  useEffect,
  SetStateAction,
  Dispatch,
} from 'react';
import { useNotify, useRecordContext } from 'react-admin';
import { Box, Input, Popper } from '@mui/material';

import { TrustedUserModerationData } from 'types/trustedUser/trustedUserModeration';
import ErrorButton from 'layout/buttons/ErrorButton';
import SuccessButton from 'layout/buttons/SuccessButton';

import { TABS } from '../constants';
import styled from 'styled-components';
import { httpClient } from 'httpClient';
import { useLocation, useNavigate } from 'react-router';

const textNormalization = (text?: string) => {
  if (!text) {
    return;
  }

  return text[0].concat(text.slice(1).toLocaleLowerCase());
};

type Reasons = 'decline' | 'block' | 'deactivate';

const Root = styled.div`
  position: relative;
`;

const StyledBox = styled(Box)`
  background-color: #fff;
  padding: 10px;
  width: 300px;
  height: 210px;
  max-height: 200px;
  overflow-y: scroll;
  border-radius: 10px;
  border: 1px solid #ccc;
`;

const BlockButton = styled(ErrorButton)`
  background-color: #8a2be2;

  &:hover {
    background-color: #6711b7;
  }

  &:disabled {
    background-color: #d4b2f3;
  }
`;

const DeactivateButton = styled(ErrorButton)`
  background-color: #4da6ff;

  &:hover {
    background-color: #1b7fe3;
  }

  &:disabled {
    background-color: #bdd6ee;
  }
`;

interface Props {
  tabNumber: number;
  moderationData: TrustedUserModerationData;
  setModerationData: Dispatch<SetStateAction<TrustedUserModerationData>>;
}

const DEFAULT_DECLINES_REASON = {
  document_decline_reason: '',
  other_detail_decline_reason: '',
};

const DeclinePopup: FC<Props> = ({
  tabNumber,
  moderationData,
  setModerationData,
}) => {
  const notify = useNotify();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const record = useRecordContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [declineReasonInput, setDeclineReasonInput] = useState(
    DEFAULT_DECLINES_REASON,
  );
  const [reasonKey, setReasonKey] = useState<Reasons>('decline');

  const declineKey = useMemo(
    () =>
      tabNumber === 0
        ? 'document_decline_reason'
        : 'other_detail_decline_reason',
    [tabNumber],
  );

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined; //"decline-button"

  const notificationHandler = () => {
    notify(`${textNormalization(anchorEl?.innerText)} reason saved`);
  };

  const handleSaveDeclineReason = async () => {
    const currentModerateStatus = TABS[tabNumber];

    if (reasonKey === 'block') {
      await httpClient.put<{ reason: string }>(
        `/trusted-user/${record.id}/blocking`,
        {
          reason: declineReasonInput[declineKey],
        },
      );

      setAnchorEl(null);
      notificationHandler();
      navigate(`/${pathname.split('/')[1]}/${pathname.split('/')[2]}`);

      return;
    }

    if (reasonKey === 'deactivate') {
      await httpClient.put<{ reason: string }>(
        `/trusted-user/${record.id}/deactivate`,
        {
          reason: declineReasonInput[declineKey],
        },
      );

      setAnchorEl(null);
      notificationHandler();
      navigate(`/${pathname.split('/')[1]}/${pathname.split('/')[2]}`);

      return;
    }

    setModerationData({
      ...moderationData,
      [declineKey]: declineReasonInput[declineKey],
      [currentModerateStatus]: !declineReasonInput[declineKey],
    });

    setAnchorEl(null);
    notificationHandler();
  };

  const handleOpenDeclinePopupBtn = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setReasonKey(event.currentTarget.innerText.toLocaleLowerCase() as Reasons);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDeclineReasonInput((prev) => {
      return { ...prev, [declineKey]: event.target.value };
    });
  };

  useEffect(() => {
    if (moderationData?.[declineKey]) {
      setDeclineReasonInput({
        document_decline_reason: moderationData.document_decline_reason,
        other_detail_decline_reason: moderationData.other_detail_decline_reason,
      });
    }
  }, []);

  return (
    <Root>
      <BlockButton id={id} onClick={handleOpenDeclinePopupBtn}>
        Block
      </BlockButton>
      <DeactivateButton id={id} onClick={handleOpenDeclinePopupBtn}>
        Deactivate
      </DeactivateButton>
      <ErrorButton id={id} onClick={handleOpenDeclinePopupBtn}>
        Decline
      </ErrorButton>

      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        disablePortal={false}
        sx={{
          zIndex: '2',
        }}
      >
        <StyledBox>
          <Input
            placeholder="Reason"
            multiline
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
              width: '100%',
              padding: '5px',
              overflowY: 'scroll',
            }}
            value={declineReasonInput[declineKey]}
            onChange={handleInputChange}
          />
          <SuccessButton
            style={{ margin: '10px 0 0', backgroundColor: '#4f3cc9' }}
            onClick={handleSaveDeclineReason}
          >
            Save
          </SuccessButton>
        </StyledBox>
      </Popper>
    </Root>
  );
};

export default DeclinePopup;
