import {
  Datagrid,
  List,
  TextField,
  NumberField,
  FunctionField,
  DateField,
  ImageField,
  ArrayField,
  SimpleList,
} from 'react-admin';
import CustomPagination from 'layout/CustomPagination';

import { ModerationBtns } from 'component/common/ModerationBtns';
import IceBreakerType from 'layout/IceBreakerType';
import styled from 'styled-components';

const StyledNumberField = styled(NumberField)`
  width: 30px;
  text-align: center;
`;

const StyledArrayField = styled(ArrayField)`
  min-width: 160px;
  width: 160px;
`;

const IceBreakerList = () => {
  return (
    <List
      perPage={15}
      sort={{ field: 'updated_at', order: 'DESC' }}
      pagination={<CustomPagination />}
    >
      <Datagrid optimized bulkActionButtons={false}>
        <StyledNumberField
          source="id"
          options={{ useGrouping: false }}
          sortable={false}
          textAlign="center"
        />

        <StyledNumberField
          label="TU id"
          source="trusted_user.id"
          options={{ useGrouping: false }}
          sortable={false}
        />

        <StyledNumberField
          label="Carthage TU id"
          source="trusted_user.external_id"
          style={{ color: 'rgb(59,130,246)' }}
          options={{ useGrouping: false }}
          sortable={false}
          textAlign="center"
        />

        <TextField source="message" sortable={false} />

        <StyledArrayField source="childrens" label="Childrens" sortable={false}>
          <SimpleList
            primaryText={(record) => record.message}
            secondaryText={(record) => `Delay: ${record.delay}`}
            linkType={false}
          />
        </StyledArrayField>

        <ImageField
          source="media"
          src="big_url"
          emptyText="No images"
          sx={{
            [`& .RaImageField-list`]: {
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(50px, 1fr))',
              gridGap: '5px',
              maxWidth: '170px',
            },

            [`& .RaImageField-image`]: {
              width: '100%',
              margin: 0,
              objectFit: 'contain',
            },
          }}
        />

        <FunctionField
          label="Type"
          render={(record?: Record<string, any>) => (
            <IceBreakerType record={record} />
          )}
        />

        <DateField source="created_at" locales="UK" sortable={false} />

        <ModerationBtns />
      </Datagrid>
    </List>
  );
};

export default IceBreakerList;
