import { FC, ReactElement } from 'react';
import { ExpandMore } from '@mui/icons-material';
import {
  Collapse,
  List,
  ListItemIcon,
  MenuItem,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';

const Icon = styled(ListItemIcon)`
  min-width: ${(props) => props.theme.spacing(5)};
`;

const SiteBar = styled(List)<{ open: boolean }>`
  & a {
    padding-left: ${(props) =>
      props.open ? props.theme.spacing(4) : props.theme.spacing(2)};
    transition: padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  }
`;

interface Props {
  handleToggle: () => void;
  icon: ReactElement;
  isOpen: boolean;
  name: string;
  sidebarIsOpen: boolean;
}

const SubMenu: FC<Props> = ({
  handleToggle,
  sidebarIsOpen,
  isOpen,
  name,
  icon,
  children,
}) => {
  const header = (
    <MenuItem onClick={handleToggle}>
      <Icon>{isOpen ? <ExpandMore /> : icon}</Icon>
      <Typography variant="inherit" color="textSecondary">
        {name}
      </Typography>
    </MenuItem>
  );

  return (
    <div>
      {sidebarIsOpen || isOpen ? (
        header
      ) : (
        <Tooltip title={name} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <SiteBar disablePadding open={sidebarIsOpen}>
          {children}
        </SiteBar>
      </Collapse>
    </div>
  );
};

export default SubMenu;
