import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  TextField,
  NumberField,
  RaRecord,
} from 'react-admin';
import MyPagination from 'layout/CustomPagination';
import TypeField from './submodules/TypeField';
import { Supply } from 'model-types';
import { Chip } from '@mui/material';
import styled from 'styled-components';

const StyledNumberField = styled(NumberField)`
  width: 30px;
  text-align: center;
`;

const NotificationsList = () => {
  const changeRowStyle = (record: any) => {
    return {
      cursor: record.deleted_at ? 'auto' : 'pointer',
      backgroundColor: record.deleted_at ? '#e2dede' : 'inherit',
    };
  };

  const getSuppliesChip = (record: RaRecord | undefined) => {
    return record?.supplies?.map((supply: Supply) => (
      <Chip
        size="small"
        key={supply.id}
        label={supply.name}
        style={{ marginBottom: '5px' }}
      />
    ));
  };

  return (
    <List perPage={15} pagination={<MyPagination />}>
      <Datagrid
        optimized
        rowSx={changeRowStyle}
        isRowSelectable={(row) => !row.deleted_at}
      >
        <StyledNumberField
          source="id"
          options={{ useGrouping: false }}
          sortable={false}
        />

        <TypeField source="type" />

        <TextField
          source="message"
          style={{
            maxHeight: '110px',
            display: 'block',
            overflowY: 'scroll',
          }}
          sortable={false}
        />

        <FunctionField
          label="Supplies"
          render={getSuppliesChip}
          sortable={false}
          sx={{ maxHeight: '115px', display: 'block', overflowY: 'scroll' }}
        />

        <DateField source="deleted_at" locales="UK" sortable={false} />
      </Datagrid>
    </List>
  );
};

export default NotificationsList;
