import {
  DatagridConfigurable,
  DateField,
  List,
  NumberField,
  TextField,
  NumberInput,
  TextInput,
  BooleanField,
  SelectInput,
  ImageField,
  TopToolbar,
  SelectColumnsButton,
  Pagination,
} from 'react-admin';

import { profileType, spendSegmentChoices } from '../field-choices';
import BooleanFromValueField from 'layout/fields/BooleanFromValueField';
import { CsvExport } from 'component/common/CsvExport';
import { CSVSource } from 'types/enums/CSVSource';

const filters = [
  <NumberInput
    source="id"
    label="Id"
    alwaysOn
    style={{ width: '120px' }}
    variant="filled"
  />,

  <TextInput
    source="email"
    alwaysOn
    label="Email"
    style={{ width: '120px' }}
    variant="filled"
  />,

  <TextInput
    source="ulid_id"
    alwaysOn
    label="Ulid"
    style={{ width: '120px' }}
    variant="filled"
  />,

  <TextInput
    source="username"
    alwaysOn
    label="Username"
    style={{ width: '120px' }}
    variant="filled"
  />,

  <NumberInput
    source="site_id"
    label="Site"
    alwaysOn
    style={{ width: '120px' }}
    variant="filled"
  />,

  <SelectInput
    source="profile_type"
    choices={profileType}
    alwaysOn
    style={{ width: '120px', marginBottom: 0 }}
    variant="filled"
  />,

  <SelectInput
    source="spend_segment"
    choices={spendSegmentChoices}
    alwaysOn
    style={{ width: '120px', marginBottom: 0 }}
    variant="filled"
  />,
];

const Actions = () => {
  return (
    <TopToolbar
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
      }}
    >
      <SelectColumnsButton />
      <CsvExport source={CSVSource.RegularUser} />
    </TopToolbar>
  );
};

const RegularUserList = () => {
  return (
    <List
      filters={filters}
      actions={<Actions />}
      pagination={<Pagination rowsPerPageOptions={[]} perPage={100} />}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <DatagridConfigurable rowClick="edit" bulkActionButtons={false}>
        <NumberField source="id" />
        <TextField source="ulid_id" sortable={false} />
        <TextField source="email" />
        <NumberField source="site_id" sortable={false} />
        <TextField source="profile_type" sortable={false} />
        <NumberField source="age" sortable={false} />
        <TextField source="name" />
        <BooleanField source="is_deleted" sortable={false} />
        <TextField source="moderation_status" sortable={false} />
        <BooleanFromValueField source="is_test" />
        <BooleanField source="is_chb" sortable={false} />
        <NumberField source="credits" sortable={false} />
        <ImageField source="thumbnail" sortable={false} />
        <TextField source="photo_count" sortable={false} />
        <TextField source="city" sortable={false} />
        <TextField source="country" sortable={false} />
        <DateField
          source="created_at"
          locales="ru-RU"
          showTime
          sortable={false}
        />
      </DatagridConfigurable>
    </List>
  );
};

export default RegularUserList;
