import {
  CreateButton,
  Datagrid,
  DateField,
  List,
  NumberField,
  TextField,
  TopToolbar,
} from 'react-admin';

const ListActions = () => {
  const createPath = '/activity/config/create';

  return (
    <TopToolbar>
      <CreateButton to={createPath} />
    </TopToolbar>
  );
};

const ActivityConfigList = () => {
  return (
    <List title="Activity configs" actions={<ListActions />}>
      <Datagrid optimized rowClick="edit">
        <TextField source="id" label="ID" sortable={false} />
        <TextField source="supply_id" sortable={false} />
        <NumberField source="weight" sortable={false} />
        <DateField source="created_at" showTime={true} sortable={false} />
        <DateField source="updated_at" showTime={true} sortable={false} />
      </Datagrid>
    </List>
  );
};

export default ActivityConfigList;
