import { FC, memo } from 'react';
import {
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  FormDataConsumer,
  required,
} from 'react-admin';

import {
  subSourceFields,
  subSourceOperators,
} from 'pages/partner/submodules/field-choices';
import { conditionOperators } from 'consts';

const requiredValidation = [required()];

interface Props {
  source: string;
}

export const SubsourceRules: FC<Props> = memo(({ source }) => {
  return (
    <>
      <p style={{ marginTop: '15px' }}>Include subsources rules:</p>
      <ArrayInput source={source} label="">
        <SimpleFormIterator>
          <SelectInput
            source="field"
            label="Field"
            variant="filled"
            choices={subSourceFields}
            validate={requiredValidation}
          />

          <FormDataConsumer>
            {({ scopedFormData, getSource }) => {
              return (
                <>
                  <SelectInput
                    source={getSource && getSource('operator')}
                    label="Operator"
                    validate={requiredValidation}
                    variant="filled"
                    choices={
                      scopedFormData?.field
                        ? subSourceOperators[scopedFormData.field]
                        : subSourceOperators.name
                    }
                  />
                </>
              );
            }}
          </FormDataConsumer>

          <FormDataConsumer>
            {({ scopedFormData, getSource }) => {
              let isDisabled = false;
              let helpText = '';

              if (scopedFormData && scopedFormData.operator) {
                isDisabled =
                  scopedFormData.operator === conditionOperators.is_null ||
                  scopedFormData.operator === conditionOperators.is_not_null;

                if (
                  scopedFormData.operator === conditionOperators.in ||
                  scopedFormData.operator === conditionOperators.not_in
                ) {
                  helpText = 'Values must be written to coma!';
                }
              }

              return (
                <TextInput
                  source={getSource ? getSource('value') : ''}
                  label="Value"
                  disabled={isDisabled}
                  helperText={helpText}
                  validate={requiredValidation}
                  variant="filled"
                />
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
});
