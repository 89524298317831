import { useEffect, useState } from 'react';
import { Title } from 'react-admin';
import { useParams } from 'react-router-dom';
import { httpClient } from '../../httpClient';
import ActivityList from './ActivityList';
import GreyButton from '../../layout/buttons/GreyButton';
import ActivityQueueTable from './components/ActivityQueueTable';
import { Box, Card, CardContent, Grid } from '@mui/material';
import styled from 'styled-components';

const Root = styled.div`
  padding: 30px 0;
`;

const Header = styled.h2`
  margin: 15px 0;
  text-align: center;
`;

export const ActivityByUser = () => {
  const { userId } = useParams();

  const [userData, setUserData] = useState<any | null>(null);
  const [loading, setLoading] = useState(true);
  const [isPaused, setIsPaused] = useState(false);

  const historyProps = {
    basePath: '/activity',
    resource: 'activity',
    filter: { user_id: userId },
    filters: null,
    page: 1,
  };

  const pause = () => {
    httpClient
      .post(`/activity/switch-user/${userId}?pause=1`)
      .then((response) => setIsPaused(response.data.paused));
  };

  const unpause = () => {
    httpClient
      .post(`/activity/switch-user/${userId}?pause=0`)
      .then((response) => setIsPaused(response.data.paused));
  };

  useEffect(() => {
    httpClient.get(`/activity/user/${userId}`).then((response) => {
      setUserData(response.data);
      setLoading(false);
      setIsPaused(response.data.is_paused);
    });
  }, []);

  if (loading) return null;

  return (
    <Root>
      <Title title="Activity by user" />
      <Card variant="outlined">
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <span>User: {userId}</span>
            </Grid>
            <Grid item xs={8}>
              <span>Status: {isPaused ? 'Paused' : 'Active'}</span>
              {isPaused ? (
                <GreyButton size="small" onClick={unpause}>
                  Unpause
                </GreyButton>
              ) : (
                <GreyButton size="small" onClick={pause}>
                  Pause
                </GreyButton>
              )}
            </Grid>
          </Grid>

          {userData?.queue?.length > 0 && (
            <Grid container spacing={3}>
              <Grid xs={12} item>
                <Header>Queue</Header>
              </Grid>
              <Grid xs={12} item>
                <ActivityQueueTable items={userData.queue} />
              </Grid>
            </Grid>
          )}

          <Grid container spacing={3}>
            <Grid xs={12} item>
              <Header>History</Header>
            </Grid>

            <Grid xs={12} item>
              <ActivityList {...historyProps} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Root>
  );
};
