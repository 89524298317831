import NotificationsIcon from '@mui/icons-material/NotificationsActive';

import NotificationsList from './NotificationsList';
import NotificationsCreate from './NotificationsCreate';

export default {
  list: NotificationsList,
  create: NotificationsCreate,
  icon: NotificationsIcon,
};
