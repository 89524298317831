import { memo, useState } from 'react';
import { TextareaAutosize } from '@mui/material';
import { EditButton, UrlField, useRecordContext, useUpdate } from 'react-admin';
import styled from 'styled-components';

import { SupportTicket } from 'types/SupportTicket';

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 330px;
`;

const StyledUrlField = styled(UrlField)`
  overflow-wrap: anywhere;
`;

const StyledTextareaAutosize = styled(TextareaAutosize)`
  flex: 1;
`;

const StyledEditButton = styled(EditButton)`
  margin-left: 10px;
`;

const TicketField = memo((props: any) => {
  const record = useRecordContext<SupportTicket>();

  const [update, { isLoading }] = useUpdate<SupportTicket>();

  const [value, setValue] = useState(() => record.ticket || '');
  const [isEditing, setIsEditing] = useState(false);

  const toggleEdit = () => {
    if (isEditing) {
      setIsEditing(false);

      update('support/ticket', {
        id: record.id,
        data: { ticket: value },
        previousData: record,
      });

      return;
    }

    setIsEditing(true);
  };

  const handleInputChange = (newValue: string) => setValue(newValue);

  return (
    <Root {...props}>
      {!isEditing && (
        <StyledUrlField
          source="ticket"
          sortable={false}
          label="Ticket"
          target="_blank"
        />
      )}

      {isEditing && (
        <StyledTextareaAutosize
          value={value}
          minRows={2}
          onChange={(e) => handleInputChange(e.target.value)}
        />
      )}

      <StyledEditButton
        label={isEditing ? 'Save' : 'Edit'}
        disabled={isLoading}
        onClick={toggleEdit}
      />
    </Root>
  );
});

export default TicketField;
