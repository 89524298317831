import PartnerIcon from '@mui/icons-material/EmojiPeople';

import PartnerList from './PartnerList';
import PartnerEdit from './PartnerEdit';
import PartnerCreate from './PartnerCreate';

export default {
  list: PartnerList,
  edit: PartnerEdit,
  create: PartnerCreate,
  icon: PartnerIcon,
};
