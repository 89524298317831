import React, { FC } from 'react';
import ErrorButton from './ErrorButton';
import SuccessButton from './SuccessButton';
import { CircularProgress } from '@mui/material';

interface Props {
  isActivate: boolean;
  handleDeactivate: () => void;
  handleActivate: () => void;
  isLoader?: boolean;
  activateLabel?: string;
  deactivateLabel?: string;
  disabled?: boolean;
}

const ToggleActivateButton: FC<Props> = ({
  isActivate,
  handleDeactivate,
  handleActivate,
  isLoader = false,
  activateLabel = 'Activate',
  deactivateLabel = 'Deactivate',
  disabled = false,
}) => {
  return (
    <>
      {isActivate ? (
        <SuccessButton
          size="small"
          onClick={handleActivate}
          style={{ minWidth: '130px', margin: '4px' }}
          disabled={disabled}
        >
          {isLoader ? <CircularProgress size="20px" /> : activateLabel}
        </SuccessButton>
      ) : (
        <ErrorButton
          size="small"
          onClick={handleDeactivate}
          style={{ minWidth: '130px', margin: '4px' }}
          disabled={disabled}
        >
          {isLoader ? <CircularProgress size="20px" /> : deactivateLabel}
        </ErrorButton>
      )}
    </>
  );
};

export default ToggleActivateButton;
