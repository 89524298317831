import { FC } from 'react';

import { Edit, SimpleForm, TextInput, useRecordContext } from 'react-admin';

const PartnerNetworkTitle: FC = () => {
  const record = useRecordContext();

  return record ? <span>Partner Network #{record.id}</span> : null;
};

const PartnerNetworkEdit: FC = () => {
  return (
    <Edit title={<PartnerNetworkTitle />}>
      <SimpleForm>
        <TextInput source="name" />
      </SimpleForm>
    </Edit>
  );
};

export default PartnerNetworkEdit;
