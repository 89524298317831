import { PostbackEventConfigTiming } from 'model-types';

const inTimeRange = (hour: number, minute: number, timeRange: any) => {
  return (
    timeRange.fHour <= hour &&
    hour <= timeRange.tHour &&
    ((hour === timeRange.fHour && minute >= timeRange.fMinute) ||
      (hour === timeRange.tHour && minute <= timeRange.tMinute) ||
      (timeRange.fHour < hour && hour < timeRange.tHour))
  );
};

export const validateTimings = ({
  timings,
  postbackErrors,
}: {
  timings: PostbackEventConfigTiming[];
  postbackErrors: any;
}) => {
  const uniqueTimings: any[] = [];

  if (timings.length > 0) {
    for (let j = 0; j < timings.length; j++) {
      if (timings[j]) {
        let eventConfigTiming = timings[j];
        const errors: any = { postback_event_config_timings: [] };

        errors.postback_event_config_timings[j] = {};

        let timing = {
          fHour: eventConfigTiming.from_hour ?? 0,
          fMinute: eventConfigTiming.from_minute ?? 0,
          tHour: eventConfigTiming.to_hour ?? 0,
          tMinute: eventConfigTiming.to_minute ?? 0,
        };

        if (
          timing.tHour < timing.fHour ||
          (timing.tHour == timing.fHour && timing.tMinute <= timing.fMinute)
        ) {
          errors.postback_event_config_timings[j].to_hour =
            'TO date should be greater than FROM date';
        }

        let enabledPercentage = eventConfigTiming.enabled_percentage || null;

        if (
          enabledPercentage &&
          (enabledPercentage > 100 || enabledPercentage < 0)
        ) {
          errors.postback_event_config_timings[j].enabled_percentage =
            'Not valid field value';
        }

        for (let k = 0; k < uniqueTimings.length; k++) {
          let uniqueTiming = uniqueTimings[k];
          if (
            inTimeRange(timing.fHour, timing.fMinute, uniqueTiming) ||
            inTimeRange(timing.tHour, timing.tMinute, uniqueTiming) ||
            inTimeRange(uniqueTiming.fHour, uniqueTiming.fMinute, timing) ||
            inTimeRange(uniqueTiming.tHour, uniqueTiming.tMinute, timing)
          ) {
            errors.postback_event_config_timings[j].from_hour =
              'Time intersection';
          }
        }

        if (errors.postback_event_config_timings.length) {
          postbackErrors.postback_event_config_timings =
            errors.postback_event_config_timings;
        }

        uniqueTimings.push(timing);
      }
    }
  }
};
