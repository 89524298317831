import {
  Datagrid,
  DateField,
  List,
  TextField,
  NumberInput,
  TextInput,
} from 'react-admin';

const PartnerNetworkList = () => {
  return (
    <List
      filters={[
        <NumberInput source="id" alwaysOn variant="filled" />,
        <TextInput source="name" alwaysOn variant="filled" />,
      ]}
      perPage={50}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <Datagrid optimized rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <DateField source="created_at" locales="ru-RU" showTime />
        <DateField source="updated_at" locales="ru-RU" showTime />
      </Datagrid>
    </List>
  );
};

export default PartnerNetworkList;
