import { FC } from 'react';
import { TextField, useRecordContext } from 'react-admin';
import Zoom from 'react-medium-image-zoom';

import 'react-medium-image-zoom/dist/styles.css';
import { Box, Grid, ImageListItem, Typography } from '@mui/material';
import styled from 'styled-components';

import { ReasonBadge } from 'component/common/ReasonBadge';

const Span = styled.span`
  display: block;
  margin: 8px 0 0 0;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
`;

const Image = styled.img`
  width: 100%;
  max-width: 400px;
  object-fit: contain;
  object-position: top 0 left 0;
`;

interface Props {
  declineReason: string;
  blockReason?: string;
  deactivateReason?: string;
}

export const DocumentSection: FC<Props> = ({
  declineReason,
  blockReason,
  deactivateReason,
}) => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={3}>
          <Zoom>
            <Image
              src={record.avatar}
              style={{
                objectFit: 'contain',
              }}
              loading="lazy"
            />
          </Zoom>
        </Grid>

        <Grid item xs={9} container>
          {!!declineReason && (
            <Grid item xs={12}>
              <ReasonBadge reason={declineReason} color="#d32f2f" />
            </Grid>
          )}

          {!!blockReason && (
            <Grid item xs={12}>
              <ReasonBadge reason={blockReason} color="#8a2be2" />
            </Grid>
          )}

          {!!deactivateReason && (
            <Grid item xs={12}>
              <ReasonBadge reason={deactivateReason} color="#4da6ff" />
            </Grid>
          )}

          <Grid item xs={4}>
            <Box>
              <Span>Date of birth</Span>
              <TextField
                source="documents.date_of_birth"
                label="Date of birth"
              />
            </Box>
            <Box>
              <Span>Name</Span>
              <TextField source="documents.name" label="Name" />
            </Box>
            <Box>
              <Span>City</Span>
              <TextField source="documents.city_id.name" label="City" />
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Box>
              <Span>Email</Span>
              <TextField source="documents.email" label="Email" />
            </Box>
            <Box>
              <Span>Last Name</Span>
              <TextField source="documents.last_name" label="Last name" />
            </Box>
            <Box>
              <Span>Country</Span>
              <TextField source="documents.country_id.name" label="Country" />
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} container>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            color="textSecondary"
            style={{ textAlign: 'center' }}
          >
            Verification info
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Span>Passport number</Span>
          <TextField source="documents.passport_number" />
        </Grid>

        {!!record?.documents?.passport_scan && (
          <Grid item xs={12}>
            <Span>Passport scan</Span>
            <Zoom>
              <Image
                src={record.documents.passport_scan}
                style={{
                  objectFit: 'contain',
                }}
                loading="lazy"
              />
            </Zoom>
          </Grid>
        )}

        {!!record?.documents?.verification_documents && (
          <Grid item xs={12}>
            <Span>Verification documents</Span>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                listStyleType: 'none',
              }}
            >
              {record.documents.verification_documents.map(
                (item: any, index: number) => (
                  <ImageListItem key={index}>
                    <Zoom>
                      <img
                        src={item.preview_url}
                        style={{
                          height: '180px',
                          objectFit: 'contain',
                          marginRight: '5px',
                        }}
                        loading="lazy"
                      />
                    </Zoom>
                  </ImageListItem>
                ),
              )}
            </div>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
