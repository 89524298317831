import { FC } from 'react';
import { Create } from 'react-admin';
import NotificationsForm from './NotificationsForm';

const NotificationsCreate: FC = () => {
  return (
    <Create title="Create Notifications" redirect="list">
      <NotificationsForm />
    </Create>
  );
};

export default NotificationsCreate;
