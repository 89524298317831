import { FC } from 'react';
import { Create } from 'react-admin';
import GiftForm from './GiftForm';

const GiftCreate: FC = () => {
  return (
    <Create title="Create Gift" redirect="list">
      <GiftForm />
    </Create>
  );
};

export default GiftCreate;
