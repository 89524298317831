import { FC, memo, useCallback, useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { SupplyItemType } from './WizardEditConfigs';
import { Divider, FormControl, Grid, IconButton, Input } from '@mui/material';
import { SupplyIdSelect } from './SupplyIdSelect';
import styled from 'styled-components';

const StyledGrid = styled(Grid)`
  padding: 5px 0;
`;

const StyledFormControl= styled(FormControl)`
  min-width: 150px;
`;

const StyledInput= styled(Input)`
padding: 0 10px;
`;

interface Props {
  weight: string, 
  id: string,
  supplyItemIndex: number,
  usedIdList: string[];
  isDeleteButtonDisabled: boolean;
  supplyIdList: number[];
  setSupplyItem: (args: SupplyItemType, supplyItemIndex: number) => void;
  deleteSupplyItem: (index: number) => void
}

export const SupplyItem: FC<Props> = memo(({weight, id, supplyItemIndex, usedIdList, isDeleteButtonDisabled, supplyIdList, setSupplyItem, deleteSupplyItem}) => {
  const [supplyItemWeight, setSupplyItemWeight] = useState(''); 
  const [supplyItemId, setSupplyItemId] = useState(''); 

  const handleChangeSupplyItemWeight = useCallback((event) => {
    if(event.target.value < 0) {
      return;
    }

    setSupplyItemWeight(event.target.value);
  }, [setSupplyItemWeight, setSupplyItem, supplyItemId]);

  const handleBlureSupplyItemWeight = useCallback((event) => {
    setSupplyItem({supply_id: supplyItemId, weight: event.target.value}, supplyItemIndex);
  }, [setSupplyItemWeight, setSupplyItem, supplyItemId]);

  const handleChangeSupplyItemId = useCallback((value: string) => {
    setSupplyItemId(value);
    setSupplyItemWeight('0');
    setSupplyItem({supply_id: value, weight: '0'}, supplyItemIndex);
  }, [setSupplyItemId, setSupplyItemWeight, setSupplyItem]);
  
  const handleDeleteItem = useCallback(() => {
    deleteSupplyItem(supplyItemIndex);
  }, []);

  useEffect(() => {
    setSupplyItemWeight(weight);
  }, [setSupplyItemWeight, weight]);

  useEffect(() => {
    setSupplyItemId(id);
  }, [setSupplyItemId, id]);


  return ( 
    <li>
     <StyledGrid container alignItems="flex-end" spacing={2} >
        <Grid item xs={4}
        >
          <SupplyIdSelect currentId={supplyItemId} usedIdList={usedIdList} supplyIdList={supplyIdList} setSupplyItemId={handleChangeSupplyItemId} />
        </Grid>
        <Grid item xs={4}

        >
          <StyledFormControl>
            <StyledInput
                id={`weight-${supplyItemId}`}
                type="number"
                value={supplyItemWeight}
                onChange={handleChangeSupplyItemWeight}
                onBlur={handleBlureSupplyItemWeight}
              />
          </StyledFormControl>
        </Grid>

        <Grid item xs={4}>
          <IconButton 
            aria-label="delete" 
            color="secondary" 
            disabled={isDeleteButtonDisabled}
            onClick={handleDeleteItem} 
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
    </StyledGrid>
    <Divider />
  </li>
  );
});