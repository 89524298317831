import { Layout, Sidebar } from 'react-admin';

import { FaviconProvider } from 'faviconsProvider';

import CustomAppBar from './CustomAppBar';
import CustomMenu from './CustomMenu';

const CustomSidebar = (props: any) => <Sidebar {...props} size={200} />;

export default (props: any) => {
  return (
    <FaviconProvider>
      <Layout
        {...props}
        appBar={CustomAppBar}
        menu={CustomMenu}
        sidebar={CustomSidebar}
        appBarAlwaysOn
      />
    </FaviconProvider>
  );
};
