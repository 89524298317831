import IceBreakerIcon from '@mui/icons-material/AcUnit';
import IceBreakerConfigList from './IceBreakerConfigList';
import IceBreakerConfigEdit from './IceBreakerConfigEdit';
import IceBreakerConfigCreate from './IceBreakerConfigCreate';

export default {
  list: IceBreakerConfigList,
  edit: IceBreakerConfigEdit,
  create: IceBreakerConfigCreate,
  icon: IceBreakerIcon,
};
