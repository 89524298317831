import { FC, useState, useMemo } from 'react';
import {
  BooleanField,
  DateField,
  TextField,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import { Link as RouterLink } from 'react-router-dom';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, Card, CardMedia, Link, Typography } from '@mui/material';
import styled from 'styled-components';
import { green } from '@mui/material/colors';
import { useNavigate } from 'react-router';

import { httpClient } from 'httpClient';

import ToggleActivateButton from 'layout/buttons/ToggleActivateButton';
import ErrorButton from 'layout/buttons/ErrorButton';
import {
  TrustedModerationStatus,
  TrustedUser,
} from 'types/trustedUser/trustedUserModeration';

import { SiteIdsSelectBlock } from './SiteIdsSelectBlock';

import 'react-medium-image-zoom/dist/styles.css';
import Toggle from 'react-toggle';
import { OnlineType } from 'types/enums/OnlineType';
import { PidvalApi } from 'api/PidvalApi';
import { format } from 'date-fns';
import { formatISOToUtcDate } from 'helper/date';

const AsideCard = styled(Card)`
  display: flex;
  flex-direction: column;
  width: '16.66%';
  padding: 10px;
  border: none;
  box-shadow: none;
  overflow-y: scroll;
`;

const OnlineStatus = styled.p`
  width: 10px;
  height: 10px;
  margin-left: 5px;
  background-color: ${() => green[500]};
  border-radius: 50%;
`;

const BottomContainer = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
`;
const GiftStatus = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled.p`
  margin-bottom: 10px;
`;

const StyledErrorButton = styled(ErrorButton)`
  min-width: 100px;
  margin: 4px;

  &:disabled {
    background-color: ${() => green[50]};
  }
`;

interface Props {
  isModeration?: boolean;
}

export const ShortInfoBar: FC<Props> = ({ isModeration }) => {
  const record = useRecordContext<TrustedUser>();
  const refresh = useRefresh();
  const navigate = useNavigate();
  const notify = useNotify();

  // TODO refactor loading statuses
  const [isUpdatingOnlineType, setIsUpdatingOnlineType] = useState(false);
  const [isUpdatingNewStatus, setIsUpdatingNewStatus] = useState(false);
  const [isUpdatingTestStatus, setIsUpdatingTestStatus] = useState(false);
  const [isUpdatingModerationStatus, setIsUpdatingModerationStatus] =
    useState(false);

  const isShownDuplicates = useMemo(
    () =>
      [
        TrustedModerationStatus.Moderation,
        TrustedModerationStatus.ReModeration,
        TrustedModerationStatus.Deactivated,
        TrustedModerationStatus.Decline,
        TrustedModerationStatus.Blocked,
      ].includes(record.moderation_status),
    [],
  );

  const changeTestStatus = () => {
    if (record) {
      setIsUpdatingTestStatus(true);

      httpClient
        .put(`/trusted-user/${record.id}/test`, {
          is_test: !record.is_test,
        })
        .then(() => {
          setIsUpdatingTestStatus(false);
        })
        .finally(refresh);
    }
  };

  const handleClickReModeration = () => {
    setIsUpdatingModerationStatus(true);

    if (record) {
      httpClient.put(`/trusted-user/${record.id}/re-moderation`).then(() => {
        setIsUpdatingModerationStatus(false);
        navigate(`/trusted-user/re-moderation/${record.id}`);
      });
    }
  };

  const handleClickActivateProfile = () => {
    setIsUpdatingModerationStatus(true);

    if (record) {
      httpClient
        .post(`trusted-user/published/${record.id}`, {})
        .then(() => {
          setIsUpdatingModerationStatus(false);
          refresh();
        })
        .catch((error) => {
          setIsUpdatingModerationStatus(false);

          notify(error.message || 'Something went wrong try change again', {
            type: 'error',
          });
        });
    }
  };

  const handleDefaultOnlineType = () => {
    setIsUpdatingOnlineType(true);

    httpClient
      .post(`/trusted-user/${record.id}/online-type`, {
        online_type: OnlineType.RealOnline,
      })
      .finally(() => {
        refresh();
        setIsUpdatingOnlineType(false);
      });
  };

  const handleForceOnlineClick = () => {
    if (record.online_type === OnlineType.ForceOnline) {
      handleDefaultOnlineType();

      return;
    }

    setIsUpdatingOnlineType(true);

    httpClient
      .post(`/trusted-user/${record.id}/online-type`, {
        online_type: OnlineType.ForceOnline,
      })
      .finally(() => {
        refresh();
        setIsUpdatingOnlineType(false);
      });
  };

  const handleForceOfflineClick = () => {
    if (record.online_type === OnlineType.ForceOffline) {
      handleDefaultOnlineType();

      return;
    }

    setIsUpdatingOnlineType(true);

    httpClient
      .post(`/trusted-user/${record.id}/online-type`, {
        online_type: OnlineType.ForceOffline,
      })
      .finally(() => {
        refresh();
        setIsUpdatingOnlineType(false);
      });
  };

  const handleNewStatusChange = () => {
    setIsUpdatingNewStatus(true);

    if (record.facilitate_rules?.new_from) {
      PidvalApi.activateTuNewStatus({
        status: false,
        trusted_user_id: record.id,
      }).finally(() => {
        refresh();
        setIsUpdatingNewStatus(false);
      });

      return;
    }

    const isoDate = new Date().toISOString();
    const newFrom = formatISOToUtcDate(isoDate);

    if (newFrom)
      PidvalApi.activateTuNewStatus({
        status: true,
        trusted_user_id: record.id,
        new_from: newFrom,
      }).finally(() => {
        refresh();
        setIsUpdatingNewStatus(false);
      });
  };

  if (!record) return null;

  return (
    <AsideCard>
      <Box marginBottom="10px">
        Id: <TextField source="id" />
      </Box>

      <Box marginBottom="10px">
        <Box>
          Carthage Id:{' '}
          <TextField
            source="external_id"
            style={{ color: 'rgb(59,130,246)' }}
          />
        </Box>
        <Box>
          Supply Id: <TextField source="supply.id" />
        </Box>
        <Box>
          Supply name:{' '}
          <TextField source="supply.name" style={{ fontWeight: 'bold' }} />
        </Box>
      </Box>

      <Box>
        Registration date: <DateField source="created_at" locales="UK" />
      </Box>

      <Box display="flex" marginBottom="10px">
        Gift available:{' '}
        <BooleanField source="gift_available" textAlign="center" />
      </Box>

      {isShownDuplicates && !!record?.duplicates?.length && (
        <Box
          marginBottom="10px"
          padding="10px 8px"
          bgcolor="orange"
          borderRadius="10px"
        >
          <Box display="flex" alignItems="center">
            <WarningAmberIcon style={{ height: '20px', width: '20px' }} />
            <Typography>Duplicate of:</Typography>
          </Box>

          <Box marginLeft="20px" display="flex" flexDirection="column">
            {record.duplicates.map((duplicate) => (
              <Link
                key={duplicate}
                component={RouterLink}
                to={`/trusted-user/${duplicate}`}
              >
                {duplicate}
              </Link>
            ))}
          </Box>
        </Box>
      )}

      {!isModeration && (
        <Box display="flex" alignItems="center" marginBottom="5px">
          Force_<b>online</b>:
          <Toggle
            checked={record.online_type === OnlineType.ForceOnline}
            disabled={isUpdatingOnlineType}
            style={{ marginLeft: '10px' }}
            onChange={handleForceOnlineClick}
          />
        </Box>
      )}

      {!isModeration && (
        <Box display="flex" alignItems="center" marginBottom="10px">
          Force_<b>offline</b>:{' '}
          <Toggle
            checked={record.online_type === OnlineType.ForceOffline}
            disabled={isUpdatingOnlineType}
            style={{ marginLeft: '10px' }}
            onChange={handleForceOfflineClick}
          />
        </Box>
      )}

      {!isModeration && (
        <Box
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          marginBottom="10px"
        >
          Is New:
          <Toggle
            checked={!!record.facilitate_rules?.new_from}
            disabled={isUpdatingNewStatus}
            style={{ marginLeft: '10px' }}
            onChange={handleNewStatusChange}
          />
          {!!record.facilitate_rules?.new_from && (
            <Box width="100%" fontWeight="600" marginTop="3px">
              [{record.facilitate_rules.new_from}]
            </Box>
          )}
        </Box>
      )}

      <CardMedia
        component="img"
        image={record.avatar}
        alt="image"
        style={{ maxHeight: '500px', maxWidth: '300px', objectFit: 'contain' }}
      />

      <SiteIdsSelectBlock />

      <div
        style={{
          marginTop: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {record.is_online && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            Online <OnlineStatus />
          </div>
        )}

        <StyledErrorButton
          disabled={
            isUpdatingModerationStatus ||
            record.moderation_status !== TrustedModerationStatus.Prepared
          }
          onClick={handleClickActivateProfile}
        >
          Publish
        </StyledErrorButton>

        <ToggleActivateButton
          isActivate={!!record.is_test}
          handleActivate={changeTestStatus}
          handleDeactivate={changeTestStatus}
          isLoader={isUpdatingTestStatus}
          activateLabel="Deactivate is_test"
          deactivateLabel="Activate is_test"
        />

        <StyledErrorButton
          disabled={
            isUpdatingModerationStatus ||
            record.moderation_status === TrustedModerationStatus.ReModeration ||
            record.moderation_status === TrustedModerationStatus.Moderation
          }
          onClick={handleClickReModeration}
        >
          RE-MODERATE
        </StyledErrorButton>
      </div>
    </AsideCard>
  );
};
