import { FC } from 'react';
import { Edit, TabbedForm, FormTab, useRecordContext } from 'react-admin';

import { DocumentSection } from 'component/trustedUser/DocumentSection';
import { OtherDetailsSection } from 'component/trustedUser/OtherDetailsSection';
import { ShortInfoBar } from 'component/trustedUser/ShortInfoBar';

import MediaList from './submodules/MediaList';
import CustomToolbar from './submodules/CustomToolbar';

import styled from 'styled-components';
import { Card } from '@mui/material';

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  height: 90vh;
  box-shadow: none;
  border: none;
  background-color: #fff;
`;

const StyledCard = styled(Card)`
  width: 100%;
  height: 100%;
  border-radius: 0;
  border-left: 1px solid #eee;
  box-shadow: none;
`;

const StyledForm = styled(TabbedForm)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  box-shadow: none;
`;

const StyledFormTab = styled(FormTab)<{ $declined?: boolean }>`
  min-width: 16.66%;
  width: 16.66%;
  font-weight: ${({ $declined }) => ($declined ? 'bold' : 'inherit')};
  color: ${({ $declined }) => ($declined ? '#d32f2f' : 'inherit')};
`;

const UserTitle: FC = () => {
  const record = useRecordContext();

  return record ? <span>Trusted user #{record.id}</span> : null;
};

const EditForm: FC = () => {
  const record = useRecordContext();

  return (
    <Root>
      <ShortInfoBar />

      <StyledCard>
        <StyledForm toolbar={<CustomToolbar />}>
          <StyledFormTab
            label="Documents"
            $declined={!!record?.documents?.decline_reason}
          >
            <DocumentSection
              declineReason={record?.documents?.decline_reason}
              blockReason={record?.blocked_reason}
              deactivateReason={record?.deactivate_reason}
            />
          </StyledFormTab>

          <StyledFormTab
            label="Other Details"
            $declined={!!record?.other_detail_decline_reason}
          >
            <OtherDetailsSection
              declineReason={record?.other_details?.decline_reason}
            />
          </StyledFormTab>

          <StyledFormTab label="Public Photos">
            <MediaList media={record?.public_photos} />
          </StyledFormTab>

          <StyledFormTab label="Private Photos">
            <MediaList media={record?.private_photos} />
          </StyledFormTab>

          <StyledFormTab label="Private library">
            <MediaList media={record?.private_library} />
          </StyledFormTab>

          <StyledFormTab label="Videos">
            <MediaList media={record?.videos} />
          </StyledFormTab>
        </StyledForm>
      </StyledCard>
    </Root>
  );
};

const TrustedUserEdit = () => {
  return (
    <Edit title={<UserTitle />}>
      <EditForm />
    </Edit>
  );
};

export default TrustedUserEdit;
