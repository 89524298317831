import { FC } from 'react';
import {
  SimpleForm,
  SaveButton,
  Toolbar,
  useRecordContext,
  useRefresh,
  SelectInput,
  FormDataConsumer,
} from 'react-admin';

import { Partner } from '../../model-types';
import ScrollButtons from '../../layout/ScrollButtons';

import { MainConfigs } from './submodules/MainConfigs';

import styled, { css, keyframes } from 'styled-components';
import { PostbackType } from 'types/PostbackType';
import { FacebookConfig } from './submodules/FacebookConfig';
import { GoogleAdsConfig } from './submodules/GoogleAdsConfig';
import { AffiliateConfig } from './submodules/AffiliateConfig';
import { formValidation } from './validation/formValidation';

const fade = keyframes`
  0% { 
    opacity: 0;
  };

  100% { 
    opacity: 1; 
  }
`;

const Root = styled.div<{ $targetCountryCode?: boolean }>`
  ${({ $targetCountryCode }) =>
    $targetCountryCode &&
    css`
      animation-name: ${fade};
      animation-duration: 1s;
      animation-delay: 200;
      animation-iteration-count: infinite;

      &:after {
        content: '"!!! FUCK UK !!!"';
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%);
        font-size: 40px;
        z-index: 1000;
        color: red;
      }
    `}
`;

const postbackTypeChoices = [
  { id: PostbackType.Affiliate, name: PostbackType[PostbackType.Affiliate] },
  { id: PostbackType.GoogleAds, name: PostbackType[PostbackType.GoogleAds] },
  { id: PostbackType.Facebook, name: PostbackType[PostbackType.Facebook] },
  { id: PostbackType.TikTok, name: PostbackType[PostbackType.TikTok] },
];

const UserEditToolbar = () => {
  const refresh = useRefresh();

  return (
    <Toolbar>
      <SaveButton onClick={refresh} />
    </Toolbar>
  );
};

const PartnerForm: FC = () => {
  const record = useRecordContext();

  return (
    <Root $targetCountryCode={record?.country_code === 'UK'}>
      <SimpleForm validate={formValidation} toolbar={<UserEditToolbar />}>
        <MainConfigs />

        <SelectInput
          source="postback_type"
          defaultValue={postbackTypeChoices[0]?.id}
          choices={postbackTypeChoices}
          variant="filled"
        />

        <FormDataConsumer>
          {({ formData }: { formData: Partner }) => {
            if (
              formData.postback_type === PostbackType.Facebook ||
              formData.postback_type === PostbackType.TikTok
            ) {
              return <FacebookConfig />;
            }

            if (formData.postback_type === PostbackType.GoogleAds) {
              return <GoogleAdsConfig />;
            }

            return <AffiliateConfig />;
          }}
        </FormDataConsumer>
      </SimpleForm>

      <ScrollButtons />
    </Root>
  );
};

export default PartnerForm;
