import { Box } from '@mui/material';
import React from 'react';

interface Props {
  reason: string;
  color: string;
}

export const ReasonBadge: React.FC<Props> = ({ reason, color }) => {
  return (
    <Box
      bgcolor={color}
      color="white"
      p="7px"
      width="100%"
      textAlign="center"
      borderRadius="5px"
      mb="10px"
    >
      {reason}
    </Box>
  );
};
