import { FC } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { ReactComponent as Erotic } from 'assets/icons/erotic.svg';
import { Checkbox, ImageListItemBar } from '@mui/material';
import { ModerationStatus } from 'types/enums/ModerationStatus';

interface Props {
  item: { id: number; status_id: ModerationStatus; is_erotic: boolean };
  isEroticCheckboxActive?: boolean;
  handleModerateMedia: (params: {
    id: number;
    isErotic?: boolean;
    statusId?: number;
  }) => void;
}

const MediaModerationBtn: FC<Props> = ({
  item,
  handleModerateMedia,
  isEroticCheckboxActive,
}) => {
  const handleModerate = (event: any) => {
    if (
      event.currentTarget.dataset.approveBtn === 'cancel' &&
      item.status_id !== ModerationStatus.Ban
    ) {
      handleModerateMedia({
        id: item.id,
        statusId: ModerationStatus.Ban,
      });
    }
    if (
      event.currentTarget.dataset.approveBtn === 'approve' &&
      item.status_id !== ModerationStatus.Ok
    ) {
      handleModerateMedia({
        id: item.id,
        statusId: ModerationStatus.Ok,
      });
    }
    if (event.currentTarget.type === 'checkbox') {
      handleModerateMedia({
        id: item.id,
        isErotic: event.target.checked,
      });
    }
  };

  return (
    <ImageListItemBar
      actionIcon={
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <CancelIcon
            data-approve-btn="cancel"
            style={{
              fill:
                item.status_id === ModerationStatus.Ban ? 'red' : '#ee393952',
              cursor: 'pointer',
              width: '50px',
              height: '50px',
            }}
            onClick={handleModerate}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {isEroticCheckboxActive && (
              <Checkbox
                icon={
                  <div
                    style={{
                      width: '38px',
                      height: '38px',
                      border: '2px solid #d616167d',
                      borderRadius: '50%',
                    }}
                  />
                }
                checkedIcon={<Erotic width="38" />}
                checked={item.is_erotic}
                onChange={handleModerate}
              />
            )}
            <CheckCircleIcon
              data-approve-btn="approve"
              style={{
                fill:
                  item.status_id === ModerationStatus.Ok
                    ? '#57d45c'
                    : '#56d22757',
                cursor: 'pointer',
                width: '50px',
                height: '50px',
              }}
              onClick={handleModerate}
            />
          </div>
        </div>
      }
    />
  );
};

export default MediaModerationBtn;
