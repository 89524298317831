import { FC } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  required,
  ReferenceInput,
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import queryString, { ParsedQuery } from 'query-string';

import TreeSelectInput from '../../../layout/input/TreeSelectInput';

const TextSourceCreate: FC = () => {
  const location = useLocation();

  const queryParams: ParsedQuery = location?.search
    ? queryString.parse(location?.search)
    : {};

  return (
    <Create title="Create Text Sources" redirect="list">
      <SimpleForm>
        <div>
          <ReferenceInput
            label="Category"
            source="category_id"
            reference="translations/text-categories-select"
            alwaysOn
            defaultValue={queryParams?.category_id}
          >
            <TreeSelectInput
              optionText="name"
              checkTree={false}
              variant="filled"
              sx={{ width: '300px' }}
              required
            />
          </ReferenceInput>

          <ArrayInput
            source="text_sources"
            defaultValue={[{ key: '', value: '' }]}
          >
            <SimpleFormIterator inline>
              <TextInput
                source="key"
                validate={[required()]}
                label="Key"
                variant="filled"
                sx={{ width: '300px' }}
              />
              <TextInput
                source="value"
                validate={[required()]}
                label="Value"
                multiline
                variant="filled"
                sx={{ width: '600px' }}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </div>
      </SimpleForm>
    </Create>
  );
};
export default TextSourceCreate;
