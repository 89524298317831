import { Button, Grid } from '@mui/material';
import { FC, memo } from 'react';
import { ArrayInput, NumberInput, SimpleFormIterator } from 'react-admin';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

interface Props {
  source: string;
}

export const AgeConfig: FC<Props> = memo(({ source }) => {
  return (
    <Grid container paddingLeft={0} sx={{ width: '100%' }}>
      <p>Age configs:</p>

      <ArrayInput source={source} label="">
        <SimpleFormIterator
          addButton={<Button startIcon={<AddCircleOutlineIcon />}>Add</Button>}
          disableClear
          inline
          getItemLabel={(index) => `#${index + 1}`}
          sx={{
            justifyContent: 'space-between',

            [`& .RaSimpleFormIterator-inline`]: {
              flexGrow: '1',
            },

            [`& .RaSimpleFormIterator-action`]: {
              width: '10%',
            },
          }}
        >
          <NumberInput
            source="min"
            defaultValue={18}
            min={18}
            max={99}
            step={1}
            label="Min age"
            variant="filled"
            sx={{ width: '100px' }}
          />

          <NumberInput
            source="max"
            defaultValue={99}
            min={18}
            max={99}
            step={1}
            label="Max age"
            variant="filled"
            sx={{ width: '100px' }}
          />

          <NumberInput
            source="shave"
            defaultValue={0}
            min={0}
            max={100}
            step={1}
            label="Shave %"
            variant="filled"
            sx={{ width: '100px' }}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </Grid>
  );
});
