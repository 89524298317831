import { FC, useEffect, useState } from 'react';
import {
  Create,
  SimpleForm,
  BooleanInput,
  PasswordInput,
  TextInput,
  Toolbar,
  required,
  SelectInput,
} from 'react-admin';
import { getArrayFromLinkedList } from 'utils';
import { supplyAgentRoles } from 'consts';

const SupplyAgentsCreate: FC = () => {
  const [suppliesSelectChoses, setSuppliesSelectChoses] = useState<any[]>([]);

  const fetchSupplies = async () => {
    const suppliesArray = await getArrayFromLinkedList('/supply');

    setSuppliesSelectChoses(suppliesArray);
  };

  useEffect(() => {
    fetchSupplies();
  }, []);

  return (
    <Create title="Create SupplyAgents" redirect="list">
      <SimpleForm toolbar={<Toolbar />}>
        <TextInput source="name" validate={[required()]} variant="filled" />
        <TextInput source="email" validate={[required()]} variant="filled" />
        <PasswordInput
          source="password"
          validate={[required()]}
          variant="filled"
        />
        <BooleanInput source="is_active" defaultValue={true} />

        {!!suppliesSelectChoses && (
          <SelectInput
            label="Supply id"
            source="supply_id"
            validate={[required()]}
            choices={suppliesSelectChoses}
            variant="filled"
          />
        )}

        <SelectInput
          label="Supply role"
          source="role"
          validate={[required()]}
          choices={supplyAgentRoles}
          variant="filled"
        />
      </SimpleForm>
    </Create>
  );
};

export default SupplyAgentsCreate;
