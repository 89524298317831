import { FC } from 'react';
import { Edit } from 'react-admin';
import ActivityConfigForm from './ActivityConfigForm';

const ConfigTitle: FC<any> = ({ record }) =>
  record ? <span>IcebreakerConfig #{record.id}</span> : null;

const ActivityConfigEdit: FC = () => {
  return (
    <Edit title={<ConfigTitle />}>
      <ActivityConfigForm />
    </Edit>
  );
};

export default ActivityConfigEdit;
