import { FC } from 'react';
import {
  Edit,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useRecordContext,
} from 'react-admin';
import { TextSource } from 'model-types';
import { textTranslationsActions } from 'consts';
import CopyClipboardInput from '../../../layout/input/CopyClipboardInput';

interface TextSourceProps {
  record?: TextSource;
}

const TextSourceTitle: FC<TextSourceProps> = () => {
  const record = useRecordContext();

  return record ? <span>Text Source #{record.id}</span> : null;
};

const TextSourceEdit: FC = () => {
  return (
    <Edit title={<TextSourceTitle />}>
      <SimpleForm>
        <ReferenceInput
          label="Category"
          source="category_id"
          reference="translations/text-categories"
          alwaysOn
        >
          <SelectInput
            variant="filled"
            sx={{ width: '175px' }}
            optionText={(choice) =>
              '-'.repeat(choice.level) + ' ' + choice.name
            }
            required
          />
        </ReferenceInput>
        <TextInput source="key" variant="filled" />
        <CopyClipboardInput
          source="full_key"
          disabled={true}
          variant="filled"
        />
        <TextInput
          source="value"
          multiline
          style={{ width: '100%' }}
          variant="filled"
        />
        <SelectInput
          source="action"
          choices={textTranslationsActions}
          label="Do with translations"
          defaultValue="disapprove"
          validate={[required()]}
          variant="filled"
        />
      </SimpleForm>
    </Edit>
  );
};
export default TextSourceEdit;
