import { FC } from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

const PartnerNetworkCreate: FC = () => {
  return (
    <Create title="Create Partner Network" redirect="list">
      <SimpleForm>
        <TextInput source="name" />
      </SimpleForm>
    </Create>
  );
};

export default PartnerNetworkCreate;
