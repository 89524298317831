import { red } from '@mui/material/colors';

export type GenderProps = {
  type: number;
};
const Gender = ({ type }: GenderProps) => {
  const gender = type === 1 ? 'male' : 'female';
  const color = gender === 'male' ? 'blue' : red[700];

  return <span style={{ color: color }}>{gender}</span>;
};

export default Gender;
