import { FC } from 'react';
import { Edit, useRecordContext } from 'react-admin';
import TextCategoryForm from './TextCategoryForm';

const TextCategoryTitle: FC = () => {
  const record = useRecordContext();

  return record ? <span>Text Category #{record.id}</span> : null;
};

const TextCategoryEdit: FC = () => {
  return (
    <Edit title={<TextCategoryTitle />}>
      <TextCategoryForm />
    </Edit>
  );
};
export default TextCategoryEdit;
