import { FC } from 'react';
import { Create } from 'react-admin';
import SupplyForm from './SupplyForm';

const SupplyCreate: FC = () => {
  return (
    <Create title="Create Supply" redirect="list">
      <SupplyForm />
    </Create>
  );
};

export default SupplyCreate;
