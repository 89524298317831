import { useState } from 'react';
import {
  Datagrid,
  DateField,
  List,
  NumberField,
  ReferenceField,
  AutocompleteInput,
  TextField,
  NumberInput,
  ArrayField,
  SingleFieldList,
  SelectInput,
  BooleanField,
  NullableBooleanInput,
  ReferenceInput,
  TextInput,
  useRedirect,
  useRecordContext,
  FunctionField,
} from 'react-admin';

import FileCopyIcon from '@mui/icons-material/FileCopy';

import ConfigListView from './ConfigListView';
import { trafficTypeChoices } from './submodules/field-choices';
import { costModelChoices } from 'consts';
import { httpClient } from 'httpClient';
import { CircularProgress, IconButton } from '@mui/material';
import { PostbackType } from 'types/PostbackType';

const DuplicateBtn = () => {
  const record = useRecordContext();

  const redirect = useRedirect();

  const [isLoading, setIsLoading] = useState(false);

  const handleClickDuplicate = (e: any) => {
    e.stopPropagation();

    setIsLoading(true);

    httpClient.get('/partners/duplicate/' + record.id).then((response: any) => {
      setIsLoading(false);

      redirect('/partners/' + response.data.id);
    });
  };

  return (
    <IconButton
      id="duplicate-button"
      onClick={handleClickDuplicate}
      disabled={isLoading}
    >
      {isLoading && <CircularProgress color="inherit" size="20px" />}
      {!isLoading && <FileCopyIcon fontSize="small" />}
    </IconButton>
  );
};

const filters = [
  <NumberInput source="id" alwaysOn variant="filled" />,
  <TextInput source="name" alwaysOn variant="filled" />,
  <SelectInput
    source="traffic_type"
    choices={trafficTypeChoices}
    alwaysOn
    variant="filled"
    style={{ marginBottom: 0 }}
  />,
  <SelectInput
    source="cost_model"
    choices={costModelChoices}
    alwaysOn
    variant="filled"
    style={{ marginBottom: 0 }}
  />,
  <NullableBooleanInput
    source="is_shave_invalid_email"
    label="Shave invalid email"
    alwaysOn
    variant="filled"
  />,
  <ReferenceInput
    label="Network"
    source="network_id"
    reference="partners-networks"
    allowEmpty={true}
    filterToQuery={(searchText: any) => ({ name: '%' + searchText + '%' })}
    alwaysOn
  >
    <AutocompleteInput
      optionText="name"
      variant="filled"
      sx={{
        [`& .MuiInputBase-root`]: {
          paddingTop: '11px',
        },
      }}
    />
  </ReferenceInput>,
  <TextInput source="country_code" alwaysOn variant="filled" />,
];

const PartnerList = () => {
  return (
    <List
      filters={filters}
      perPage={50}
      sort={{ field: 'updated_at', order: 'DESC' }}
    >
      <Datagrid optimized rowClick="edit">
        <NumberField source="id" />

        <DuplicateBtn />

        <TextField source="name" sx={{ width: '600px' }} />

        <ArrayField source="postback_event_configs" label="Configs">
          <SingleFieldList>
            <ConfigListView />
          </SingleFieldList>
        </ArrayField>

        <TextField source="region" />

        <TextField source="country_code" />

        <TextField source="platform" />

        <TextField source="manager" />

        <TextField source="partner_group" />

        <TextField source="traffic_type" />

        <FunctionField
          label="Postback type"
          render={(record?: Record<string, any>) =>
            PostbackType[record?.postback_type || 1]
          }
        />

        <TextField source="cost_model" />

        <TextField source="direction" />

        <ReferenceField
          label="Network"
          source="network_id"
          reference="partners-networks"
        >
          <TextField source="name" />
        </ReferenceField>

        <BooleanField
          source="is_shave_invalid_email"
          label="Shave invalid email"
        />
        <DateField source="updated_at" locales="ru-RU" showTime />
      </Datagrid>
    </List>
  );
};

export default PartnerList;
