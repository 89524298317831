import { FC, memo } from 'react';
import {
  TextInput,
  BooleanInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  required,
  FormDataConsumer,
  useRecordContext,
} from 'react-admin';
import { Button, Grid } from '@mui/material';
import _, { cloneDeep } from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Partner, PostbackEventConfig } from 'model-types';

import {
  eventTypeChoices,
  PostbackSendType,
  sendTypeChoices,
} from 'pages/partner/submodules/field-choices';
import ErrorButton from 'layout/buttons/ErrorButton';
import { TimingConfigs } from './TimingConfigs';
import { EmailConfigs } from './EmailConfigs';
import { PostbackEventConfigsActions } from './PostbackEventConfigsActions';
import { AgeConfig } from './AgeConfig';
import { needVisitPostback } from 'pages/partner/validation/formValidation';

const urlPatternValidation = [required()];

export const PostbackEventConfigs: FC = memo(() => {
  const record = useRecordContext();

  const handleRemoveAll = (configs: PostbackEventConfig[]) => {
    configs.forEach((_, i) => {
      record.postback_event_configs.remove(0);
    });
  };

  const handleDuplicateConfig = (
    index: number,
    config: PostbackEventConfig,
  ) => {
    if (record?.postback_event_configs) {
      let newConfig = cloneDeep(config);
      newConfig.id = 0;
      newConfig.subsource_id = null;

      record.postback_event_configs.splice(index + 1, 0, newConfig);
    }
  };

  const handleAddVisit = (index: number, config: PostbackEventConfig) => {
    if (record?.postback_event_configs) {
      let newConfig = cloneDeep(config);
      newConfig.id = 0;
      newConfig.event_type = 5;

      record.postback_event_configs.splice(index + 1, 0, newConfig);
    }
  };

  return (
    <>
      <h3>Postback event configs:</h3>

      <Grid container justifyContent="flex-end">
        <FormDataConsumer>
          {({ formData }) => {
            return (
              <ErrorButton
                startIcon={<DeleteIcon />}
                size="small"
                onClick={() => handleRemoveAll(formData.postback_event_configs)}
              >
                Remove subsources
              </ErrorButton>
            );
          }}
        </FormDataConsumer>
      </Grid>

      <ArrayInput source="postback_event_configs" label="">
        <SimpleFormIterator
          addButton={<Button startIcon={<AddCircleOutlineIcon />}>Add</Button>}
          disableClear
          inline
          getItemLabel={(index) => `#${index + 1}`}
          sx={{
            [`& .RaSimpleFormIterator-inline`]: {
              paddingTop: '10px',
            },

            [`& .RaSimpleFormIterator-action`]: {
              width: '20%',
            },

            [`& RaSimpleFormIterator-form`]: {
              flexGrow: '1',
            },
          }}
        >
          <BooleanInput source="is_enabled" defaultValue={true} label="ON" />

          <NumberInput
            source="subsource_id"
            defaultValue={null}
            min={0}
            label="Subsource ID"
            variant="filled"
          />

          <SelectInput
            source="event_type"
            defaultValue={null}
            label="Event Type"
            variant="filled"
            required
            choices={eventTypeChoices}
            sx={{ marginTop: '0', width: '200px' }}
          />

          <SelectInput
            source="send_type"
            label="Send Type"
            variant="filled"
            required
            choices={sendTypeChoices}
            defaultValue={PostbackSendType.RegistrationClick}
            sx={{ marginTop: '0', width: '200px' }}
          />

          <NumberInput
            source="enabled_percentage"
            defaultValue={100}
            min={0}
            max={100}
            step={1}
            label="Shave %"
            variant="filled"
            sx={{ width: '100px' }}
          />

          <NumberInput
            source="percentage_apply_offset"
            defaultValue={0}
            min={0}
            label="Shave Offset"
            variant="filled"
            sx={{ width: '100px' }}
          />

          <NumberInput
            source="payout"
            defaultValue={0.0}
            min={0}
            step={0.1}
            label="Payout"
            variant="filled"
            sx={{ width: '100px' }}
          />

          <TextInput
            source="url_pattern"
            defaultValue={null}
            label="Postback offer"
            variant="filled"
            validate={
              record?.traffic_type === 'api' ? urlPatternValidation : undefined
            }
            sx={{ width: '80%' }}
          />

          <FormDataConsumer>
            {({ getSource, formData, scopedFormData }) => {
              const index =
                formData.postback_event_configs?.findIndex(
                  (item: PostbackEventConfig) =>
                    _.isEqual(item, scopedFormData),
                ) || 0;

              const isAddVisitEnabled = needVisitPostback(
                formData as Partner,
                scopedFormData as PostbackEventConfig,
              );

              return (
                <>
                  <TimingConfigs
                    source={
                      getSource
                        ? getSource('postback_event_config_timings')
                        : ''
                    }
                  />

                  <EmailConfigs
                    source={getSource ? getSource('partner_config_emails') : ''}
                    enabledPercentage={scopedFormData?.enabled_percentage}
                  />

                  <AgeConfig
                    source={
                      getSource ? getSource('postback_event_config_ages') : ''
                    }
                  />

                  <PostbackEventConfigsActions
                    index={index}
                    config={scopedFormData as PostbackEventConfig}
                    isAddVisitEnabled={isAddVisitEnabled}
                    onDuplicate={handleDuplicateConfig}
                    onAddVisit={handleAddVisit}
                  />
                </>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
});
