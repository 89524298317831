import { Datagrid, DateField, List, TextField, EditButton } from 'react-admin';
import TreeNodeField from '../../../layout/fields/TreeNodeField';
import LinkToRelatedSources from './LinkToRelatedSources';

const TextCategoryList = () => {
  return (
    <List
      sort={{ field: 'name', order: 'ASC' }}
      pagination={false}
      title="Text categories"
    >
      <Datagrid optimized>
        <TreeNodeField source="name" sortable={false} />

        <TextField source="id" sortable={false} />

        <DateField
          source="created_at"
          locales="ru-RU"
          showTime
          sortable={false}
        />

        <DateField
          source="updated_at"
          locales="ru-RU"
          showTime
          sortable={false}
        />

        <LinkToRelatedSources />

        <EditButton />
      </Datagrid>
    </List>
  );
};
export default TextCategoryList;
