import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import { grey } from '@mui/material/colors';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  margin: 0 10px;
  box-shadow: none;
  color: ${() => grey[900]};
  background-color: ${() => grey[200]};

  &:hover {
    box-shadow: none;
    background-color: ${() => grey[400]};
  }
`;

const GreyButton = ({ loading, disabled, children, ...rest }: any) => {
  return (
    <StyledButton variant="contained" {...rest} disabled={disabled || loading}>
      {children}
      {loading && <CircularProgress size={15} thickness={4} />}
    </StyledButton>
  );
};

export default GreyButton;
